import { combineActions, handleActions } from 'redux-actions'

import produce from 'immer'

import {
  __internal,
} from './actions'

const initialState = {
  loading: false,
  list: [],
}

const startedActions = combineActions(
  __internal.fetchInstalls.started,
)

const finishedActions = combineActions(
  __internal.fetchInstalls.finished,
)

const reducer = handleActions({
  [startedActions]: state => produce(state, next => {
    next.loading = true
  }),
  [__internal.fetchInstalls.successed]: (state, action) => produce(state, next => {
    next.list = action.payload.installs
  }),
  [finishedActions]: state => produce(state, next => {
    next.loading = false
  }),
}, initialState)

export default reducer
