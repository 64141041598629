import invariant from 'invariant'

import {
  Switch,
  Redirect,

  withRouter,
} from 'react-router'

import {
  BrowserRouter,
  Link,
  NavLink,
} from 'react-router-dom'

import { ConnectedRouter } from 'connected-react-router'

import { RouterTypes } from 'types'

import Route from './Route'

const Router = () => {
  invariant(false, 'Don\'t use this component directly! Use Router.Browser instead!')

  return null
}

Router.connect = withRouter

Router.Browser = BrowserRouter
Router.ReduxAdapter = ConnectedRouter

Router.Switch = Switch
Router.Route = Route
Router.Redirect = Redirect

Router.Link = Link
Router.NavLink = NavLink

Router.Types = RouterTypes

export default Router
