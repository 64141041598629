import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { fromQueryString, toQueryString } from 'utils/query'

import { Actions, Selectors } from 'ducks'
import { FunctionUtils } from 'utils'

import DeleteForm from 'components/Form/DeleteForm'
import Spinner from 'components/Spinner'
import Table from 'components/Table'
import FilterForm from 'components/Form/FilterForm'
import Modal from 'components/Modal'
import Svg from 'components/Svg'

import cx from 'classnames'

import styles from './styles.module.scss'
import 'react-virtualized/styles.css'

class ExpertBids extends PureComponent {
  constructor(props) {
    super(props)

    const query = (this.props.location && this.props.location.search)
      ? fromQueryString(this.props.location.search)
      : {
        limit: 10,
        offset: 0,
        order: {
          field: 'id',
          direct: 'DESC',
        },
      }

    this.state = {
      COLUMN: [
        {
          name: 'ID',
          dataKey: 'id',
          maxWidth: 35,
          className: styles.id,
        },
        {
          name: 'ФИО',
          dataKey: 'name',
          flexGrow: 1,
          cellDataGetter: ({ rowData }) => rowData.name.full,
        },
        {
          name: 'Электронная почта',
          dataKey: 'email',
          maxWidth: 235,
        },
        {
          name: 'Контактный телефон',
          dataKey: 'phone',
          maxWidth: 100,
        },
        {
          name: 'Время работы, часы',
          dataKey: 'work_time',
          className: styles.id,
          maxWidth: 85,
        },
        {
          name: 'Опыт преподавания',
          dataKey: null,
          flexGrow: 1,
          cellDataGetter: ({ rowData }) => rowData.expirience,
        },
        {
          name: 'Опыт разработки',
          dataKey: null,
          flexGrow: 1,
          cellDataGetter: ({ rowData }) => rowData.expirience_lng,
        },
        {
          name: 'Дата подачи заявки',
          minWidth: 100,
          maxWidth: 135,
          dataKey: 'createdAt',
          className: styles.date,
          cellRenderer: ({ cellData }) => ((cellData) ? new Date(cellData).toLocaleDateString('ru-RU') : null),
        },
        {
          name: 'Одобрено',
          dataKey: 'appruved',
          flexGrow: 1,
          maxWidth: 135,
          cellRenderer: ({ rowData }) => ((rowData.appruved)
            ? new Date(rowData.appruved).toLocaleDateString('ru-RU')
            : (
              <div className={ styles.appruvedCell }>
                <Svg
                  name="icon_add"
                  className={ styles.appruvedIcon }
                  onClick={ () => this.setState({
                    showModal: true,
                    modalType: 'appruve',
                    rowData: {
                      name: rowData.name.full,
                      id: rowData.id,
                    },
                  }) }
                />
                <p>Одобрить</p>
              </div>
            )
          ),
        },
      ],
      FILTER_FIELDS: [
        {
          name: 'id',
          label: 'ID',
          component: 'input',
          type: 'number',
        },
        {
          name: 'name',
          label: 'Имя',
          component: 'input',
        },
        {
          name: 'email',
          label: 'Email',
          component: 'input',
          validate: ['validateEmail'],
        },
        {
          name: 'phone',
          label: 'Телефон',
          component: 'input',
        },
        {
          name: 'work_time',
          label: 'Время работы, часы',
          component: 'input',
          type: 'number',
        },
        {
          name: 'appruved',
          placeholder: 'ГГГГ-ММ-ДД',
          label: 'Дата подтверждения',
          component: 'input',
          validate: ['validateFullDate'],
        },
      ],
      showModal: false,
      fetchParams: {
        limit: query.limit || 10,
        offset: query.offset || 0,
        order: {
          field: (query.order && query.order.field) ? query.order.field : 'id',
          direct: (query.order && query.order.direct) ? query.order.direct : 'DESC',
        },
        filter: query.filter || undefined,

      },
      tableParams: {
        limit: query.limit || 10,
        offset: query.offset || 0,
        field: (query.order && query.order.field) ? query.order.field : 'id',
        direct: (query.order && query.order.direct) ? query.order.direct : 'DESC',
      },
      rowData: null,
      modalType: '',
    }
  }

  componentDidMount = async () => {
    await this.props.fetchBids(this.state.fetchParams)

    this.props.history.replace({
      pathname: this.props.location.pathname,
      search: toQueryString(this.state.fetchParams),
    })
  }

  onTableUpdate = tableState => {
    if (tableState) {
      const fetchParams = {
        ...this.state.fetchParams,
      }

      if (typeof tableState.limit === 'number') {
        fetchParams.limit = tableState.limit
      }
      if (typeof tableState.offset === 'number') {
        fetchParams.offset = tableState.offset
      }
      if (tableState.field) {
        fetchParams.order.field = tableState.field
      }
      if (tableState.direct) {
        fetchParams.order.direct = tableState.direct
      }

      this.props.fetchBids(fetchParams)

      this.props.history.replace({
        pathname: this.props.location.pathname,
        search: toQueryString(this.state.fetchParams),
      })

      this.setState(prevState => ({
        fetchParams: {
          ...fetchParams,
        },
        tableParams: {
          ...prevState.tableParams,
          ...tableState,
        },
      }))
    }
  }

  renderTable = () => (
    <div className={ styles.AutoSizeWrapper }>
      <Table
        className={ styles.table }
        headerClassName={ styles.header }
        columns={ this.state.COLUMN }
        list={ this.props.bids }
        count={ this.props.count }
        params={ this.state.tableParams }
        onTableUpdate={ this.onTableUpdate }
      />
    </div>
  )

  renderAppruveForm = data => (
    <DeleteForm
      title="Одобрение заявки"
      actionMessage="одобрить заявку"
      isLoadingSelector={ Selectors.Teachers.isLoading }
      submittingText="Одобрить"
      cancelText="Отменить"
      cancelAction={ () => this.setState({ showModal: false }) }
      submitAction={ Actions.Teachers.appruveBid }
      data={ data }
      afterSubmitAction={ this.props.fetchBids }
      afterSubmitActionArgs={ this.state.fetchParams }
    />
  )

  renderForm = type => {
    switch (type) {
      case 'appruve':
        return this.renderAppruveForm(this.state.rowData)
      case 'filter':
        return this.renderFilterForm(this.state.FILTER_FIELDS)
      default:
        return null
    }
  }

  renderModal = () => (
    <Modal isOpen={ this.state.showModal } onRequestClose={ () => this.setState({ showModal: false }) }>
      {this.state.showModal ? this.renderForm(this.state.modalType) : false}
    </Modal>
  )

  showFilterModal = evt => {
    if (evt.type === 'keypress' && evt.key !== 'Enter') {
      return
    }

    this.setState(state => ({
      initialValues: { ...state.fetchParams.filter },
      showModal: true,
      modalType: 'filter',
    }))
  }

  setFilter = values => {
    this.setState(state => {
      const fetchParams = {
        ...state.fetchParams,
        ...values,
      }

      this.props.history.replace({
        pathname: this.props.location.pathname,
        search: toQueryString(fetchParams),
      })

      return {
        showModal: false,
        fetchParams,
      }
    })
  }

  removeFilter = evt => {
    if (evt.type === 'keypress' && evt.key !== 'Enter') {
      return
    }

    if (!this.state.fetchParams.filter || !Object.keys(this.state.fetchParams.filter).length) {
      return
    }

    this.setState(state => {
      const fetchParams = {
        ...state.fetchParams,
        filter: undefined,
      }

      this.props.fetchBids(fetchParams)

      this.props.history.replace({
        pathname: this.props.location.pathname,
        search: toQueryString(fetchParams),
      })

      return {
        fetchParams,
      }
    })
  }

  renderFilterForm = fields => (
    <FilterForm
      title="Расширенный поиск"
      isLoadingSelector={ Selectors.Teachers.isLoading }
      submittingText="Поиск"
      cancelText="Отменить"
      cancelAction={ () => this.setState({ showModal: false }) }
      initialValues={ this.state.initialValues }
      fields={ fields }
      submitAction={ values => Actions.Teachers.bids({ ...this.state.fetchParams, ...values }) }
      afterSubmitAction={ this.setFilter }
    />
  )

  render() {
    return (
      <div className={ cx(styles.page, { [styles.spinner]: this.props.isLoading }) }>
        {this.props.isLoading ? <Spinner /> : (
          <div className={ styles.container }>
            <div className={ styles.header }>
              <div className={ styles.label }>
                Заявки учителей
              </div>
              <div
                className={ styles.filter }
                role="button"
                onClick={ this.showFilterModal }
                tabIndex={ 0 }
                onKeyPress={ this.showFilterModal }
              >
                <Svg name="icon_filter" />
                <div className={ styles.filter__text }>
                  Расширенный поиск
                </div>
              </div>
              {this.state.fetchParams.filter && (
                <div
                  role="button"
                  onClick={ this.removeFilter }
                  tabIndex={ 0 }
                  onKeyPress={ this.removeFilter }
                >
                  <div className={ styles.filter__remove }>
                    Отчистить фильтр
                  </div>
                </div>
              )}
            </div>
            {this.renderTable()}
          </div>
        )}
        {this.renderModal()}
      </div>
    )
  }
}

ExpertBids.propTypes = {
  fetchBids: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  bids: PropTypes.arrayOf(
    PropTypes.object,
  ).isRequired,
  count: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired, //eslint-disable-line
  history: PropTypes.object.isRequired, //eslint-disable-line
}

const mapStateToProps = state => ({
  isLoading: Selectors.Teachers.isLoading(state),
  bids: Selectors.Teachers.bids(state),
  count: Selectors.Teachers.countBids(state),
})

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchBids: Actions.Teachers.bids,
}, dispatch)

export default FunctionUtils.compose(
  connect(mapStateToProps, mapDispatchToProps),
)(ExpertBids)
