import { combineActions, handleActions } from 'redux-actions'

import produce from 'immer'

import {
  __internal,
} from './actions'

const initialState = {
  loading: false,
  newsList: [],
  count: 0,

  singleNews: {},
}

const startedActions = combineActions(
  __internal.fetch.started,
  __internal.fetchOne.started,
  __internal.add.started,
  __internal.change.started,
  __internal.del.started,
)


const finishedActions = combineActions(
  __internal.fetch.finished,
  __internal.fetchOne.finished,
  __internal.add.finished,
  __internal.change.finished,
  __internal.del.finished,
)

const reducer = handleActions({
  [startedActions]: state => produce(state, next => {
    next.loading = true
  }),
  [__internal.fetch.successed]: (state, action) => produce(state, next => {
    next.newsList = action.payload.list
    next.count = action.payload.count
  }),
  [__internal.fetchOne.successed]: (state, action) => produce(state, next => {
    next.singleNews = action.payload.news
  }),
  [finishedActions]: state => produce(state, next => {
    next.loading = false
  }),
}, initialState)

export default reducer
