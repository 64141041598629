import React from 'react'
import { useSelector } from 'react-redux'

import { Selectors } from 'ducks'

const AwaitInitialize = ({ children }) => {
  const isInitialized = useSelector(Selectors.App.isInitialized)

  if (!isInitialized) {
    return null
  }

  return children
}

export default React.memo(AwaitInitialize)
