import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { FunctionUtils } from 'utils'

import Spinner from 'components/Spinner'
import Button from 'components/Button'

import styles from './styles.module.scss'

class DeleteForm extends PureComponent {
  // eslint-disable-next-line react/state-in-constructor
  state = { error: '' }

  handleSubmit = id => {
    try {
      this.props.submitAction(id)
        .then(() => this.props.afterSubmitAction(this.props.afterSubmitActionArgs))
        .then(() => this.props.cancelAction())
    } catch (e) {
      this.setState({ error: 'Что-то пошло не так' })
    }
  }

  render() {
    const {
      title, cancelAction, cancelText, submittingText, data, actionMessage,
    } = this.props

    return (
      <div className={ styles.container }>
        <p className={ styles.formTitle }>{title}</p>
        {this.props.isLoading ? <Spinner /> : (
          <div className={ styles.formContainer }>
            <p className={ styles.formText }>{`Вы действительно хотите
              ${actionMessage}
              ${data.name} c ID=${data.id}?`}
            </p>
            <div className={ styles.buttonGroup }>
              <Button
                className={ styles.cancelButton }
                color="white"
                filled
                onClick={ cancelAction }
              >
                {cancelText}
              </Button>
              <Button
                className={ styles.submitButton }
                color="blue"
                filled
                onClick={ () => this.handleSubmit(data.id) }
              >
                {submittingText}
              </Button>
              {(this.state.error.length !== 0 && <div className={ styles.error }>{this.state.error}</div>)}
            </div>
          </div>
        ) }
      </div>
    )
  }
}

DeleteForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  cancelAction: PropTypes.func.isRequired,
  cancelText: PropTypes.string.isRequired,
  submittingText: PropTypes.string.isRequired,
  submitAction: PropTypes.func.isRequired,
  afterSubmitAction: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  afterSubmitActionArgs: PropTypes.object,
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
  actionMessage: PropTypes.string,
}

DeleteForm.defaultProps = {
  actionMessage: 'удалить',
  afterSubmitActionArgs: {},
  afterSubmitAction: () => {},
}

const mapStateToProps = (state, ownProps) => ({
  isLoading: ownProps.isLoadingSelector(state),
})

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  submitAction: ownProps.submitAction,
}, dispatch)

export default FunctionUtils.compose(
  connect(mapStateToProps, mapDispatchToProps),
)(DeleteForm)
