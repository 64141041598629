import { createAction } from 'redux-actions'

import { ReduxUtils } from 'utils'

const fetchActions = ReduxUtils.generateActionCreators('student', 'fetch', 'request')
const fetchByNameActions = ReduxUtils.generateActionCreators('student', 'fetch_by_name', 'request')
const editActions = ReduxUtils.generateActionCreators('student', 'edit', 'request')
const banKeyActions = ReduxUtils.generateActionCreators('student', 'ban_key', 'request')
const unbanKeyActions = ReduxUtils.generateActionCreators('student', 'unban_key', 'request')

const fetch = ReduxUtils.waitForAction(
  createAction('STUDENT/FETCH'),
  fetchActions.successed,
  fetchActions.failed,
)

const fetchByName = ReduxUtils.waitForAction(
  createAction('STUDENT/FETCH_BY_NAME'),
  fetchByNameActions.successed,
  fetchByNameActions.failed,
)

const edit = ReduxUtils.waitForAction(
  createAction('STUDENT/EDIT'),
  editActions.successed,
  editActions.failed,
)

const banKey = ReduxUtils.waitForAction(
  createAction('STUDENT/BAN_KEY'),
  banKeyActions.successed,
  banKeyActions.failed,
)

const unbanKey = ReduxUtils.waitForAction(
  createAction('STUDENT/UNBAN_KEY'),
  unbanKeyActions.successed,
  unbanKeyActions.failed,
)

const __internal = {
  fetch: fetchActions,
  fetchByName: fetchByNameActions,
  edit: editActions,
  banKey: banKeyActions,
  unbanKey: unbanKeyActions,
}

export {
  fetch,
  fetchByName,
  edit,
  banKey,
  unbanKey,

  __internal,
}
