import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Actions, Selectors } from 'ducks'
import { FunctionUtils } from 'utils'
import debounce from 'lodash/debounce'
import { fromQueryString, toQueryString } from 'utils/query'

import DeleteForm from 'components/Form/DeleteForm'
import Spinner from 'components/Spinner'
import Button from 'components/Button'
import Table from 'components/Table'
import Modal from 'components/Modal'
import Form from 'components/Form'
import Svg from 'components/Svg'
import FilterForm from 'components/Form/FilterForm'
import cx from 'classnames'
import FreeKeys from './FreeKeys'
import Keys from './Keys'

import styles from './styles.module.scss'
import 'rc-dropdown/assets/index.css'
import 'react-virtualized/styles.css'

class Codes extends PureComponent {
  constructor(props) {
    super(props)
    this.loadOptions = debounce(this.debounceLoadOptions, 200)

    const query = (this.props.location && this.props.location.search)
      ? fromQueryString(this.props.location.search)
      : {
        codes: {
          limit: 10,
          offset: 0,
          order: {
            field: 'id',
            direct: 'DESC',
          },
        },
      }

    this.state = {
      COLUMN: [
        {
          dataKey: null,
          maxWidth: 25,
          className: styles.centerText,
          headerRenderer: () => (
            <div className={ cx(styles.firstColumn, styles.firstColumnHeader) }>
              <Svg name="icon_edit" />
            </div>
          ),
          cellRenderer: ({ rowData }) => (
            <div className={ cx(styles.firstColumn, styles.firstColumnCell) }>
              <Svg
                name="icon_edit"
                className={ styles.editIcon }
                onClick={ e => {
                  e.stopPropagation()
                  this.makeEditFormValue(rowData)
                } }
              />
            </div>
          ),
        },
        {
          name: 'ID',
          dataKey: 'id',
          maxWidth: 35,
          className: styles.centerText,
        },
        {
          name: 'Группа',
          dataKey: 'name',
          flexGrow: 1,
        },
        {
          name: 'Страна',
          dataKey: null,
          maxWidth: 120,
          cellDataGetter: ({ rowData }) => rowData.School,
          cellRenderer: ({ cellData }) => ((cellData) ? cellData.Country.name : null),
        },
        {
          name: 'Город',
          dataKey: null,
          maxWidth: 120,
          cellDataGetter: ({ rowData }) => rowData.School,
          cellRenderer: ({ cellData }) => ((cellData) ? cellData.City.name : null),
        },
        {
          name: 'Образовательное учреждение',
          dataKey: 'SchoolId',
          flexGrow: 1,
          cellDataGetter: ({ rowData }) => rowData.School,
          cellRenderer: ({ cellData }) => ((cellData) ? cellData.name : null),
        },
        {
          name: 'Дистрибьютор',
          dataKey: 'EmployeeId',
          flexGrow: 1,
          cellDataGetter: ({ rowData }) => rowData.Employee,
          cellRenderer: ({ cellData }) => ((cellData) ? cellData.name.full : null),
        },
        {
          name: 'Количество, шт',
          dataKey: null,
          maxWidth: 80,
          cellDataGetter: ({ rowData }) => rowData.codes_count,
          className: styles.number,
        },
        {
          dataKey: null,
          maxWidth: 25,
          className: styles.centerText,
          headerRenderer: () => (
            <div className={ cx(styles.firstColumn, styles.firstColumnHeader) }>
              <Svg name="icon_delete" />
            </div>
          ),
          cellRenderer: ({ rowData }) => (
            <div className={ cx(styles.firstColumn, styles.firstColumnCell) }>
              <Svg
                name="icon_delete"
                className={ styles.deleteIcon }
                onClick={ e => {
                  e.stopPropagation()
                  this.setState({ showModal: true, modalType: 'delete', rowData })
                } }
              />
            </div>
          ),
        },
      ],
      ADD_FIELDS: [
        {
          name: 'name',
          label: 'Имя группы',
          validate: ['requiredField'],
          component: 'input',
        },
        {
          name: 'SchoolId',
          label: 'Образовательное учреждение',
          validate: ['requiredField'],
          component: 'select',
          options: [],
        },
        ...(this.props.role !== 'distributor' ? [{
          name: 'EmployeeId',
          label: 'Дистрибьютор',
          component: 'select',
          options: [],
        }] : []),
      ],
      KEYS_FIELDS: [
        {
          name: 'count',
          label: 'Число ключей',
          component: 'input',
          type: 'number',
          validate: ['requiredField'],
        },
        {
          name: 'started_at',
          label: 'Дата начала срока действия',
          component: 'input',
          placeholder: 'ГГГГ-ММ-ДД',
          validate: ['validateFullDate', 'requiredField'],
        },
        {
          name: 'expired_at',
          label: 'Дата окончания срока действия',
          component: 'input',
          placeholder: 'ГГГГ-ММ-ДД',
          validate: ['validateFullDate', 'requiredField'],
        },
        {
          name: 'SchoolId',
          label: 'Образовательное учреждение',
          validate: ['requiredField'],
          component: 'select',
          options: [],
        },
        {
          name: 'cg_name',
          label: 'Наименование группы ключей',
          validate: ['requiredField'],
          component: 'input',
        },
        {
          name: 'EmployeeId',
          label: 'Дистрибьютор',
          validate: ['requiredField'],
          component: 'select',
          options: [],
        },
      ],
      EDIT_FIELDS: [
        {
          name: 'id',
          label: 'ID',
          component: 'input',
          disabled: true,
        },
        {
          name: 'name',
          label: 'Имя группы',
          validate: ['requiredField'],
          component: 'input',
        },
        {
          name: 'SchoolId',
          label: 'Образовательное учреждение',
          validate: ['requiredField'],
          component: 'select',
          options: [],
        },
        ...(this.props.role !== 'distributor' ? [{
          name: 'EmployeeId',
          label: 'Дистрибьютор',
          component: 'select',
          options: [],
        }] : []),
      ],
      FILTER_FIELDS: [
        {
          name: 'id',
          label: 'ID',
          component: 'input',
          type: 'number',
        },
        ...(this.props.role !== 'distributor' ? [{
          name: 'EmployeeId',
          label: 'ID дистрибьютера',
          component: 'input',
          type: 'number',
        }] : []),
        {
          name: 'SchoolId',
          label: 'ID школы',
          component: 'input',
          type: 'number',
        },
      ],
      showKeys: (query.keys && typeof query.keys.id === 'number'),
      showModal: false,
      modalType: '',
      initialValues: {},
      rowData: null,
      groupParams: { id: (query.keys && typeof query.keys.id === 'number') ? query.keys.id : 0 },
      fetchParams: {
        limit: (query.codes && query.codes.limit) ? query.codes.limit : 10,
        offset: (query.codes && query.codes.offset) ? query.codes.offset : 0,
        order: {
          field: (query.codes && query.codes.order && query.codes.order.field) ? query.codes.order.field : 'id',
          direct: (query.codes && query.codes.order && query.codes.order.direct) ? query.codes.order.direct : 'DESC',
        },
        filter: (query.codes && query.codes.filter) ? query.codes.filter : undefined,
      },
      tableParams: {
        limit: (query.codes && query.codes.limit) ? query.codes.limit : 10,
        offset: (query.codes && query.codes.offset) ? query.codes.offset : 0,
        field: (query.codes && query.codes.order && query.codes.order.field) ? query.codes.order.field : 'id',
        direct: (query.codes && query.codes.order && query.codes.order.direct) ? query.codes.order.direct : 'DESC',
      },
    }
  }

  componentDidMount = async () => {
    await this.props.fetchGroups(this.state.fetchParams)

    const search = (this.props.location && this.props.location.search)
      ? fromQueryString(this.props.location.search)
      : {}

    search.codes = { ...this.state.fetchParams }

    this.props.history.replace({
      pathname: this.props.location.pathname,
      search: toQueryString(search),
    })
  }


  onTableUpdate = tableState => {
    if (tableState) {
      const fetchParams = {
        ...this.state.fetchParams,
      }

      if (typeof tableState.limit === 'number') {
        fetchParams.limit = tableState.limit
      }
      if (typeof tableState.offset === 'number') {
        fetchParams.offset = tableState.offset
      }
      if (tableState.field) {
        fetchParams.order.field = tableState.field
      }
      if (tableState.direct) {
        fetchParams.order.direct = tableState.direct
      }

      this.props.fetchGroups(fetchParams)

      const search = (this.props.location && this.props.location.search)
        ? fromQueryString(this.props.location.search)
        : {}

      search.codes = { ...fetchParams }

      this.props.history.replace({
        pathname: this.props.location.pathname,
        search: toQueryString(search),
      })

      this.setState(prevState => ({
        fetchParams: {
          ...fetchParams,
        },
        tableParams: {
          ...prevState.tableParams,
          ...tableState,
        },
      }))
    }
  }

  renderGroupsTable = () => (
    <div className={ styles.groupsTable }>
      <Table
        count={ this.props.count }
        params={ this.state.tableParams }
        onTableUpdate={ this.onTableUpdate }
        list={ this.props.list }
        columns={ this.state.COLUMN }
        onRowClick={ this._onRowClick }
      />
    </div>
  )

  _onRowClick = async data => {
    try {
      this.setState({
        showKeys: true,
        groupParams: {
          id: data.rowData.id,
        },
      })
    } catch (e) {
      this.setState({ showKeys: false })
    }
  }

  debounceLoadOptions = async value => {
    switch (value.name) {
      case 'SchoolId':
        await this.props.fetchInstitutionsName(value.value)
        if (this.props.institutionsList.length !== 0) {
          const optionsArr = this.props.institutionsList.map(item => {
            const options = {
              value: item.id,
              label: item.name,
            }
            return options
          })
          this.setState(prevState => {
            const newAddFields = [...prevState.ADD_FIELDS]
            const newEditFields = [...prevState.EDIT_FIELDS]
            const newKeysFields = [...prevState.KEYS_FIELDS]
            newAddFields[1].options = optionsArr
            newEditFields[2].options = optionsArr
            newKeysFields[3].options = optionsArr

            return { ADD_FIELDS: newAddFields, EDIT_FIELDS: newEditFields, KEYS_FIELDS: newKeysFields }
          })
        }

        return null

      case 'EmployeeId':
        await this.props.fetchDistributorsName(value.value)
        if (this.props.distributorsList.length !== 0 && this.props.role !== 'distributor') {
          const optionsArr = this.props.distributorsList.map(item => {
            const options = {
              value: item.id,
              label: item.name.full,
            }
            return options
          })
          this.setState(prevState => {
            const newAddFields = [...prevState.ADD_FIELDS]
            const newEditFields = [...prevState.EDIT_FIELDS]
            const newKeysFields = [...prevState.KEYS_FIELDS]
            newAddFields[2].options = optionsArr
            newEditFields[3].options = optionsArr
            newKeysFields[5].options = optionsArr

            return { ADD_FIELDS: newAddFields, EDIT_FIELDS: newEditFields, KEYS_FIELDS: newKeysFields }
          })
        }

        return null

      default:
        return null
    }
  }

  renderDeleteForm = data => (
    <DeleteForm
      title="Удаление группы ключей"
      isLoadingSelector={ Selectors.Codes.isLoading }
      submittingText="Да"
      cancelText="Отменить"
      cancelAction={ () => this.setState({ showModal: false }) }
      submitAction={ Actions.Codes.deleteGroup }
      data={ data }
      afterSubmitAction={ this.props.fetchGroups }
      afterSubmitActionArgs={ this.state.fetchParams }
    />
  )

  renderAddForm = fields => (
    <Form
      title="Создание группы ключей"
      isLoadingSelector={ Selectors.Codes.isLoading }
      submittingText="Создать"
      cancelText="Отменить"
      cancelAction={ () => this.setState({ showModal: false }) }
      fields={ fields }
      submitAction={ Actions.Codes.createGroup }
      loadOptions={ this.loadOptions }
      afterSubmitAction={ this.props.fetchGroups }
      afterSubmitActionArgs={ this.state.fetchParams }
    />
  )

  onChangeSelect = (name, value) => {
    if (name === 'SchoolId' && value) {
      this.setState({
        initialValues: {
          cg_name: `${value.label} - ${new Date().toLocaleDateString('ru-RU')}`,
        },
      })
    }
  }

  renderKeysForm = fields => (
    <Form
      title="Создание ключей"
      isLoadingSelector={ Selectors.Codes.isLoading }
      submittingText="Создать"
      cancelText="Отменить"
      cancelAction={ () => this.setState({ showModal: false }) }
      fields={ fields }
      submitAction={ Actions.Codes.createKeys }
      loadOptions={ this.loadOptions }
      afterSubmitAction={ this.props.fetchGroups }
      afterSubmitActionArgs={ this.state.fetchParams }
      onChangeSelect={ this.onChangeSelect }
      initialValues={ this.state.initialValues }
    />
  )

  renderEditForm = fields => (
    <Form
      title="Изменение группы ключей"
      isLoadingSelector={ Selectors.Codes.isLoading }
      submittingText="Изменить"
      cancelText="Отменить"
      cancelAction={ () => this.setState({ showModal: false }) }
      fields={ fields }
      initialValues={ this.state.initialValues }
      submitAction={ Actions.Codes.editGroup }
      loadOptions={ this.loadOptions }
      afterSubmitAction={ this.props.fetchGroups }
      afterSubmitActionArgs={ this.state.fetchParams }
    />
  )

  makeEditFormValue = rowData => {
    this.setState({
      initialValues: {
        id: rowData.id,
        name: rowData.name,
        ...(rowData.Employee ? {
          EmployeeId:
          {
            value: rowData.Employee.id,
            label: rowData.Employee.name.full,
          },
        } : []),
        ...(rowData.School ? {
          SchoolId:
          {
            value: rowData.School.id,
            label: rowData.School.name,
          },
        } : []),
      },
      showModal: true,
      modalType: 'edit',
    })
  }

  renderGroups = () => (
    <div className={ styles.groups }>
      <div className={ styles.header }>
        <div className={ styles.label }>
          Ключи
        </div>
        <div className={ styles.buttonGroupRow }>
          <Button
            className={ styles.button }
            color="blue"
            filled
            onClick={ () => this.setState({ showModal: true, modalType: 'add' }) }
          >
            Создать группу ключей
          </Button>
          { this.props.role === 'admin'
            ? (
              <Button
                className={ styles.buttonFree }
                color="green"
                filled
                onClick={ () => this.setState({ showModal: true, modalType: 'keys' }) }
              >
            Создать ключи
              </Button>
            )
            : null}
          <Button
            className={ styles.buttonFree }
            color="white"
            filled
            onClick={ () => this.setState({ showKeys: false }) }
          >
            Непривязанные ключи
          </Button>
        </div>
        <div
          className={ styles.filter }
          role="button"
          onClick={ this.showFilterModal }
          tabIndex={ 0 }
          onKeyPress={ this.showFilterModal }
        >
          <Svg name="icon_filter" />
          <div className={ styles.filter__text }>
            Расширенный поиск
          </div>
        </div>
        {this.state.fetchParams.filter && (
          <div
            role="button"
            onClick={ this.removeFilter }
            tabIndex={ 0 }
            onKeyPress={ this.removeFilter }
          >
            <div className={ styles.filter__remove }>
              Отчистить фильтр
            </div>
          </div>
        )}
      </div>
      {this.renderGroupsTable()}
    </div>
  )

  renderForm = type => {
    switch (type) {
      case 'add':
        return this.renderAddForm(this.state.ADD_FIELDS)
      case 'keys':
        return this.renderKeysForm(this.state.KEYS_FIELDS)
      case 'delete':
        return this.renderDeleteForm(this.state.rowData)
      case 'edit':
        return this.renderEditForm(this.state.EDIT_FIELDS)
      case 'filter':
        return this.renderFilterForm(this.state.FILTER_FIELDS)
      default:
        return null
    }
  }

  renderModal = () => (
    <Modal isOpen={ this.state.showModal } onRequestClose={ () => this.setState({ showModal: false }) }>
      {this.state.showModal ? this.renderForm(this.state.modalType) : false}
    </Modal>
  )

  renderKey = showKeys => ((showKeys) ? (
    <Keys
      key={ this.state.groupParams.id }
      fetchParams={ this.state.groupParams }
    />
  ) : <FreeKeys />
  )


  showFilterModal = evt => {
    if (evt.type === 'keypress' && evt.key !== 'Enter') {
      return
    }

    this.setState(state => ({
      initialValues: { ...state.fetchParams.filter },
      showModal: true,
      modalType: 'filter',
    }))
  }

  setFilter = values => {
    this.setState(state => {
      const fetchParams = {
        ...state.fetchParams,
        ...values,
      }
      const search = (this.props.location && this.props.location.search)
        ? fromQueryString(this.props.location.search)
        : {}

      search.codes = { ...fetchParams }

      this.props.history.replace({
        pathname: this.props.location.pathname,
        search: toQueryString(search),
      })

      return {
        showModal: false,
        fetchParams,
      }
    })
  }

  removeFilter = evt => {
    if (evt.type === 'keypress' && evt.key !== 'Enter') {
      return
    }

    if (!this.state.fetchParams.filter || !Object.keys(this.state.fetchParams.filter).length) {
      return
    }

    this.setState(state => {
      const fetchParams = {
        ...state.fetchParams,
        filter: undefined,
      }

      this.props.fetchGroups(fetchParams)

      const search = (this.props.location && this.props.location.search)
        ? fromQueryString(this.props.location.search)
        : {}

      search.codes = { ...fetchParams }

      this.props.history.replace({
        pathname: this.props.location.pathname,
        search: toQueryString(search),
      })

      return {
        fetchParams,
      }
    })
  }

  renderFilterForm = fields => (
    <FilterForm
      title="Расширенный поиск"
      isLoadingSelector={ Selectors.Codes.isLoading }
      submittingText="Поиск"
      cancelText="Отменить"
      cancelAction={ () => this.setState({ showModal: false }) }
      initialValues={ this.state.initialValues }
      fields={ fields }
      submitAction={ values => Actions.Codes.fetchGroups({ ...this.state.fetchParams, ...values }) }
      afterSubmitAction={ this.setFilter }
    />
  )

  render() {
    return (
      <div className={ styles.page }>
        <div className={ styles.container }>
          {this.props.isLoading ? <div className={ styles.spinner }><Spinner /></div> : this.renderGroups()}
          {this.renderKey(this.state.showKeys)}
        </div>
        {this.renderModal()}
      </div>
    )
  }
}

Codes.propTypes = {
  fetchGroups: PropTypes.func.isRequired,
  fetchInstitutionsName: PropTypes.func.isRequired,
  fetchDistributorsName: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.object,
  ).isRequired,
  count: PropTypes.number.isRequired,
  institutionsList: PropTypes.arrayOf(
    PropTypes.object,
  ).isRequired,
  distributorsList: PropTypes.arrayOf(
    PropTypes.object,
  ).isRequired,
  location: PropTypes.object.isRequired, //eslint-disable-line
  history: PropTypes.object.isRequired, //eslint-disable-line
  role: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
  isLoading: Selectors.Codes.isLoading(state),
  list: Selectors.Codes.list(state),
  count: Selectors.Codes.count(state),
  role: Selectors.Auth.role(state),
  institutionsList: Selectors.Institutions.autocompleteList(state),
  distributorsList: Selectors.Distributors.autocompleteList(state),
})

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchGroups: Actions.Codes.fetchGroups,
  fetchInstitutionsName: Actions.Institutions.fetchByName,
  fetchDistributorsName: Actions.Distributors.fetchByName,
}, dispatch)

export default FunctionUtils.compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Codes)
