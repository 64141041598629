import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Router from 'components/Router'

import SignIn from 'pages/Auth/SignIn'
import SignUp from 'pages/Auth/SignUp'
import Forgot from 'pages/Auth/Forgot'
import ChangePassword from 'pages/Auth/ChangePassword'
import SocialMobileSuccess from 'pages/Auth/SocialMobileSuccess'
import SocialMobileFail from 'pages/Auth/SocialMobileFail'

class Auth extends PureComponent {
  render() {
    return (
      <Router.Switch>
        <Router.Route exact path={ `${this.props.match.url}/sign-in` } component={ SignIn } />
        <Router.Route exact path={ `${this.props.match.url}/sign-up` } component={ SignUp } />
        <Router.Route exact path={ `${this.props.match.url}/forgot` } component={ Forgot } />
        <Router.Route path={ `${this.props.match.url}/change-password` } component={ ChangePassword } />
        <Router.Route exact path={ `${this.props.match.url}/social/success` } component={ SocialMobileSuccess } />
        <Router.Route exact path={ `${this.props.match.url}/social/fail` } component={ SocialMobileFail } />
        <Router.Route exact path="/404" />
        <Router.Redirect to="/404" />
      </Router.Switch>
    )
  }
}

Auth.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
}

export default Auth
