import React from 'react'
import PropTypes from 'prop-types'
import { APIConfig } from 'configs'

import styles from './styles.module.scss'

const initialState = {
  imagePreviewUrl: '',
}

class FileInput extends React.Component {
  constructor(props) {
    super(props)
    let imagePreviewUrl = ''

    if (this.props.initialValues && this.props.initialValues.photo) {
      imagePreviewUrl = `${APIConfig.ORIGIN}${this.props.initialValues.photo}`
    }

    this.state = {
      imagePreviewUrl,
    }
  }

  onChange = evt => {
    const reader = new FileReader()

    const file = evt.target.files[0]

    reader.onloadend = () => {
      this.props.onChange(file)

      this.setState({
        imagePreviewUrl: reader.result,
      })
    }

    if (file) {
      reader.readAsDataURL(file)
    } else {
      this.setState(initialState)
      this.props.onChange('')
    }
  }

  render() {
    const { imagePreviewUrl } = this.state

    return (
      <div className={ styles.fileInputWrapper }>
        <input
          type="file"
          accept="image/*"
          onChange={ this.onChange }
          className={ styles.fileInput }
        />
        <div className={ styles.fotoPreview }>
          { imagePreviewUrl.length !== 0
            ? (<img src={ imagePreviewUrl } alt="Предпросмотр" className={ styles.foto } />)
            : null }
        </div>
      </div>

    )
  }
}

FileInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.object,
}

FileInput.defaultProps = {
  initialValues: undefined,
}

export default FileInput
