import {
  call,
  put,
  putResolve,
  takeLatest,
} from 'redux-saga/effects'

import {
  InstitutionService,
  FeedbackService,
  TeacherService,
} from 'services'

import {
  bid,
  feedback,
  teacher,

  __internal,
} from './actions'

function* bidTask(action) {
  yield put(__internal.bid.started())

  try {
    const response = yield call([InstitutionService, 'bid'], action.payload)
    yield putResolve(__internal.bid.successed(response))
  } catch (err) {
    yield put(__internal.bid.failed(err))
  }

  yield put(__internal.bid.finished())
}

function* feedbackTask(action) {
  yield put(__internal.feedback.started())

  try {
    const response = yield call([FeedbackService, 'bid'], action.payload)
    yield putResolve(__internal.feedback.successed(response))
  } catch (err) {
    yield put(__internal.feedback.failed(err))
  }

  yield put(__internal.feedback.finished())
}

function* teacherTask(action) {
  yield put(__internal.teacher.started())

  try {
    const response = yield call([TeacherService, 'bid'], action.payload)
    yield putResolve(__internal.teacher.successed(response))
  } catch (err) {
    yield put(__internal.teacher.failed(err))
  }

  yield put(__internal.teacher.finished())
}

export default function* () {
  yield takeLatest(bid, bidTask)
  yield takeLatest(feedback, feedbackTask)
  yield takeLatest(teacher, teacherTask)
}
