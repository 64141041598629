import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import cx from 'classnames'
import styles from './styles.module.scss' //eslint-disable-line

const MessagePreview = props => {
  const rawDate = moment.utc(props.date).local()
  let showTime

  if (moment(rawDate).diff(moment(), 'days') !== 0) {
    showTime = rawDate.format('DD.MM.YY HH:mm')
  } else {
    showTime = rawDate.format('HH:mm')
  }

  const date = (rawDate.isValid()) ? `${showTime} (мск)` : null
  return (
    <div
      role="button"
      tabIndex={ 0 }
      onKeyPress={ evt => props.handleClick(props.id, props.userId, evt) }
      onClick={ evt => props.handleClick(props.id, props.userId, evt) }
      className={ (props.selected) ? cx(styles.container, styles.selected) : styles.container }
    >
      <div className={ styles.notification }>
        {(props.isOnline) ? <div className={ styles.notification__dot } /> : null}
      </div>
      <div className={ styles.messageContainer }>
        <div className={ styles.main }>
          <div className={ styles.main__number }>#{props.id}</div>
          <div className={ styles.main__name }>
            {props.name}
            {(props.age) ? `, ${props.age} лет` : null}
          </div>
        </div>
        <div className={ styles.text }>{props.message}</div>
        <div className={ styles.date }>{date}</div>
      </div>
    </div>
  )
}


MessagePreview.propTypes = {
  selected: PropTypes.bool,
  handleClick: PropTypes.func,
  id: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  name: PropTypes.string,
  age: PropTypes.number,
  isOnline: PropTypes.bool,
  message: PropTypes.string,
  date: PropTypes.string,
}

MessagePreview.defaultProps = {
  selected: false,
  handleClick: () => { },
  isOnline: false,
  name: 'Анастасия Пряничникова',
  age: 0,
  message: 'Нет сообщений',
  date: '',
}

export default MessagePreview
