export default class StudentService {
  constructor(APIService) {
    this.APIService = APIService
  }

  fetchStudents({
    limit, offset, order, filter,
  }) {
    return this.APIService.get('/admin/users', {
      limit,
      offset,
      order,
      filter,
    })
      .then(response => response.data).catch(e => {
        throw e
      })
  }

  fetchByName(name) {
    return this.APIService.get('/admin/users', { filter: { name } })
      .then(response => response.data).catch(e => {
        throw e
      })
  }

  profileUpdate(info) {
    return this.APIService.post('/app/user/profile-update', info)
      .then(response => response.data)
      .catch(e => {
        throw e
      })
  }
}
