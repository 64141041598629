import React from 'react'

import styles from './styles.module.scss'

const Main = () => (
  <div className={ styles.page }>
    <h1>Добро пожаловать</h1>
  </div>
)

export default Main
