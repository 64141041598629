import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Dropdown from 'rc-dropdown'
import { withRouter } from 'react-router-dom'
import { fromQueryString, toQueryString } from 'utils/query'

import { Actions, Selectors } from 'ducks'
import { FunctionUtils } from 'utils'
import debounce from 'lodash/debounce'

import Spinner from 'components/Spinner'
import Table from 'components/Table'
import Modal from 'components/Modal'
import Form from 'components/Form'
import FilterForm from 'components/Form/FilterForm'
import Svg from 'components/Svg'
import cx from 'classnames'

import styles from './styles.module.scss'
import 'rc-dropdown/assets/index.css'
import 'react-virtualized/styles.css'

class FreeKeys extends PureComponent {
  constructor(props) {
    super(props)
    this.loadOptions = debounce(this.debounceLoadOptions, 300)
    this.loadOptionsGroupName = debounce(this.debounceLoadOptionsGroupName, 300)

    const query = (this.props.location && this.props.location.search)
      ? fromQueryString(this.props.location.search)
      : {
        freeKeys: {
          limit: 10,
          offset: 0,
          order: {
            field: 'id',
            direct: 'DESC',
          },
        },
      }

    this.state = {
      KEYS_COLUMN: [
        {
          name: 'ID',
          dataKey: 'id',
          maxWidth: 35,
          className: styles.centerText,
        },
        {
          name: 'Ключ',
          dataKey: 'key',
          flexGrow: 1,
          className: styles.keyColumn,
        },
        {
          name: 'Пользователь',
          dataKey: null,
          flexGrow: 1,
          maxWidth: 250,
          cellDataGetter: ({ rowData }) => rowData.User,
          cellRenderer: ({ rowData }) => ((rowData.User)
            ? rowData.User.name.full
            : (
              <div className={ styles.banCell }>
                { ((this.props.role === 'distributor')
                  ? <p>Не привязан</p>
                  : (
                    <div className={ styles.banCell }>
                      <Svg
                        name="icon_add"
                        className={ styles.addUserIcon }
                        onClick={ () => this.makeAddToUserFormValue(rowData) }
                      />
                      <p className={ styles.banDate }>Привязать</p>
                    </div>
                  )
                ) }
              </div>
            )
          ),
        },
        {
          name: 'Привязать к группе',
          dataKey: null,
          flexGrow: 1,
          maxWidth: 100,
          cellRenderer: ({ rowData }) => (
            <div className={ styles.banCell }>
              <Svg
                name="icon_add"
                className={ styles.addUserIcon }
                onClick={ () => this.makeAddToGroupFormValue(rowData) }
              />
              <p className={ styles.banDate }>Привязать</p>
            </div>
          ),
        },
        {
          name: 'Статус',
          dataKey: null,
          maxWidth: 100,
          className: styles.centerText,
          cellDataGetter: ({ rowData }) => rowData.status,
          cellRenderer: ({ cellData }) => {
            switch (cellData) {
              case 'inactive':
                return 'Не активен'
              case 'active':
                return 'Активен'
              case 'expired':
                return 'Истек срок'
              case 'banned':
                return 'Забанен'

              default:
                return null
            }
          },
        },
        {
          name: 'Дата активации',
          maxWidth: 135,
          dataKey: 'activated_at',
          flexGrow: 1,
          className: styles.date,
          cellRenderer: ({ cellData }) => ((cellData)
            ? new Date(cellData).toLocaleDateString('ru-RU')
            : 'Не активирован'),
        },
        {
          name: 'Дата окончания срока действия',
          minWidth: 100,
          maxWidth: 135,
          dataKey: 'expired_at',
          className: styles.date,
          cellRenderer: ({ cellData }) => new Date(cellData).toLocaleDateString('ru-RU'),
        },
        {
          name: 'Дата/причина бана',
          minWidth: 75,
          maxWidth: 100,
          dataKey: 'banned_at',
          flexGrow: 1,
          className: styles.date,
          cellRenderer: ({ rowData }) => ((rowData.banned_at)
            ? (
              <div className={ styles.banCell }>
                <p className={ styles.banDate }>
                  {new Date(rowData.banned_at).toLocaleDateString('ru-RU')}
                </p>
                <div>
                  <Dropdown
                    overlay={ this.renderBanMessage(rowData.comment) }
                  >
                    <Svg name="icon_question" className={ styles.questionIcon } />
                  </Dropdown>
                </div>
              </div>
            )
            : null),
        },
        ...(this.props.role !== 'distributor' ? [{
          name: 'Бан/разбан',
          dataKey: null,
          maxWidth: 50,
          className: styles.id,
          cellRenderer: ({ rowData }) => (
            <div className={ cx(styles.firstColumn, styles.firstColumnCell) }>
              {this.renderBanColumn(rowData)}
            </div>
          ),
        }] : []),
      ],
      GROUP_FIELDS: [
        {
          name: 'id',
          label: 'ID ключа',
          component: 'input',
          disabled: true,
        },
        {
          name: 'key',
          label: 'Ключ',
          component: 'input',
          disabled: true,
        },
        {
          name: 'GCodeId',
          label: 'Группа',
          validate: ['requiredField'],
          component: 'select',
          options: [],
        },
      ],
      ADD_FIELDS: [
        {
          name: 'id',
          label: 'ID ключа',
          component: 'input',
          disabled: true,
        },
        {
          name: 'key',
          label: 'Ключ',
          component: 'input',
          disabled: true,
        },
        {
          name: 'User',
          label: 'Пользователь',
          validate: ['requiredField'],
          component: 'select',
          options: [],
        },
      ],
      BLOCK_FIELDS: [
        {
          name: 'id',
          label: 'ID ключа',
          component: 'input',
          disabled: true,
        },
        {
          name: 'key',
          label: 'Ключ',
          component: 'input',
          disabled: true,
        },
        {
          name: 'comment',
          label: 'Сообщение',
          component: 'textarea',
          validate: ['requiredField'],
        },
      ],
      FILTER_FIELDS: [
        {
          name: 'comment',
          label: 'Комментарий',
          component: 'input',
        },
        ...(this.props.role !== 'distributor' ? [{
          name: 'EmployeeId',
          label: 'ID дистрибьютера',
          component: 'input',
          type: 'number',
        }] : []),
        {
          name: 'key',
          label: 'Ключ',
          component: 'input',
        },
        {
          name: 'activated_at',
          label: 'Дата активации',
          component: 'input',
          placeholder: 'ГГГГ-ММ-ДД',
          validate: ['validateFullDate'],
        },
        {
          name: 'started_at',
          label: 'Дата начала',
          component: 'input',
          placeholder: 'ГГГГ-ММ-ДД',
          validate: ['validateFullDate'],
        },
        {
          name: 'expired_at',
          label: 'Дата окончания',
          component: 'input',
          placeholder: 'ГГГГ-ММ-ДД',
          validate: ['validateFullDate'],
        },
        {
          name: 'banned_at',
          label: 'Дата блокирования',
          component: 'input',
          placeholder: 'ГГГГ-ММ-ДД',
          validate: ['validateFullDate'],
        },
      ],
      showModal: false,
      modalType: '',
      fetchParams: {
        limit: (query.freeKeys && query.freeKeys.limit) ? query.freeKeys.limit : 10,
        offset: (query.freeKeys && query.freeKeys.offset) ? query.freeKeys.offset : 0,
        order: {
          field: (query.freeKeys && query.freeKeys.order && query.freeKeys.order.field)
            ? query.freeKeys.order.field
            : 'id',
          direct: (query.freeKeys && query.freeKeys.order && query.freeKeys.order.direct)
            ? query.freeKeys.order.direct
            : 'DESC',
        },
        filter: (query.freeKeys && query.freeKeys.filter) ? query.freeKeys.filter : undefined,
      },
      tableParams: {
        limit: (query.freeKeys && query.freeKeys.limit) ? query.freeKeys.limit : 10,
        offset: (query.freeKeys && query.freeKeys.offset) ? query.freeKeys.offset : 0,
        field: (query.freeKeys && query.freeKeys.order && query.freeKeys.order.field)
          ? query.freeKeys.order.field
          : 'id',
        direct: (query.freeKeys && query.freeKeys.order && query.freeKeys.order.direct)
          ? query.freeKeys.order.direct
          : 'DESC',
      },
    }
  }

  componentDidMount = async () => {
    await this.props.fetchFreeKeys(this.state.fetchParams)

    const search = (this.props.location && this.props.location.search)
      ? fromQueryString(this.props.location.search)
      : {}

    search.freeKeys = { ...this.state.fetchParams }

    this.props.history.replace({
      pathname: this.props.location.pathname,
      search: toQueryString(search),
    })
  }

  debounceLoadOptions = async value => {
    await this.props.fetchUsersName(value.value)

    if (this.props.usersList.length !== 0) {
      const optionsArr = await this.props.usersList.map(item => {
        const options = {
          value: item.id,
          label: item.name.full,
        }
        return options
      })
      this.setState(prevState => {
        const newUserFields = [...prevState.ADD_FIELDS]
        newUserFields[2].options = optionsArr

        return { ADD_FIELDS: newUserFields }
      })
    }

    return null
  }

  debounceLoadOptionsGroupName = async value => {
    await this.props.fetchGroupsName(value.value)

    if (this.props.groupList.length !== 0) {
      const optionsArr = await this.props.groupList.map(item => {
        const options = {
          value: item.id,
          label: item.name,
        }
        return options
      })
      this.setState(prevState => {
        const newGroupFields = [...prevState.GROUP_FIELDS]
        newGroupFields[2].options = optionsArr

        return { GROUP_FIELDS: newGroupFields }
      })
    }

    return null
  }

  onTableUpdate = tableState => {
    if (tableState) {
      const fetchParams = {
        ...this.state.fetchParams,
      }

      if (typeof tableState.limit === 'number') {
        fetchParams.limit = tableState.limit
      }
      if (typeof tableState.offset === 'number') {
        fetchParams.offset = tableState.offset
      }
      if (tableState.field) {
        fetchParams.order.field = tableState.field
      }
      if (tableState.direct) {
        fetchParams.order.direct = tableState.direct
      }

      this.props.fetchFreeKeys(fetchParams)

      const search = (this.props.location && this.props.location.search)
        ? fromQueryString(this.props.location.search)
        : {}

      search.freeKeys = { ...fetchParams }

      this.props.history.replace({
        pathname: this.props.location.pathname,
        search: toQueryString(search),
      })

      this.setState(prevState => ({
        fetchParams: {
          ...fetchParams,
        },
        tableParams: {
          ...prevState.tableParams,
          ...tableState,
        },
      }))
    }
  }

  renderBanColumn = rowData => {
    switch (rowData.status) {
      case 'active':
        return (
          <Svg
            name="icon_block"
            className={ styles.deleteIcon }
            onClick={ () => this.makeBanFormValue(rowData) }
          />
        )
      case 'banned':
        return (
          <Svg
            name="icon_add"
            className={ styles.addIcon }
            onClick={ () => this.makeUnBanFormValue(rowData) }
          />
        )

      default:
        return null
    }
  }

  makeBanFormValue = rowData => {
    this.setState({
      initialValues: {
        id: rowData.id,
        key: rowData.key,
      },
      showModal: true,
      modalType: 'block',
    })
  }

  makeUnBanFormValue = rowData => {
    this.setState({
      initialValues: {
        id: rowData.id,
        key: rowData.key,
        comment: rowData.comment,
      },
      showModal: true,
      modalType: 'unblock',
    })
  }

  makeAddToUserFormValue = rowData => {
    this.setState({
      initialValues: {
        id: rowData.id,
        key: rowData.key,
      },
      showModal: true,
      modalType: 'add',
    })
  }

  makeAddToGroupFormValue = rowData => {
    this.setState({
      initialValues: {
        id: rowData.id,
        key: rowData.key,
      },
      showModal: true,
      modalType: 'group',
    })
  }

  renderBanMessage = message => (
    <div className={ styles.dropdownMenu }>
      <p>
        <strong>Причина блокировки: </strong>{message}
      </p>
    </div>
  )

  renderKeysTable = () => (
    <div className={ styles.keysTable }>
      <Table
        list={ this.props.freeKeys }
        count={ this.props.freeKeysCount }
        params={ this.state.tableParams }
        onTableUpdate={ this.onTableUpdate }
        columns={ this.state.KEYS_COLUMN }
      />
    </div>
  )

  renderKeys = () => (
    <div className={ styles.keys }>
      <div className={ styles.header }>
        <div className={ styles.label }>
          Непривязанные ключи
        </div>
        <div
          className={ styles.filter }
          role="button"
          onClick={ this.showFilterModal }
          tabIndex={ 0 }
          onKeyPress={ this.showFilterModal }
        >
          <Svg name="icon_filter" />
          <div className={ styles.filter__text }>
            Расширенный поиск
          </div>
        </div>
        {this.state.fetchParams.filter && (
          <div
            role="button"
            onClick={ this.removeFilter }
            tabIndex={ 0 }
            onKeyPress={ this.removeFilter }
          >
            <div className={ styles.filter__remove }>
              Отчистить фильтр
            </div>
          </div>
        )}
      </div>
      {this.renderKeysTable()}
    </div>
  )

  renderForm = type => {
    switch (type) {
      case 'block':
        return this.renderKeyBlockForm(this.state.BLOCK_FIELDS)
      case 'unblock':
        return this.renderKeyUnBlockForm(this.state.BLOCK_FIELDS)
      case 'add':
        return this.renderAddToUserForm(this.state.ADD_FIELDS)
      case 'filter':
        return this.renderFilterForm(this.state.FILTER_FIELDS)
      case 'group':
        return this.renderAddToGroupForm(this.state.GROUP_FIELDS)

      default:
        return null
    }
  }

  renderAddToUserForm = fields => (
    <Form
      title="Привязка ключа пользователю"
      isLoadingSelector={ Selectors.Codes.isLoadingKeys }
      submittingText="Привязать"
      cancelText="Отменить"
      cancelAction={ () => this.setState({ showModal: false }) }
      fields={ fields }
      initialValues={ this.state.initialValues }
      submitAction={ Actions.Codes.addUser }
      loadOptions={ value => this.loadOptions(value) }
      afterSubmitAction={ this.props.fetchFreeKeys }
      afterSubmitActionArgs={ this.state.fetchParams }
    />
  )

  renderAddToGroupForm = fields => (
    <Form
      title="Привязка ключа группе"
      isLoadingSelector={ Selectors.Codes.isLoadingKeys }
      submittingText="Привязать"
      cancelText="Отменить"
      cancelAction={ () => this.setState({ showModal: false }) }
      fields={ fields }
      initialValues={ this.state.initialValues }
      submitAction={ Actions.Codes.addKeyToGroup }
      loadOptions={ value => this.loadOptionsGroupName(value) }
      afterSubmitAction={ this.props.fetchFreeKeys }
      afterSubmitActionArgs={ this.state.fetchParams }
    />
  )

  renderKeyBlockForm = fields => (
    <Form
      title="Заблокировать ключ"
      isLoadingSelector={ Selectors.Codes.isLoadingKeys }
      submittingText="Заблокировать"
      cancelText="Отменить"
      cancelAction={ () => this.setState({ showModal: false }) }
      fields={ fields }
      submitAction={ Actions.Codes.banKeys }
      initialValues={ this.state.initialValues }
      afterSubmitAction={ this.props.fetchFreeKeys }
      afterSubmitActionArgs={ this.state.fetchParams }
    />
  )

  renderKeyUnBlockForm = fields => (
    <Form
      title="Разблокировать ключ"
      isLoadingSelector={ Selectors.Codes.isLoadingKeys }
      submittingText="Разблокировать"
      cancelText="Отменить"
      cancelAction={ () => this.setState({ showModal: false }) }
      fields={ fields }
      submitAction={ Actions.Codes.unbanKeys }
      initialValues={ this.state.initialValues }
      afterSubmitAction={ this.props.fetchFreeKeys }
      afterSubmitActionArgs={ this.state.fetchParams }
    />
  )

  showFilterModal = evt => {
    if (evt.type === 'keypress' && evt.key !== 'Enter') {
      return
    }

    this.setState(state => ({
      initialValues: { ...state.fetchParams.filter },
      showModal: true,
      modalType: 'filter',
    }))
  }

  setFilter = values => {
    this.setState(state => {
      const fetchParams = {
        ...state.fetchParams,
        ...values,
      }
      const search = (this.props.location && this.props.location.search)
        ? fromQueryString(this.props.location.search)
        : {}

      search.freeKeys = { ...fetchParams }

      this.props.history.replace({
        pathname: this.props.location.pathname,
        search: toQueryString(search),
      })

      return {
        showModal: false,
        fetchParams,
      }
    })
  }

  removeFilter = evt => {
    if (evt.type === 'keypress' && evt.key !== 'Enter') {
      return
    }

    if (!this.state.fetchParams.filter || !Object.keys(this.state.fetchParams.filter).length) {
      return
    }

    this.setState(state => {
      const fetchParams = {
        ...state.fetchParams,
        filter: undefined,
      }

      this.props.fetchFreeKeys(fetchParams)

      const search = (this.props.location && this.props.location.search)
        ? fromQueryString(this.props.location.search)
        : {}

      search.freeKeys = { ...fetchParams }

      this.props.history.replace({
        pathname: this.props.location.pathname,
        search: toQueryString(search),
      })

      return {
        fetchParams,
      }
    })
  }

  renderFilterForm = fields => (
    <FilterForm
      title="Расширенный поиск"
      isLoadingSelector={ Selectors.Codes.isLoadingKeys }
      submittingText="Поиск"
      cancelText="Отменить"
      cancelAction={ () => this.setState({ showModal: false }) }
      initialValues={ this.state.initialValues }
      fields={ fields }
      submitAction={ values => Actions.Codes.fetchFreeKeys({ ...this.state.fetchParams, ...values }) }
      afterSubmitAction={ this.setFilter }
    />
  )

  renderModal = () => (
    <Modal isOpen={ this.state.showModal } onRequestClose={ () => this.setState({ showModal: false }) }>
      {this.state.showModal ? this.renderForm(this.state.modalType) : false}
    </Modal>
  )

  render() {
    return (
      <div className={ cx(styles.page, { [styles.spinner]: this.props.isLoading }) }>
        {this.props.isLoading ? <Spinner /> : this.renderKeys()}
        {this.renderModal()}
      </div>
    )
  }
}

FreeKeys.propTypes = {
  fetchFreeKeys: PropTypes.func.isRequired,
  fetchUsersName: PropTypes.func.isRequired,
  fetchGroupsName: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  freeKeys: PropTypes.arrayOf(
    PropTypes.object,
  ).isRequired,
  usersList: PropTypes.arrayOf(
    PropTypes.object,
  ).isRequired,
  groupList: PropTypes.arrayOf(
    PropTypes.object,
  ).isRequired,
  freeKeysCount: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired, //eslint-disable-line
  history: PropTypes.object.isRequired, //eslint-disable-line
  role: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
  isLoading: Selectors.Codes.isLoadingKeys(state),
  freeKeys: Selectors.Codes.freeKeys(state),
  freeKeysCount: Selectors.Codes.freeKeysCount(state),
  usersList: Selectors.Students.autocompleteList(state),
  groupList: Selectors.Codes.autocompleteList(state),
  role: Selectors.Auth.role(state),
})

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchFreeKeys: Actions.Codes.fetchFreeKeys,
  fetchUsersName: Actions.Students.fetchByName,
  fetchGroupsName: Actions.Codes.fetchGroupsByName,
}, dispatch)

export default FunctionUtils.compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(FreeKeys)
