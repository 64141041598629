const requiredField = input => (!input)

const validateDate = input => !(input >= 1 && input <= 31)

const validateYear = input => !(input >= 1900 && input <= new Date().getFullYear())

const validateEmail = input => (
  (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(input))
    ? 'Некорректный электронный адрес'
    : false
)

const validateFullDate = input => {
  if (input === '' || input === undefined) {
    return false
  }

  if (!/^\d{4}-\d{2}-\d{2}/g.test(input)) {
    return 'Некорректный формат даты'
  }

  try {
    const date = new Date(input)
    if (date instanceof Date && !isNaN(date)) {
      return false
    }

    return 'Некорректный формат даты'
  } catch (e) {
    return 'Некорректный формат даты'
  }
}

const matchPassword = (input, allInputs) => (input === allInputs.password ? false : 'Пароли не совпадают')

const validation = {
  requiredField,
  validateDate,
  validateYear,
  matchPassword,
  validateEmail,
  validateFullDate,
}

export default validation
