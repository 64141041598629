/* eslint-disable react/jsx-props-no-spreading */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import { Field, reduxForm, SubmissionError } from 'redux-form'

import { FunctionUtils } from 'utils'

import uniqueId from 'lodash/uniqueId'
import Spinner from 'components/Spinner'
import Button from 'components/Button'
import FileInput from 'components/FileInput'


import cx from 'classnames'
import {
  EditorState, ContentState, convertToRaw, convertFromHTML,
} from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'

import validation from '../validation'

import styles from './styles.module.scss'


class NewsForm extends PureComponent {
  constructor(props) {
    super(props)

    let editorState = EditorState.createEmpty()

    if (this.props.initialValues && this.props.initialValues.text) {
      editorState = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(this.props.initialValues.text),
        ),
      )
    }

    this.state = {
      editorState,
    }
  }

  editor = ({
    input, meta: { touched, error },
  }) => (
    <Editor
      { ...input }
      editorState={ this.state.editorState }
      value={ this.state.editorState }
      wrapperClassName="demo-wrapper"
      editorClassName={ ((touched && error) ? 'editor_error' : 'editor') }
      onEditorStateChange={ editorState => {
        const newValue = draftToHtml(convertToRaw(editorState.getCurrentContent()))

        if (input.value !== newValue) {
          input.onChange(newValue)
        }

        this.setState({ editorState })
      } }

      toolbar={ {
        inline: { inDropdown: false },
        list: { inDropdown: true },
        textAlign: { inDropdown: true },
        link: { inDropdown: true },
        history: { inDropdown: true },
      } }
    />
  )

  inputFile = props => (
    <FileInput
      value={ props.input.value }
      onChange={ props.input.onChange }
      initialValues={ this.props.initialValues }
    />
  )

  // eslint-disable-next-line
  input = ({ input, label, placeholder, type, style, disabled, meta: { touched, error }, }) => {
    return (
      <input
        { ...input }
        placeholder={ placeholder || label }
        type={ type }
        className={ cx(styles.input, style,
          { [styles.input_error]: touched && error },
          { [styles.input_checkbox]: (type === 'checkbox') },
          { [styles.input_disabled]: disabled }) }
        disabled={ disabled || false }
      />

    )
  }

  validateField = validate => validate.map(item => validation[item])

  renderFieldComponent = component => (this[component])

  renderField = field => (
    <div className={ cx(styles.fieldWrapper, field.className) } key={ field.name }>
      {field.label ? (
        <p className={ styles.fieldLabel }>
          {`${field.label}:`}
        </p>
      ) : false}
      <Field
        type={ field.type }
        name={ field.name }
        label={ field.label }
        placeholder={ field.placeholder }
        component={ this.renderFieldComponent(field.component) }
        validate={ field.validate ? this.validateField(field.validate) : null }
        options={ field.options }
        disabled={ field.disabled || false }
      />
    </div>
  )

  renderFields = fields => fields.map(item => this.renderField(item))

  rendersubmitSucceeded = title => (
    <p className={ styles.textSucceeded }>{`${title} прошло успешло`}</p>
  )

  render() {
    const {
      // eslint-disable-next-line
      error, pristine, submitting, invalid, handleSubmit, submitSucceeded,
    } = this.props

    const {
      title, cancelAction, cancelText, submittingText, fields,
    } = this.props

    return (
      <div className={ styles.container }>
        <p className={ styles.formTitle }>{title}</p>
        {this.props.isLoading ? <Spinner /> : (
          <form onSubmit={ handleSubmit } className={ styles.formContainer }>
            {submitSucceeded ? this.rendersubmitSucceeded(title) : this.renderFields(fields)}
            {(error === undefined)
              ? (!pristine && invalid && <div className={ styles.error }>Заполнены не все поля</div>)
              : (error && <div className={ styles.error }>{error}</div>)}
            <div className={ styles.buttonGroup }>
              {submitSucceeded ? false : (
                <Button
                  className={ styles.cancelButton }
                  color="white"
                  filled
                  onClick={ cancelAction }
                >
                  {cancelText}
                </Button>
              )}
              <Button
                className={ styles.submitButton }
                color="blue"
                filled
                disabled={ submitting || pristine || invalid }
                type={ submitSucceeded ? 'button' : 'submit' }
                onClick={ submitSucceeded ? cancelAction : undefined }
              >
                {submitSucceeded ? 'Закрыть' : submittingText}
              </Button>
            </div>
          </form>
        )}
      </div>
    )
  }
}

NewsForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fields: PropTypes.array,
  cancelAction: PropTypes.func.isRequired,
  cancelText: PropTypes.string.isRequired,
  submittingText: PropTypes.string.isRequired,
  submitAction: PropTypes.func.isRequired,
  afterSubmitAction: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  afterSubmitActionArgs: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.object,
}

NewsForm.defaultProps = {
  fields: [],
  afterSubmitAction: () => {},
  afterSubmitActionArgs: {},
  initialValues: undefined,
}

const mapStateToProps = (state, ownProps) => ({
  isLoading: ownProps.isLoadingSelector(state),
  initialValues: ownProps.initialValues,
})

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  submitAction: ownProps.submitAction,
}, dispatch)

export default FunctionUtils.compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: uniqueId('CommonForm-'),
    onSubmit: (values, dispatch, props) => props.submitAction(values)
      .then(() => {
        if (typeof props.afterSubmitAction === 'function') {
          props.afterSubmitAction(props.afterSubmitActionArgs)
        }
      })
      .catch(e => {
        throw new SubmissionError({
          _error: 'Что-то пошло не так',
        })
      }),
    keepDirtyOnReinitialize: true,
    enableReinitialize: true,
    updateUnregisteredFields: true,
  }),
)(NewsForm)
