import {
  call,
  put,
  putResolve,
  takeLatest,
} from 'redux-saga/effects'

import {
  FeedbackService,
} from 'services'

import {
  fetchBids,
  fetchBidsCount,
  appruveBid,

  __internal,
} from './actions'

function* fetchBidsTask(action) {
  yield put(__internal.fetchBids.started())

  try {
    const data = yield call([FeedbackService, 'fetchBids'], action.payload)

    yield putResolve(__internal.fetchBids.successed(data))
  } catch (err) {
    yield put(__internal.fetchBids.failed(err))
  }

  yield put(__internal.fetchBids.finished())
}

function* fetchBidsCountTask() {
  yield put(__internal.fetchBidsCount.started())

  try {
    const data = yield call([FeedbackService, 'fetchBidsCount'])

    yield putResolve(__internal.fetchBidsCount.successed(data))
  } catch (err) {
    yield put(__internal.fetchBidsCount.failed(err))
  }

  yield put(__internal.fetchBidsCount.finished())
}

function* appruveBidTask(action) {
  yield put(__internal.appruveBid.started())

  try {
    yield call([FeedbackService, 'appruveBid'], { id: action.payload })

    yield putResolve(__internal.appruveBid.successed())
  } catch (err) {
    yield put(__internal.appruveBid.failed(err))
  }

  yield put(__internal.appruveBid.finished())
}

export default function* () {
  yield takeLatest(fetchBids, fetchBidsTask)
  yield takeLatest(fetchBidsCount, fetchBidsCountTask)
  yield takeLatest(appruveBid, appruveBidTask)
}
