import React from 'react'

import { Route as ReactRouterRoute } from 'react-router'

import Protected from './Protected'

const Route = props => React.createElement(ReactRouterRoute, props)

Route.propTypes = ReactRouterRoute.propTypes

Route.Protected = Protected

export default Route
