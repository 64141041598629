import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { fromQueryString, toQueryString } from 'utils/query'

import { Actions, Selectors } from 'ducks'
import { FunctionUtils, GeoNameUtils } from 'utils'
import debounce from 'lodash/debounce'

import DeleteForm from 'components/Form/DeleteForm'
import Spinner from 'components/Spinner'
import Button from 'components/Button'
import Table from 'components/Table'
import Modal from 'components/Modal'
import Form from 'components/Form'
import FilterForm from 'components/Form/FilterForm'
import Svg from 'components/Svg'

import cx from 'classnames'

import styles from './styles.module.scss'
import 'react-virtualized/styles.css'

class Institutions extends PureComponent {
  constructor(props) {
    super(props)

    this.loadOptions = debounce(this.debounceLoadOptions, 700)

    const query = (this.props.location && this.props.location.search)
      ? fromQueryString(this.props.location.search)
      : {
        limit: 10,
        offset: 0,
        order: {
          field: 'id',
          direct: 'DESC',
        },
      }

    this.state = {
      COLUMN: [
        {
          dataKey: null,
          maxWidth: 25,
          className: styles.id,
          headerRenderer: () => (
            <div className={ cx(styles.firstColumn, styles.firstColumnHeader) }>
              <Svg name="icon_edit" />
            </div>
          ),
          cellRenderer: ({ rowData }) => (
            <div className={ cx(styles.firstColumn, styles.firstColumnCell) }>
              <Svg
                name="icon_edit"
                className={ styles.editIcon }
                onClick={ () => this.makeEditFormValue(rowData) }
              />
            </div>
          ),
        },
        {
          name: 'ID',
          dataKey: 'id',
          maxWidth: 35,
          className: styles.id,
        },
        {
          name: 'Наименование',
          dataKey: 'name',
          flexGrow: 1,
        },
        {
          name: 'Страна',
          dataKey: 'Country.name',
          cellDataGetter: ({ rowData }) => ((rowData.Country) ? rowData.Country.name : ''),
          flexGrow: 1,
        },
        {
          name: 'Город',
          dataKey: 'City.name',
          cellDataGetter: ({ rowData }) => ((rowData.City) ? rowData.City.name : ''),
          flexGrow: 1,
        },
        {
          name: 'Адрес',
          dataKey: 'address',
          flexGrow: 1,
        },
        {
          name: 'Учащихся',
          dataKey: 'users',
          maxWidth: 80,
        },
        {
          name: 'Контактное лицо',
          dataKey: 'contact_name',
          flexGrow: 1,
        },
        {
          name: 'Контактные данные',
          dataKey: 'contacts',
          flexGrow: 1,
        },
        {
          name: 'Электронная почта ОУ',
          dataKey: 'email',
          minWidth: 85,
          flexGrow: 1,
        },
        {
          name: 'Контактный телефон',
          dataKey: 'phone',
          maxWidth: 100,
        },
        {
          name: 'Сайт',
          dataKey: 'url',
          flexGrow: 1,
        },
        {
          dataKey: null,
          maxWidth: 25,
          className: styles.id,
          headerRenderer: () => (
            <div className={ cx(styles.firstColumn, styles.firstColumnHeader) }>
              <Svg name="icon_delete" />
            </div>
          ),
          cellRenderer: ({ rowData }) => (
            <div className={ cx(styles.firstColumn, styles.firstColumnCell) }>
              <Svg
                name="icon_delete"
                className={ styles.deleteIcon }
                onClick={ () => this.setState({ showModal: true, modalType: 'delete', rowData }) }
              />
            </div>
          ),
        },
      ],
      ADD_FIELDS: [
        {
          name: 'name',
          label: 'Наименование',
          validate: ['requiredField'],
          component: 'input',
        },
        {
          name: 'country',
          label: 'Страна',
          validate: ['requiredField'],
          component: 'select',
          options: [],
        },
        {
          name: 'city',
          label: 'Город',
          validate: ['requiredField'],
          component: 'select',
          options: [],
        },
        {
          name: 'address',
          label: 'Адрес',
          validate: ['requiredField'],
          component: 'input',
        },
        {
          name: 'users',
          label: 'Учащихся',
          type: 'number',
          validate: ['requiredField'],
          component: 'input',
        },
        {
          name: 'contact_name',
          label: 'Контактное лицо',
          validate: ['requiredField'],
          component: 'input',
        },
        {
          name: 'contacts',
          label: 'Контактные данные',
          validate: ['requiredField'],
          component: 'input',
        },
        {
          name: 'email',
          label: 'Электронная почта',
          validate: ['validateEmail', 'requiredField'],
          component: 'input',
        },
        {
          name: 'phone',
          label: 'Контактный телефон',
          validate: ['requiredField'],
          component: 'input',
        },
        {
          name: 'url',
          label: 'Сайт',
          validate: ['requiredField'],
          component: 'input',
        },
      ],
      EDIT_FIELDS: [
        {
          name: 'id',
          label: 'ID',
          component: 'input',
          disabled: true,
        },
        {
          name: 'name',
          label: 'Наименование',
          validate: ['requiredField'],
          component: 'input',
        },
        {
          name: 'Country',
          label: 'Страна',
          validate: ['requiredField'],
          component: 'select',
          options: [],
        },
        {
          name: 'City',
          label: 'Город',
          validate: ['requiredField'],
          component: 'select',
          options: [],
        },
        {
          name: 'address',
          label: 'Адрес',
          validate: ['requiredField'],
          component: 'input',
        },
        {
          name: 'users',
          label: 'Учащихся',
          type: 'number',
          validate: ['requiredField'],
          component: 'input',
        },
        {
          name: 'contact_name',
          label: 'Контактное лицо',
          validate: ['requiredField'],
          component: 'input',
        },
        {
          name: 'contacts',
          label: 'Контактные данные',
          validate: ['requiredField'],
          component: 'input',
        },
        {
          name: 'email',
          label: 'Электронная почта',
          validate: ['validateEmail', 'requiredField'],
          component: 'input',
        },
        {
          name: 'phone',
          label: 'Контактный телефон',
          validate: ['requiredField'],
          component: 'input',
        },
        {
          name: 'url',
          label: 'Сайт',
          validate: ['requiredField'],
          component: 'input',
        },
      ],
      FILTER_FIELDS: [
        {
          name: 'id',
          label: 'ID Школы',
          component: 'input',
          type: 'number',
        },
        {
          name: 'name',
          label: 'Название',
          component: 'input',
        },
        {
          name: 'email',
          label: 'Email',
          component: 'input',
          validate: ['validateEmail'],
        },
        {
          name: 'address',
          label: 'Адрес',
          component: 'input',
        },
        {
          name: 'users',
          label: 'Пользователи',
          component: 'input',
          type: 'number',
        },
        {
          name: 'contact_name',
          label: 'Контактное лицо',
          component: 'input',
        },
        {
          name: 'contacts',
          label: 'Контакты',
          component: 'input',
        },

        {
          name: 'url',
          label: 'Сайт',
          component: 'input',
        },
        {
          name: 'phone',
          label: 'Телефон',
          component: 'input',
        },
        {
          name: 'country',
          label: 'Страна',
          component: 'input',
        },
        {
          name: 'city',
          label: 'Город',
          component: 'input',
        },
      ],
      showModal: false,
      modalType: '',
      selectedCountry: '',
      initialValues: {},
      rowData: null,
      fetchParams: {
        limit: query.limit || 10,
        offset: query.offset || 0,
        order: {
          field: (query.order && query.order.field) ? query.order.field : 'id',
          direct: (query.order && query.order.direct) ? query.order.direct : 'DESC',
        },
        filter: query.filter || undefined,

      },
      tableParams: {
        limit: query.limit || 10,
        offset: query.offset || 0,
        field: (query.order && query.order.field) ? query.order.field : 'id',
        direct: (query.order && query.order.direct) ? query.order.direct : 'DESC',
      },
    }
  }

  componentDidMount = async () => {
    const [, countries] = await Promise.all([
      this.props.fetchInstitutions(this.state.fetchParams),
      GeoNameUtils.getCountries(),
    ])

    this.setState(state => {
      const newState = {
        ...state,
        ADD_FIELDS: [...state.ADD_FIELDS],
        EDIT_FIELDS: [...state.EDIT_FIELDS],
      }

      newState.ADD_FIELDS[1].options = countries
      newState.EDIT_FIELDS[2].options = countries

      return newState
    })

    this.props.history.replace({
      pathname: this.props.location.pathname,
      search: toQueryString(this.state.fetchParams),
    })
  }

  renderTable = () => (
    <div className={ styles.AutoSizeWrapper }>
      <Table
        className={ styles.table }
        headerClassName={ styles.header }
        columns={ this.state.COLUMN }
        list={ this.props.list }
        count={ this.props.count }
        params={ this.state.tableParams }
        onTableUpdate={ this.onTableUpdate }
      />
    </div>
  )

  onTableUpdate = tableState => {
    if (tableState) {
      const fetchParams = {
        ...this.state.fetchParams,
      }

      if (typeof tableState.limit === 'number') {
        fetchParams.limit = tableState.limit
      }
      if (typeof tableState.offset === 'number') {
        fetchParams.offset = tableState.offset
      }
      if (tableState.field) {
        fetchParams.order.field = tableState.field
      }
      if (tableState.direct) {
        fetchParams.order.direct = tableState.direct
      }

      this.props.fetchInstitutions(fetchParams)

      this.props.history.replace({
        pathname: this.props.location.pathname,
        search: toQueryString(fetchParams),
      })

      this.setState(prevState => ({
        fetchParams: {
          ...fetchParams,
        },
        tableParams: {
          ...prevState.tableParams,
          ...tableState,
        },
      }))
    }
  }

  debounceLoadOptions = async value => {
    if (value !== null && !!this.state.selectedCountry && this.state.selectedCountry.length !== 0) {
      const cities = await GeoNameUtils.getCities(this.state.selectedCountry, value.value)

      this.setState(prevState => {
        const ADD_FIELDS = [...prevState.ADD_FIELDS]
        const EDIT_FIELDS = [...prevState.EDIT_FIELDS]
        ADD_FIELDS[2].options = cities
        EDIT_FIELDS[3].options = cities
        return { ADD_FIELDS, EDIT_FIELDS }
      })
    }
  }

  renderOnChangeSelect = async value => {
    if (value !== null) {
      this.setState({ selectedCountry: value.countryCode })
    } else {
      this.setState({ selectedCountry: '' })
    }
  }

  renderDeleteForm = data => (
    <DeleteForm
      title="Удаление образовательного учреждения"
      isLoadingSelector={ Selectors.Institutions.isLoading }
      submittingText="Да"
      cancelText="Отменить"
      cancelAction={ () => this.setState({ showModal: false }) }
      submitAction={ Actions.Institutions.del }
      data={ data }
      afterSubmitAction={ this.props.fetchInstitutions }
      afterSubmitActionArgs={ this.state.fetchParams }
    />
  )

  renderAddForm = fields => (
    <Form
      title="Добавление образовательного учреждения"
      isLoadingSelector={ Selectors.Institutions.isLoading }
      submittingText="Добавить"
      cancelText="Отменить"
      cancelAction={ () => this.setState({ showModal: false }) }
      fields={ fields }
      submitAction={ Actions.Institutions.create }
      onChangeSelect={ (_, value) => this.renderOnChangeSelect(value) }
      loadOptions={ this.loadOptions }
      afterSubmitAction={ this.props.fetchInstitutions }
      afterSubmitActionArgs={ this.state.fetchParams }
    />
  )

  renderEditForm = fields => (
    <Form
      title="Изменение образовательного учреждения"
      isLoadingSelector={ Selectors.Institutions.isLoading }
      submittingText="Изменить"
      cancelText="Отменить"
      cancelAction={ () => this.setState({ showModal: false }) }
      fields={ fields }
      submitAction={ Actions.Institutions.edit }
      onChangeSelect={ value => this.renderOnChangeSelect(value) }
      loadOptions={ value => this.loadOptions(value) }
      initialValues={ this.state.initialValues }
      afterSubmitAction={ this.props.fetchInstitutions }
      afterSubmitActionArgs={ this.state.fetchParams }
    />
  )

  makeEditFormValue = rowData => {
    this.setState({
      initialValues: {
        id: rowData.id,
        name: rowData.name,
        Country: {
          value: rowData.Country.geonames_id,
          label: rowData.Country.name,
        },
        City: {
          value: rowData.City.geonames_id,
          label: rowData.City.name,
          lon: rowData.City.lon,
          lat: rowData.City.lat,
        },
        address: rowData.address,
        users: rowData.users,
        contact_name: rowData.contact_name,
        contacts: rowData.contacts,
        email: rowData.email,
        phone: rowData.phone,
        url: rowData.url,
      },
      showModal: true,
      modalType: 'edit',
    })
  }

  renderForm = type => {
    switch (type) {
      case 'add':
        return this.renderAddForm(this.state.ADD_FIELDS)
      case 'delete':
        return this.renderDeleteForm(this.state.rowData)
      case 'edit':
        return this.renderEditForm(this.state.EDIT_FIELDS)
      case 'filter':
        return this.renderFilterForm(this.state.FILTER_FIELDS)
      default:
        return null
    }
  }

  renderModal = () => (
    <Modal isOpen={ this.state.showModal } onRequestClose={ () => this.setState({ showModal: false }) }>
      {this.state.showModal ? this.renderForm(this.state.modalType) : false}
    </Modal>
  )


  showFilterModal = evt => {
    if (evt.type === 'keypress' && evt.key !== 'Enter') {
      return
    }

    this.setState(state => ({
      initialValues: { ...state.fetchParams.filter },
      showModal: true,
      modalType: 'filter',
    }))
  }

  setFilter = values => {
    this.setState(state => {
      const fetchParams = {
        ...state.fetchParams,
        ...values,
      }

      this.props.history.replace({
        pathname: this.props.location.pathname,
        search: toQueryString(fetchParams),
      })

      return {
        showModal: false,
        fetchParams,
      }
    })
  }

  removeFilter = evt => {
    if (evt.type === 'keypress' && evt.key !== 'Enter') {
      return
    }

    if (!this.state.fetchParams.filter || !Object.keys(this.state.fetchParams.filter).length) {
      return
    }

    this.setState(state => {
      const fetchParams = {
        ...state.fetchParams,
        filter: undefined,
      }

      this.props.fetchInstitutions(fetchParams)

      this.props.history.replace({
        pathname: this.props.location.pathname,
        search: toQueryString(fetchParams),
      })

      return {
        fetchParams,
      }
    })
  }

  renderFilterForm = fields => (
    <FilterForm
      title="Расширенный поиск"
      isLoadingSelector={ Selectors.Institutions.isLoading }
      submittingText="Поиск"
      cancelText="Отменить"
      cancelAction={ () => this.setState({ showModal: false }) }
      initialValues={ this.state.initialValues }
      fields={ fields }
      submitAction={ values => Actions.Institutions.fetch({ ...this.state.fetchParams, ...values }) }
      afterSubmitAction={ this.setFilter }
    />
  )

  render() {
    return (
      <div className={ cx(styles.page, { [styles.spinner]: this.props.isLoading }) }>
        {this.props.isLoading ? <Spinner /> : (
          <div className={ styles.container }>
            <div className={ styles.header }>
              <div className={ styles.label }>
                Образовательные учреждения
              </div>
              <Button
                className={ styles.button }
                color="blue"
                filled
                onClick={ () => this.setState({ showModal: true, modalType: 'add' }) }
              >
                Добавить новое ОУ
              </Button>
              <div
                className={ styles.filter }
                role="button"
                onClick={ this.showFilterModal }
                tabIndex={ 0 }
                onKeyPress={ this.showFilterModal }
              >
                <Svg name="icon_filter" />
                <div className={ styles.filter__text }>
                  Расширенный поиск
                </div>
              </div>
              {this.state.fetchParams.filter && (
                <div
                  role="button"
                  onClick={ this.removeFilter }
                  tabIndex={ 0 }
                  onKeyPress={ this.removeFilter }
                >
                  <div className={ styles.filter__remove }>
                    Отчистить фильтр
                  </div>
                </div>
              )}
            </div>
            {this.renderTable()}
          </div>
        )}
        {this.renderModal()}
      </div>
    )
  }
}

Institutions.propTypes = {
  fetchInstitutions: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.object,
  ).isRequired,
  count: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired, //eslint-disable-line
  history: PropTypes.object.isRequired, //eslint-disable-line
}

const mapStateToProps = state => ({
  isLoading: Selectors.Institutions.isLoading(state),
  list: Selectors.Institutions.list(state),
  count: Selectors.Institutions.count(state),
})

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchInstitutions: Actions.Institutions.fetch,
}, dispatch)

export default FunctionUtils.compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Institutions)
