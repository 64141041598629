import { combineActions, handleActions } from 'redux-actions'

import produce from 'immer'

import {
  __internal,
} from './actions'

const initialState = {
  loading: false,
}

const startedActions = combineActions(
  __internal.bid.started,
  __internal.feedback.started,
  __internal.teacher.started,
)

const finishedActions = combineActions(
  __internal.bid.finished,
  __internal.feedback.finished,
  __internal.teacher.finished,
)

const reducer = handleActions({
  [startedActions]: state => produce(state, next => {
    next.loading = true
  }),
  [finishedActions]: state => produce(state, next => {
    next.loading = false
  }),
}, initialState)

export default reducer
