const {
  REACT_APP_API_BASE_URL: BASE_URL,
  REACT_APP_API_ORIGIN: ORIGIN,
  PUBLIC_URL,
} = process.env

module.exports = {
  BASE_URL,
  PUBLIC_URL,
  ORIGIN,
}
