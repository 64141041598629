import {
  call,
  takeLatest,
  put,
  select,
} from 'redux-saga/effects'

import I18n from 'components/I18n'

import { accessToken } from 'ducks/auth/selectors'

import {
  APIService,
} from 'services'

import {
  init,
  setLocale,

  __internal,
} from './actions'

function* initTask() {
  yield put(__internal.init.started())

  try {
    const token = yield select(accessToken)
    yield call([APIService, 'setAccessToken'], token)

    yield put(__internal.init.successed())
  } catch (err) {
    yield put(__internal.init.failed(err))
  }

  yield put(__internal.init.finished())
}

function* setLocaleTask({ payload }) {
  yield call([I18n, 'setLocale'], payload)
}

export default function* () {
  yield takeLatest(init, initTask)
  yield takeLatest(setLocale, setLocaleTask)
}
