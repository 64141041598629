import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  AutoSizer,
  Column,
  Table,
  CellMeasurerCache,
} from 'react-virtualized'

import cx from 'classnames'

import styles from './styles.module.scss'
import 'react-virtualized/styles.css'

const VirtualizedTable = props => {
  const [state, setState] = useState({
    columnState: {
      [props.params.field]: props.params.direct,
    },
    tableState: {
      page: 1,
      pages: 1,
    },
  })

  useEffect(() => {
    setState({
      columnState: {
        [props.params.field]: props.params.direct,
      },
      tableState: {
        page: props.params.offset + 1,
        pages: Math.ceil(props.count / props.params.limit),
      },
    })
  },
  [props.count, props.params.direct, props.params.field, props.params.limit, props.params.offset])

  const _cache = new CellMeasurerCache({ fixedWidth: true, minHeight: 37 })

  const onHeaderClick = ({ dataKey }) => {
    if (dataKey && dataKey !== 'no_use') {
      const direct = (state.columnState[dataKey] && state.columnState[dataKey] === 'DESC')
        ? 'ASC'
        : 'DESC'

      setState({
        columnState: {
          [dataKey]: direct,
        },
      })

      props.onTableUpdate({
        field: dataKey,
        direct,
      })
    }
  }

  const onPageClick = page => {
    if (page > 0 && page <= state.tableState.pages) {
      const offset = page - 1

      props.onTableUpdate({
        offset,
      })
    }
  }

  const onRowCountClick = limit => {
    const offset = Math.floor((props.params.limit * props.params.offset) / limit)

    props.onTableUpdate({
      limit,
      offset,
    })
  }

  const renderColumns = columns => columns.map(column => renderColumn(column))

  const renderColumn = column => {
    let headerRenderer

    if (!column.headerRenderer) {
      let style
      if (state.columnState[column.dataKey]) {
        style = (state.columnState[column.dataKey] === 'DESC')
          ? styles.headerCell__down
          : styles.headerCell__up
      }

      headerRenderer = () => (
        <div className={ style }>
          {column.name}
        </div>
      )
    } else {
      headerRenderer = column.headerRenderer
    }

    return (
      <Column
        key={ column.dataKey }
        className={ cx(styles.column, column.className) }
        headerClassName={ styles.column }
        headerRenderer={ headerRenderer }
        dataKey={ (column.dataKey) ? column.dataKey : 'no_use' }
        width={ column.width || 90 }
        flexGrow={ column.disableGrow ? 0 : 1 }
        flexShrink={ column.disableShrink ? 0 : 1 }
        minWidth={ column.minWidth || 10 }
        maxWidth={ column.maxWidth }
        cellDataGetter={ column.cellDataGetter }
        cellRenderer={ column.cellRenderer }
        label={ column.name }
        disableSort={ !!column.disableSort }
      />
    )
  }

  const {
    className, headerClassName, headerHeight, rowHeight, columns, onRowClick, showPagination,
  } = props

  return (
    <AutoSizer>
      {({ height, width }) => {
        const wrapperStyle = { height, width }
        return (
          <div style={ wrapperStyle }>
            <Table
              className={ cx(styles.table, className) }
              headerClassName={ headerClassName }
              headerHeight={ headerHeight }
              rowHeight={ rowHeight || _cache.rowHeight }
              rowCount={ props.list.length }
              width={ width }
              height={ height - 25 }
              rowGetter={ ({ index }) => props.list[index] }
              onRowClick={ onRowClick }
              onHeaderClick={ onHeaderClick }
            >
              {renderColumns(columns)}

            </Table>
            { showPagination
              ? (
                <div className={ styles.controlBoxStyle }>
                  <div />
                  <Pagination
                    page={ state.tableState.page }
                    pageCount={ state.tableState.pages }
                    onPageClick={ onPageClick }
                  />
                  <RowsCountSelector
                    onRowCountClick={ onRowCountClick }
                    limit={ props.params.limit }
                  />
                </div>
              )
              : null}

          </div>
        )
      }}
    </AutoSizer>
  )
}

VirtualizedTable.propTypes = {
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  headerHeight: PropTypes.number,
  rowHeight: PropTypes.number,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  onRowClick: PropTypes.func,
  list: PropTypes.arrayOf(
    PropTypes.object,
  ),
  count: PropTypes.number,
  onTableUpdate: PropTypes.func.isRequired,
  params: PropTypes.shape({
    limit: PropTypes.number,
    offset: PropTypes.number,
    field: PropTypes.string,
    direct: PropTypes.string,
  }).isRequired,
  showPagination: PropTypes.bool,
}

VirtualizedTable.defaultProps = {
  className: '',
  headerClassName: '',
  count: 0,
  list: [],
  headerHeight: 37,
  rowHeight: 37,
  onRowClick: null,
  showPagination: true,
}

export default VirtualizedTable

const Pagination = ({ page, pageCount, onPageClick }) => {
  if (pageCount < 2) { return null }

  const range = 2

  const renderedButtons = [...Array(range * 2 + 1).keys()]
    .map(i => page - range + i)
    .filter(i => i > 0 && i <= pageCount)

  const showStart = page - range > 1
  const showEnd = page + range < pageCount

  return (
    <div className={ styles.paginatorBox }>
      {showStart && (
        <>
          <button
            type="button"
            className={ (page === 1) ? styles.paginatorBox__selectedButton : styles.paginatorBox__button }
            onClick={ () => onPageClick(1) }
          >1
          </button>
          {page > range + 2 && (
            <div className={ styles.paginatorBox__space }>
              ...
            </div>
          )}
        </>
      )}

      {renderedButtons.map(button => (

        <button
          key={ button }
          onClick={ () => onPageClick(button) }
          className={ (button === page) ? styles.paginatorBox__selectedButton : styles.paginatorBox__button }
          type="button"
        >{button}
        </button>

      ))}

      {showEnd && (
        <>
          {pageCount - page > range + 1 && (
            <div className={ styles.paginatorBox__space }>
              ...
            </div>
          )}
          <button
            className={ (pageCount === page) ? styles.paginatorBox__selectedButton : styles.paginatorBox__button }
            onClick={ () => onPageClick(pageCount) }
            type="button"
          >{pageCount}
          </button>
        </>
      )}
    </div>
  )
}

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  onPageClick: PropTypes.func.isRequired,
}

const RowsCountSelector = ({ onRowCountClick, limit }) => (
  <div className={ styles.paginatorBox }>
    <button
      type="button"
      className={ (limit === 1) ? styles.paginatorBox__selectedButton : styles.paginatorBox__button }
      onClick={ () => onRowCountClick(1) }
    >1
    </button>
    <button
      type="button"
      className={ (limit === 10) ? styles.paginatorBox__selectedButton : styles.paginatorBox__button }
      onClick={ () => onRowCountClick(10) }
    >10
    </button>
    <button
      type="button"
      className={ (limit === 25) ? styles.paginatorBox__selectedButton : styles.paginatorBox__button }
      onClick={ () => onRowCountClick(25) }
    >25
    </button>
    <button
      type="button"
      className={ (limit === 50) ? styles.paginatorBox__selectedButton : styles.paginatorBox__button }
      onClick={ () => onRowCountClick(50) }
    >50
    </button>
    <button
      type="button"
      className={ (limit === 100) ? styles.paginatorBox__selectedButton : styles.paginatorBox__button }
      onClick={ () => onRowCountClick(100) }
    >100
    </button>
  </div>
)

RowsCountSelector.propTypes = {
  onRowCountClick: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
}
