export const isLoading = state => state.codes.loading
export const isLoadingKeys = state => state.codes.loadingKeys

export const list = state => state.codes.list
export const count = state => state.codes.count

export const groupName = state => state.codes.groupName
export const groupID = state => state.codes.groupID
export const groupKeys = state => state.codes.groupKeys
export const groupKeysCount = state => state.codes.groupKeysCount

export const freeKeys = state => state.codes.freeKeys
export const freeKeysCount = state => state.codes.freeKeysCount

export const autocompleteList = state => state.codes.autocompleteList
