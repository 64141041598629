import { APIConfig } from 'configs'

import API from './api'
import Auth from './auth'
import Institution from './institution'
import Feedback from './feedback'
import Teacher from './teacher'
import Student from './students'
import Distributor from './distributor'
import Code from './code'
import Statistic from './statistics'
import Dialog from './dialog'
import News from './news'

const APIService = new API(APIConfig.BASE_URL)

const AuthService = new Auth(APIService)

const InstitutionService = new Institution(APIService)

const FeedbackService = new Feedback(APIService)

const TeacherService = new Teacher(APIService)

const StudentService = new Student(APIService)

const DistributorService = new Distributor(APIService)

const CodeService = new Code(APIService)

const StatisticService = new Statistic(APIService)

const DialogService = new Dialog(APIService)

const NewsService = new News(APIService)

export {
  APIService,
  AuthService,
  InstitutionService,
  FeedbackService,
  TeacherService,
  DialogService,
  StudentService,
  DistributorService,
  CodeService,
  StatisticService,
  NewsService,
}
