import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Actions, Selectors } from 'ducks'
import { FunctionUtils } from 'utils'

import Dropdown from 'rc-dropdown'
import Router from 'components/Router'

// import { ReactComponent as Logo } from 'assets/img/header/cyber-playground.svg'
import { ReactComponent as User } from 'assets/img/header/icon_user.svg'
import { ReactComponent as Bell } from 'assets/img/header/icon_bell.svg'
import { ReactComponent as Letter } from 'assets/img/header/icon_letter.svg'
import { ReactComponent as Arrow } from 'assets/img/arrows.svg'

import logoPath from 'assets/img/header/logo.png'

import cx from 'classnames'
import styles from './styles.module.scss'

import 'rc-dropdown/assets/index.css'

class Header extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
    }
  }

  handleSignOut = () => {
    this.props.signOut()
  }

  handeDropdownActive = visible => this.setState({ visible })

  renderMenu = () => (
    <div className={ styles.dropdownMenu }>
      <Router.Link to="/profile" className={ styles.menuItem }>
        Мой профиль
      </Router.Link>
      <hr className={ styles.hr } />
      <Router.Link to="/settings" className={ styles.menuItem }>
        Настройки
      </Router.Link>
      <hr className={ styles.hr } />
      <button
        type="button"
        className={ cx(styles.menuItem, styles.logout) }
        onClick={ () => this.handleSignOut() }
      >
        Выход
      </button>
    </div>
  )

  renderNav = () => (
    <nav className={ styles.nav }>
      <Letter className={ cx(styles.nav__item, styles.letter) } />
      <Bell className={ cx(styles.nav__item, styles.bell) } />
      <Dropdown
        trigger={ ['click'] }
        overlay={ this.renderMenu }
        onVisibleChange={ this.handeDropdownActive }
      >
        <div className={ styles.nav__item }>
          <User className={ cx(styles.nav__item, styles.user) } />
          <Arrow className={ cx(styles.nav__item, { [styles.arrow__active]: this.state.visible }) } />
        </div>
      </Dropdown>
    </nav>
  )

  render() {
    return (
      <header className={ styles.header }>
        <Router.Link to="/" className={ styles.link }>
          <img
            className={ styles.logo }
            src={ logoPath }
            alt="Cyber Playground"
            title="Cyber Playground"
          />
          <span className={ styles.title }>Cyber Playground</span>
        </Router.Link>
        { this.props.isAuthorized ? this.renderNav() : false }
      </header>
    )
  }
}

Header.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
  signOut: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  isAuthorized: Selectors.Auth.isAuthorized(state),
})

const mapDispatchToProps = dispatch => bindActionCreators({
  signOut: Actions.Auth.signOut,
}, dispatch)

export default FunctionUtils.compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Header)
