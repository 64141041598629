export default class FeedbackService {
  constructor(APIService) {
    this.APIService = APIService
  }

  bid(info) {
    return this.APIService.post('/request/feedback', info)
      .then(response => response.data)
      .catch(e => {
        throw e
      })
  }

  fetchBids(payload) {
    return this.APIService.get('/admin/request/feedbacks', payload)
      .then(response => response.data).catch(e => {
        throw e
      })
  }

  fetchBidsCount() {
    return this.APIService.get('/admin/request/feedbacks')
      .then(response => response.data).catch(e => {
        throw e
      })
  }

  appruveBid(id) {
    return this.APIService.post('/admin/request/appruve-feedback', id)
      .then(response => response.data).catch(e => {
        throw e
      })
  }
}
