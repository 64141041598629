import {
  call,
  put,
  putResolve,
  takeLatest,
} from 'redux-saga/effects'

import {
  CodeService,
  StudentService,
} from 'services'

import {
  fetch,
  fetchByName,
  edit,
  banKey,
  unbanKey,

  __internal,
} from './actions'

function* fetchTask(action) {
  yield put(__internal.fetch.started())

  try {
    const data = yield call([StudentService, 'fetchStudents'], action.payload)

    yield putResolve(__internal.fetch.successed(data))
  } catch (err) {
    yield put(__internal.fetch.failed(err))
  }

  yield put(__internal.fetch.finished())
}

function* fetchByNameTask(action) {
  yield put(__internal.fetchByName.started())

  try {
    const data = yield call([StudentService, 'fetchByName'], action.payload)

    yield putResolve(__internal.fetchByName.successed(data.list))
  } catch (err) {
    yield put(__internal.fetchByName.failed(err))
  }

  yield put(__internal.fetchByName.finished())
}

function* editTask(action) {
  const profile = {
    id: action.payload.id,
    name: action.payload.name,
    bdate: `${action.payload.year}-${action.payload.month[0].value}-${action.payload.day}`,
  }

  yield put(__internal.edit.started())

  try {
    yield call([StudentService, 'profileUpdate'], profile)

    yield putResolve(__internal.edit.successed())
  } catch (err) {
    yield put(__internal.edit.failed(err))
  }

  yield put(__internal.edit.finished())
}

function* banKeyTask(action) {
  yield put(__internal.banKey.started())

  try {
    yield call([CodeService, 'banKey'], action.payload.key, action.payload.comment)

    yield putResolve(__internal.banKey.successed(action.payload.id))
  } catch (err) {
    yield put(__internal.banKey.failed(err))
  }

  yield put(__internal.banKey.finished())
}

function* unbanKeyTask(action) {
  yield put(__internal.unbanKey.started())

  try {
    yield call([CodeService, 'unbanKey'], action.payload.key, action.payload.comment)

    yield putResolve(__internal.unbanKey.successed(action.payload.id))
  } catch (err) {
    yield put(__internal.unbanKey.failed(err))
  }

  yield put(__internal.unbanKey.finished())
}

export default function* () {
  yield takeLatest(fetch, fetchTask)
  yield takeLatest(fetchByName, fetchByNameTask)
  yield takeLatest(edit, editTask)
  yield takeLatest(banKey, banKeyTask)
  yield takeLatest(unbanKey, unbanKeyTask)
}
