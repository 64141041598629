import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import cx from 'classnames'
import { CAPTCHAConfig } from 'configs'

import { Field, reduxForm, SubmissionError } from 'redux-form'

import { Actions, Selectors } from 'ducks'
import { FunctionUtils } from 'utils'

import Router from 'components/Router'
import Button from 'components/Button'
import Spinner from 'components/Spinner'
import ReCAPTCHA from 'react-google-recaptcha'

import styles from './styles.module.scss'

class Feedback extends PureComponent {
  componentDidMount() {
    document.body.classList.add('adaptive')
  }

  componentWillUnmount() {
    document.body.classList.remove('adaptive')
  }


  handleOnClick = () => {
    // eslint-disable-next-line react/prop-types
    this.props.history.goBack()
  }

  requiredInput = input => (!input)

  renderInput = ({ input, label, type, style, meta: { touched, error } }) => ( // eslint-disable-line
    <input
      // eslint-disable-next-line react/jsx-props-no-spreading
      { ...input }
      placeholder={ label }
      type={ type }
      className={ cx(styles.input, style, { [styles.inputError]: touched && error }) }
    />
  )

  renderTextarea = ({ input, label, style, meta: { touched, error } }) => ( // eslint-disable-line
    <textarea
      // eslint-disable-next-line react/jsx-props-no-spreading
      { ...input }
      placeholder={ label }
      className={ cx(styles.input, styles.textarea, style, { [styles.inputError]: touched && error }) }
    />
  )

  renderField = ({ name, label, className, validate, type, component }) => ( // eslint-disable-line
    <Field
      name={ name }
      type={ type }
      label={ label }
      component={ component }
      style={ styles[className] }
      validate={ validate }
    />
  )

  renderText = () => (
    <p className={ styles.textSucceeded }>
      Заявка отправлена, <br />
      в ближайщее время с Вами свяжется наш специалист.
    </p>
  )

  Captcha = props => (
    <div className={ styles.captchaWrapper }>
      <ReCAPTCHA
        sitekey={ CAPTCHAConfig.KEY }
        onChange={ props.input.onChange }
        size="normal"
      />
    </div>
  )

  render() {
    const {
      // eslint-disable-next-line
      error, pristine, submitting, invalid, handleSubmit, submitSucceeded,
    } = this.props

    return (
      <div className={ styles.page }>
        {this.props.isLoading ? <Spinner /> : (
          <div className={ styles.container }>
            <div className={ styles.buttonGroup }>
              <Router.Link to="/feedback">
                <Button
                  className={ styles['button-link'] }
                  size="medium"
                >
                  Форма обратной связи
                </Button>
              </Router.Link>
            </div>
            <div className={ styles.formContainer }>
              <form className={ styles.formWrapper } onSubmit={ handleSubmit }>
                {submitSucceeded
                  ? this.renderText()
                  : this.renderField({
                    name: 'name',
                    label: 'Имя',
                    validate: [this.requiredInput],
                    component: this.renderInput,
                  })}
                {
                  submitSucceeded
                    ? false
                    : this.renderField({
                      name: 'phone',
                      label: 'Телефон',
                      className: 'secondaryInput',
                      validate: [this.requiredInput],
                      component: this.renderInput,
                    })
                }
                {
                  submitSucceeded
                    ? false
                    : this.renderField({
                      name: 'message',
                      label: 'Опишите Ваш вопрос',
                      className: 'secondaryInput',
                      validate: [this.requiredInput],
                      component: this.renderTextarea,
                    })
                }
                {
                  submitSucceeded
                    ? false
                    : (
                      <Field name="captcha" validate={ [this.requiredInput] } component={ this.Captcha } />
                    )
                }
                {(error === undefined)
                  ? (!pristine && invalid && <div className={ styles.error }>Заполнены не все поля</div>)
                  : (error && <div className={ styles.error }>{error}</div>)}
                <Button
                  className={ styles['button-submit'] }
                  type={ submitSucceeded ? 'button' : 'submit' }
                  size="large"
                  filled
                  disabled={ submitting || pristine || invalid }
                  onClick={ submitSucceeded ? this.handleOnClick : undefined }
                >
                  {submitSucceeded ? 'ОК' : 'Отправить'}
                </Button>
              </form>
              {submitSucceeded ? false : (
                <span className={ styles.license }>
                  Нажимая кнопку, принимаю условия&nbsp;
                  <Router.Link to="/" className={ styles.linkLicense }>политики</Router.Link><br />
                  и  <Router.Link to="/" className={ styles.linkLicense }>пользовательского соглашения</Router.Link>
                </span>
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
}

Feedback.propTypes = {
  push: PropTypes.func.isRequired,
  feedback: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  isLoading: Selectors.RequestForm.isLoading(state),
})

const mapDispatchToProps = dispatch => bindActionCreators({
  push: Actions.Router.push,
  feedback: Actions.RequestForm.feedback,
}, dispatch)

export default FunctionUtils.compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'FeedbackForm',
    onSubmit: (values, dispatch, props) => props.feedback(values)
      .catch(e => {
        throw new SubmissionError({
          _error: 'Что-то пошло не так',
        })
      }),
  }),
)(Feedback)
