import React, { useRef, useEffect, useState } from 'react'
import moment from 'moment'
import { get } from 'lodash'
import { APIConfig } from 'configs'
import PropTypes from 'prop-types'
import Scrollable from 'components/Scrollable'
import Spinner from 'components/Spinner'

import Textarea from 'react-textarea-autosize'
import Svg from 'components/Svg'

import {
  useSelector,
  useDispatch,
} from 'react-redux'

import {
  Actions,
  Selectors,
} from 'ducks'

import styles from './styles.module.scss'

const MapMessages = ({ list, photoUrl }) => list.map(item => {
  const inputTime = moment.utc(item.updatedAt).local()
  let showTime

  if (moment(inputTime).diff(moment(), 'days') !== 0) {
    showTime = inputTime.format('DD.MM.YY HH:mm')
  } else {
    showTime = inputTime.format('HH:mm')
  }

  return (item.owner !== 'user')
    ? (
      <OutgoingMessage
        key={ item.id }
        message={ item.text }
        time={ showTime }
      />
    )
    : (
      <IncomingMessage
        key={ item.id }
        message={ item.text }
        time={ showTime }
        avatar={ photoUrl }
      />
    )
})


const OutgoingMessage = props => (
  <div className={ styles.outgoingMessage }>
    <div className={ styles.outgoingMessage__message }>{props.message}</div>
    <div className={ styles.outgoingMessage__date }>{props.time}</div>
  </div>
)

OutgoingMessage.propTypes = {
  message: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
}

const IncomingMessage = props => (
  <div className={ styles.incomingMessage }>
    <div className={ styles.avatar }>
      {(props.avatar)
        ? <img className={ styles.avatar } src={ props.avatar } alt="" />
        : <Svg className={ styles.avatar } name="icon_no_avatar" />}
    </div>
    <div className={ styles.incomingMessage__message }>{props.message}</div>
    <div className={ styles.incomingMessage__date }>{props.time}</div>
  </div>
)

IncomingMessage.propTypes = {
  avatar: PropTypes.string,
  message: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
}

IncomingMessage.defaultProps = {
  avatar: null,
}

const Dialog = props => {
  const [fetchParams, setFetchParams] = useState({
    limit: 20,
    id: undefined,
    ChatId: props.id,
  })
  const [lock, setLock] = useState(false)
  const [isOnline, setIsOnline] = useState(props.isOnline)
  const [scrollHeight, setScrollHeight] = useState(0)

  const photo = (props.listItem && props.listItem.User && props.listItem.User.photo)
    ? `${APIConfig.ORIGIN}${props.listItem.User.photo}`
    : undefined
  const fullName = (props.listItem && props.listItem.User && props.listItem.User.name && props.listItem.User.name.full)
  const age = props.listItem && props.listItem.User && props.listItem.User.age

  const dispatch = useDispatch()

  const leave = useSelector(Selectors.Socket.userLeave)
  const join = useSelector(Selectors.Socket.userJoin)
  const isLoadingDialogs = useSelector(Selectors.Dialog.isLoadingChat)
  const isEnd = useSelector(Selectors.Dialog.isEndChat)
  const list = useSelector(Selectors.Dialog.chatList)

  const scrollRef = useRef()

  useEffect(() => {
    dispatch(Actions.Dialog.dialogChatInit())
    dispatch(Actions.Socket.joinRoom({ id: props.userId }))

    return () => {
      dispatch(Actions.Socket.leaveRoom())
    }
  }, []) //eslint-disable-line

  useEffect(() => {
    if (!isLoadingDialogs) {
      dispatch(Actions.Dialog.fetchChatList(fetchParams))

      if (scrollRef && scrollRef.current
        && typeof scrollRef.current.getScrollHeight === 'function') {
        setScrollHeight(scrollRef.current.getScrollHeight())
      }
    }
  }, [fetchParams]) //eslint-disable-line

  useEffect(() => {
    if (join && join.id === props.userId) {
      setIsOnline(true)
    }
  }, [join, props.userId])

  useEffect(() => {
    if (leave && leave.id === props.userId) {
      setIsOnline(false)
    }
  }, [leave, props.userId])

  useEffect(() => {
    if (!list.length) {
      setLock(false)
      return
    }

    if (!lock
      && scrollRef && scrollRef.current && typeof scrollRef.current.scrollToBottom === 'function') {
      scrollRef.current.scrollToBottom()
      setLock(true)
    }
  }, [list]) //eslint-disable-line

  useEffect(() => {
    if (lock
      && scrollRef && scrollRef.current
      && typeof scrollRef.current.getScrollHeight === 'function'
      && typeof scrollRef.current.scrollTop === 'function'
    ) {
      scrollRef.current.scrollTop(scrollRef.current.getScrollHeight() - scrollHeight)
    }
  }, [list]) //eslint-disable-line

  const onScroll = () => {
    if (!isEnd && !isLoadingDialogs && !!list.length
      && scrollRef && scrollRef.current
      && typeof scrollRef.current.getScrollTop === 'function'
      && scrollRef.current.getScrollTop() === 0) {
      setFetchParams(prevState => ({
        ...prevState,
        id: list[list.length - 1].id,
      }))
    }
  }

  const TextareaKeyPress = evt => {
    if (evt.key !== 'Enter' || (evt.key === 'Enter' && evt.shiftKey)) {
      return
    }

    evt.preventDefault()

    if (evt.target && evt.target.value !== '') {
      dispatch(Actions.Dialog.sendMessage({ message: evt.target.value }))
      evt.target.value = null
    }
  }

  return (
    <div className={ styles.container }>
      <div className={ styles.controlButtons }>
        <button
          onClick={ props.unmount }
          className={ styles.controlButtons__button }
          type="button"
        >
          <Svg
            name="close_dialog"
            width="16px"
            height="16px"
          />
          Завершить диалог
        </button>
      </div>
      <div className={ styles.userContainer }>
        <div className={ styles.avatar }>
          {(photo)
            ? <img className={ styles.avatar } src={ photo } alt="" />
            : <Svg className={ styles.avatar } name="icon_no_avatar" />}
          {(isOnline)
            ? <div className={ styles.avatar__online } />
            : null}
        </div>
        <div className={ styles.details__box }>
          <div className={ styles.details__box__name }>
            {fullName}
            {(age) ? `, ${age} лет` : null}
          </div>
          <div className={ styles.details__box__school }>
            {get(props, 'listItem.User.Code.GCode.School.name', null)}
          </div>
        </div>
        <div className={ styles.dropdown } />
      </div>
      <div className={ styles.messages }>
        <Scrollable
          ref={ scrollRef }
          onScroll={ onScroll }
        >
          <div className={ styles.messagesWrapper }>
            <MapMessages
              list={ list }
              photoUrl={ photo }
            />
            {(isLoadingDialogs)
              ? (
                <div className={ styles.spinner }>
                  <Spinner />
                </div>
              )
              : null}
          </div>
        </Scrollable>
      </div>
      <div className={ styles.inputBox }>
        {/* <div className={ styles.inputBox__add }>
          <Svg
            name="icon_add"
            width="20px"
            height="20px"
          />
        </div> */}
        <form
          id="message"
          className={ styles.input }
        >
          <Textarea
            onKeyPress={ TextareaKeyPress }
            placeholder="Введите сообщение"
            required
            form="message"
          />
          <div className={ styles.input__smiles }>
            <Svg
              name="icon_smile"
              width="20px"
              height="20px"
            />
          </div>
        </form>
        {/* <div className={ styles.voice }>
          <Svg
            name="icon_mic"
            width="20px"
            height="20px"
          />
        </div> */}
      </div>
    </div>
  )
}

Dialog.propTypes = {
  unmount: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  isOnline: PropTypes.bool.isRequired,
  listItem: PropTypes.shape({
    user_status: PropTypes.bool.isRequired,
    User: PropTypes.shape({
      name: PropTypes.shape({
        full: PropTypes.string,
      }),
      age: PropTypes.number,
      photo: PropTypes.string,
      Code: PropTypes.shape({
        GCode: PropTypes.shape({
          School: PropTypes.shape({
            name: PropTypes.string,
          }),
        }),
      }),
    }),
  }).isRequired,
}

export default Dialog
