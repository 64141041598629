export const isLoadingDialog = state => state.dialog.loadingDialog
export const isEnd = state => state.dialog.isEnd
export const list = state => state.dialog.list
export const token = state => state.auth.accessToken
export const isLoadingChat = state => state.dialog.loadingChat
export const isEndChat = state => state.dialog.isEndChat
export const chatList = state => state.dialog.chatList
export const currentDialog = state => state.dialog.currentDialog
export const listTask = state => state.dialog.listTask
export const teacherChat = state => state.dialog.teacherChat
