import { createAction } from 'redux-actions'

import { ReduxUtils } from 'utils'

const fetchGroupsActions = ReduxUtils.generateActionCreators('codes', 'fetch_groups', 'request')
const fetchGroupsByNameActions = ReduxUtils.generateActionCreators('codes', 'fetch_groups_by_name', 'request')
const createGroupActions = ReduxUtils.generateActionCreators('codes', 'create_group', 'request')
const editGroupActions = ReduxUtils.generateActionCreators('codes', 'edit_group', 'request')
const deleteGroupActions = ReduxUtils.generateActionCreators('codes', 'delete_group', 'request')

const fetchKeysActions = ReduxUtils.generateActionCreators('codes', 'fetch_keys', 'request')
const fetchFreeKeysActions = ReduxUtils.generateActionCreators('codes', 'fetch_free_keys', 'request')

const banKeyActions = ReduxUtils.generateActionCreators('codes', 'ban_key', 'request')
const createKeysActions = ReduxUtils.generateActionCreators('codes', 'create_keys', 'request')
const unbanKeyActions = ReduxUtils.generateActionCreators('codes', 'unban_key', 'request')
const addUserActions = ReduxUtils.generateActionCreators('codes', 'add_user', 'request')
const addKeyToGroupActions = ReduxUtils.generateActionCreators('codes', 'add_key_to_group', 'request')
const fetchNameOfGroupActions = ReduxUtils.generateActionCreators('codes', 'fetch_group_name', 'request')

const fetchGroupName = ReduxUtils.waitForAction(
  createAction('CODES/FETCH_GROUP_NAME'),
  fetchGroupsActions.successed,
  fetchGroupsActions.failed,
)

const fetchGroups = ReduxUtils.waitForAction(
  createAction('CODES/FETCH_GROUPS'),
  fetchGroupsActions.successed,
  fetchGroupsActions.failed,
)

const fetchGroupsByName = ReduxUtils.waitForAction(
  createAction('CODES/FETCH_GROUPS_BY_NAME'),
  fetchGroupsByNameActions.successed,
  fetchGroupsByNameActions.failed,
)

const fetchKeys = ReduxUtils.waitForAction(
  createAction('CODES/FETCH_KEYS'),
  fetchKeysActions.successed,
  fetchKeysActions.failed,
)

const fetchFreeKeys = ReduxUtils.waitForAction(
  createAction('CODES/FETCH_FREE_KEYS'),
  fetchFreeKeysActions.successed,
  fetchFreeKeysActions.failed,
)

const createGroup = ReduxUtils.waitForAction(
  createAction('CODES/CREATE_GROUP'),
  createGroupActions.successed,
  createGroupActions.failed,
)

const editGroup = ReduxUtils.waitForAction(
  createAction('CODES/EDIT_GROUP'),
  editGroupActions.successed,
  editGroupActions.failed,
)

const deleteGroup = ReduxUtils.waitForAction(
  createAction('CODES/DELETE_GROUP'),
  deleteGroupActions.successed,
  deleteGroupActions.failed,
)

const banKeys = ReduxUtils.waitForAction(
  createAction('CODES/BAN_KEY'),
  banKeyActions.successed,
  banKeyActions.failed,
)

const createKeys = ReduxUtils.waitForAction(
  createAction('CODES/CREATE_KEYS'),
  createKeysActions.successed,
  createKeysActions.failed,
)

const unbanKeys = ReduxUtils.waitForAction(
  createAction('CODES/UNBAN_KEY'),
  unbanKeyActions.successed,
  unbanKeyActions.failed,
)

const addUser = ReduxUtils.waitForAction(
  createAction('CODES/ADD_USER'),
  addUserActions.successed,
  addUserActions.failed,
)

const addKeyToGroup = ReduxUtils.waitForAction(
  createAction('CODES/ADD_KEY_TO_GROUP'),
  addKeyToGroupActions.successed,
  addKeyToGroupActions.failed,
)

const __internal = {
  fetchGroups: fetchGroupsActions,
  fetchGroupName: fetchNameOfGroupActions,
  fetchGroupsByName: fetchGroupsByNameActions,
  createGroup: createGroupActions,
  editGroup: editGroupActions,
  deleteGroup: deleteGroupActions,
  fetchKeys: fetchKeysActions,
  fetchFreeKeys: fetchFreeKeysActions,
  banKey: banKeyActions,
  unbanKey: unbanKeyActions,
  addUser: addUserActions,
  addKeyToGroup: addKeyToGroupActions,
  createKeys: createKeysActions,
}

export {
  fetchGroupName,
  fetchGroups,
  fetchGroupsByName,
  createGroup,
  editGroup,
  deleteGroup,
  fetchKeys,
  fetchFreeKeys,
  banKeys,
  unbanKeys,
  addUser,
  addKeyToGroup,
  createKeys,

  __internal,
}
