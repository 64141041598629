import React, { useState, useEffect, createRef } from 'react'
import {
  useSelector,
  useDispatch,
} from 'react-redux'

import MessagePreview from 'components/MessagePreview'
import Scrollable from 'components/Scrollable'
import DialogComponent from 'components/Dialog'
import Spinner from 'components/Spinner'

import {
  Actions,
  Selectors,
} from 'ducks'

import styles from './styles.module.scss'

const Dialog = () => {
  const dispatch = useDispatch()
  const isLoadingDialogs = useSelector(Selectors.Dialog.isLoadingDialog)
  const isEnd = useSelector(Selectors.Dialog.isEnd)
  const list = useSelector(Selectors.Dialog.list)
  const listTask = useSelector(Selectors.Dialog.listTask)

  const [fetchParams, setFetchParams] = useState({
    limit: 20,
    id: undefined,
  })
  const [dialog, setDialog] = useState({
    show: false,
    id: undefined,
    userId: undefined,
  })
  const leave = useSelector(Selectors.Socket.userLeave)
  const join = useSelector(Selectors.Socket.userJoin)
  const [isOnline, setIsOnline] = useState({})

  useEffect(() => {
    dispatch(Actions.Dialog.dialogInit())
    dispatch(Actions.Dialog.currentDialog({ id: undefined, userId: undefined }))

    return () => {
      dispatch(Actions.Dialog.currentDialog({ id: undefined, userId: undefined }))
    }
  }, [dispatch])

  useEffect(() => {
    if (!isLoadingDialogs) {
      dispatch(Actions.Dialog.fetchDialogList(fetchParams))
    }
  }, [dispatch, fetchParams]) //eslint-disable-line

  useEffect(() => {
    if (join) {
      setIsOnline(prevState => ({
        ...prevState,
        [join.id]: true,
      }))
    }
  }, [join])

  useEffect(() => {
    if (leave) {
      setIsOnline(prevState => ({
        ...prevState,
        [leave.id]: false,
      }))
    }
  }, [leave])

  useEffect(() => {
    setIsOnline(list.reduce((acc, item) => {
      acc[item.UserId] = item.user_status
      return { ...acc }
    }, {}))
  }, [list])

  const scrollRef = createRef()

  const onScroll = () => {
    if (!isEnd && scrollRef.current.getScrollHeight()
      === scrollRef.current.getScrollTop() + scrollRef.current.getClientHeight()) {
      setFetchParams(prevState => ({
        ...prevState,
        id: list[list.length - 1].id,
      }))
    }
  }

  const handleClick = (id, userId, evt) => {
    if (evt.type === 'keypress' && evt.key !== 'Enter') {
      return
    }

    setDialog({
      show: true,
      id,
      userId,
    })

    dispatch(Actions.Dialog.currentDialog({ id, userId }))
  }

  const unmount = () => {
    dispatch(Actions.Dialog.currentDialog({ id: undefined, userId: undefined }))

    setDialog({
      show: false,
      id: undefined,
      userId: undefined,
    })
  }

  const listItem = list.find(item => item.id === dialog.id)

  return (
    <div className={ styles.page }>
      <div className={ styles.dialog }>

        <Scrollable
          onScroll={ onScroll }
          ref={ scrollRef }
        >
          <MapMessagePreviws
            list={ list }
            listOnline={ isOnline }
            handleClick={ handleClick }
            selected={ dialog.id }
          />
          {(isLoadingDialogs)
            ? (
              <div style={ { position: 'absolute', left: '42%' } }>
                <Spinner />
              </div>
            )
            : null}
        </Scrollable>

      </div>
      <div className={ styles.current }>
        {(dialog.show) ? (
          <DialogComponent
            key={ dialog.id }
            id={ dialog.id }
            userId={ dialog.userId }
            listItem={ listItem }
            isOnline={ isOnline[listItem.UserId] }
            unmount={ unmount }
          />
        ) : null}
      </div>
      <div className={ styles.ask }>
        {(listTask[dialog.id])
          ? (
            <div
              className={ styles.ask__wrapper }
              dangerouslySetInnerHTML={{ __html: listTask[dialog.id] }} //eslint-disable-line
            />
          )
          : <p className={ styles.text }>Вопрос</p>}
      </div>
    </div>
  )
}

const MapMessagePreviws = ({ list, handleClick, listOnline, selected }) => { //eslint-disable-line
  const teacherChat = useSelector(Selectors.Dialog.teacherChat)
  const currentTeacherId = useSelector(Selectors.Auth.id)
  const values = Object.values(teacherChat)

  return list
    .filter(item => (!values.includes(item.id) || teacherChat[currentTeacherId] === item.id))
    .map(item => (
      <MessagePreview
        handleClick={ handleClick }
        id={ item.id }
        userId={ (item.User && item.User.id) }
        selected={ (selected === item.id) }
        age={ (item.User && item.User.age) }
        isOnline={ listOnline[item.UserId] }
        name={ (item.User && item.User.name && item.User.name.full) }
        key={ item.id }
        date={ (item.CMessages[0] && item.CMessages[0].createdAt) }
        message={ (item.CMessages[0] && item.CMessages[0].text) }
      />
    ))
}

export default Dialog
