import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'

import cx from 'classnames'
import styles from './styles.module.scss'

ReactModal.setAppElement('#root')

export default class Modal extends PureComponent {
  render() {
    const {
      children, className, overlayClassName, isOpen, onRequestClose,
    } = this.props

    return (
      <ReactModal
        className={ cx(styles.modal, className) }
        overlayClassName={ cx(styles.overlay, overlayClassName) }
        isOpen={ isOpen }
        onRequestClose={ onRequestClose }
        shouldCloseOnOverlayClick
      >
        { children }
      </ReactModal>
    )
  }
}

Modal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
}

Modal.defaultProps = {
  children: null,
  className: '',
  overlayClassName: '',
}
