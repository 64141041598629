import {
  call,
  put,
  putResolve,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects'

import {
  DialogService,
} from 'services'

import {
  fetchDialogList,
  fetchChatList,
  fetchChatNewMessage,
  sendMessage,
  fetchNewTask,

  __internal,
} from './actions'

function* fetchDialogTask(action) {
  yield put(__internal.fetchDialogList.started())

  try {
    const data = yield call([DialogService, 'fetchDialogs'], action.payload)

    yield putResolve(__internal.fetchDialogList.successed({ data, params: action.payload }))
  } catch (err) {
    yield put(__internal.fetchDialogList.failed(err))
  }

  yield put(__internal.fetchDialogList.finished())
}

function* fetchChatTask(action) {
  yield put(__internal.fetchChatList.started())

  try {
    const data = yield call([DialogService, 'fetchChat'], action.payload)

    yield putResolve(__internal.fetchChatList.successed({ data, params: action.payload }))
  } catch (err) {
    yield put(__internal.fetchChatList.failed(err))
  }

  yield put(__internal.fetchChatList.finished())
}

function* fetchChatNewMessageTask(action) {
  yield put(__internal.fetchChatNewMessage.started())

  try {
    const data = yield call([DialogService, 'fetchChatNewMessage'], action.payload)

    yield putResolve(__internal.fetchChatNewMessage.successed({ data }))
  } catch (err) {
    yield put(__internal.fetchChatNewMessage.failed(err))
  }

  yield put(__internal.fetchChatNewMessage.finished())
}

function* sendMessageTask(action) {
  yield put(__internal.sendMessage.started())

  try {
    const data = yield call([DialogService, 'sendMessage'], action.payload)

    yield putResolve(__internal.sendMessage.successed({ data }))
  } catch (err) {
    yield put(__internal.sendMessage.failed(err))
  }

  yield put(__internal.sendMessage.finished())
}

function* fetchNewTaskTask(action) {
  yield put(__internal.fetchNewTask.started())

  try {
    const data = yield call([DialogService, 'fetchNewTask'], action.payload)

    yield putResolve(__internal.fetchNewTask.successed(data))
  } catch (err) {
    yield put(__internal.fetchNewTask.failed(err))
  }

  yield put(__internal.fetchNewTask.finished())
}

export default function* () {
  yield takeLatest(fetchDialogList, fetchDialogTask)
  yield takeLatest(fetchChatList, fetchChatTask)
  yield takeEvery(fetchChatNewMessage, fetchChatNewMessageTask)
  yield takeEvery(sendMessage, sendMessageTask)
  yield takeEvery(fetchNewTask, fetchNewTaskTask)
}
