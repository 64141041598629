import {
  call,
  put,
  select,
  putResolve,
  takeLatest,
} from 'redux-saga/effects'

import {
  NewsService,
} from 'services'

import draftToHtml from 'draftjs-to-html'

import { photo as newsPhotoSelector } from './selectors'
import {
  fetch,
  fetchOne,
  add,
  change,
  del,

  __internal,
} from './actions'


function* fetchTask(action) {
  yield put(__internal.fetch.started())

  try {
    const data = yield call([NewsService, 'fetchNews'], action.payload)

    yield putResolve(__internal.fetch.successed(data))
  } catch (err) {
    yield put(__internal.fetch.failed(err))
  }

  yield put(__internal.fetch.finished())
}

function* fetchOneTask(action) {
  yield put(__internal.fetchOne.started())

  try {
    const data = yield call([NewsService, 'fetchOneNews'], action.payload)

    yield putResolve(__internal.fetchOne.successed(data))
  } catch (err) {
    yield put(__internal.fetchOne.failed(err))
  }

  yield put(__internal.fetchOne.finished())
}

function* addTask(action) {
  yield put(__internal.add.started())

  const { photo } = action.payload
  try {
    let photoResponse
    if (photo) {
      photoResponse = yield call([NewsService, 'uploadPhoto'], photo)
    }

    yield call([NewsService, 'addNews'], {
      ...action.payload,
      ...photoResponse,
      text: draftToHtml(action.payload.text),
    })

    yield putResolve(__internal.add.successed())
  } catch (err) {
    yield put(__internal.add.failed(err))
  }

  yield put(__internal.add.finished())
}

function* changeTask(action) {
  yield put(__internal.change.started())

  const { photo } = action.payload

  try {
    let photoResponse = {
      photo: undefined,
    }

    if (photo) {
      const photoStore = yield select(newsPhotoSelector)

      if (photoStore !== photo) {
        photoResponse = yield call([NewsService, 'uploadPhoto'], photo)
      } else {
        photoResponse = { photo }
      }
    }

    let text

    if (typeof action.payload.text !== 'string') {
      text = draftToHtml(action.payload.text)
    } else {
      text = action.payload.text
    }

    yield call([NewsService, 'addNews'], {
      ...action.payload,
      ...photoResponse,
      text,
    })

    yield putResolve(__internal.change.successed())
  } catch (err) {
    yield put(__internal.change.failed(err))
  }

  yield put(__internal.change.finished())
}

function* delTask(action) {
  const id = action.payload

  yield put(__internal.del.started())

  try {
    const data = yield call([NewsService, 'deleteNews'], { id: [id] })

    yield putResolve(__internal.del.successed(data))
  } catch (err) {
    yield put(__internal.del.failed(err))
  }

  yield put(__internal.del.finished())
}

export default function* () {
  yield takeLatest(fetch, fetchTask)
  yield takeLatest(fetchOne, fetchOneTask)
  yield takeLatest(add, addTask)
  yield takeLatest(change, changeTask)
  yield takeLatest(del, delTask)
}
