import {
  call,
  put,
  putResolve,
  takeLatest,
} from 'redux-saga/effects'

import {
  InstitutionService,
} from 'services'

import {
  fetch,
  fetchBidsCount,

  fetchById,
  fetchByName,
  bids,
  appruveBid,
  create,
  edit,
  del,

  __internal,
} from './actions'

function* fetchTask(action) {
  yield put(__internal.fetch.started())

  try {
    const data = yield call([InstitutionService, 'fetchInstitutions'], action.payload)

    yield putResolve(__internal.fetch.successed(data))
  } catch (err) {
    yield put(__internal.fetch.failed(err))
  }

  yield put(__internal.fetch.finished())
}

function* fetchBidsCountTask() {
  yield put(__internal.fetchBidsCount.started())

  try {
    const data = yield call([InstitutionService, 'fetchBidsCount'])

    yield putResolve(__internal.fetchBidsCount.successed(data))
  } catch (err) {
    yield put(__internal.fetchBidsCount.failed(err))
  }

  yield put(__internal.fetchBidsCount.finished())
}

function* fetchByIdTask(action) {
  yield put(__internal.fetchById.started())

  try {
    const data = yield call([InstitutionService, 'fetchById'], action.payload)

    yield putResolve(__internal.fetchById.successed(data.list[0]))
  } catch (err) {
    yield put(__internal.fetchById.failed(err))
  }

  yield put(__internal.fetchById.finished())
}

function* fetchByNameTask(action) {
  yield put(__internal.fetchByName.started())

  try {
    const data = yield call([InstitutionService, 'fetchByName'], action.payload)

    yield putResolve(__internal.fetchByName.successed(data.list))
  } catch (err) {
    yield put(__internal.fetchByName.failed(err))
  }

  yield put(__internal.fetchByName.finished())
}

function* bidsTask(action) {
  yield put(__internal.bids.started())

  try {
    const data = yield call([InstitutionService, 'fetchBids'], action.payload)

    yield putResolve(__internal.bids.successed(data))
  } catch (err) {
    yield put(__internal.bids.failed(err))
  }

  yield put(__internal.bids.finished())
}

function* appruveBidTask(action) {
  yield put(__internal.appruveBid.started())

  try {
    yield call([InstitutionService, 'appruveBid'], { id: action.payload })

    yield putResolve(__internal.appruveBid.successed())
  } catch (err) {
    yield put(__internal.appruveBid.failed(err))
  }

  yield put(__internal.appruveBid.finished())
}

function* deleteTask(action) {
  const id = action.payload

  yield put(__internal.del.started())

  try {
    yield call([InstitutionService, 'delete'], { id: [id] })

    yield putResolve(__internal.del.successed(id))
  } catch (err) {
    yield put(__internal.del.failed(err))
  }

  yield put(__internal.del.finished())
}

function* createTask(action) {
  const school = {
    name: action.payload.name,
    email: action.payload.email,
    address: action.payload.address,
    users: action.payload.users,
    contact_name: action.payload.contact_name,
    contacts: action.payload.contacts,
    url: action.payload.url,
    phone: action.payload.phone,
  }
  const geo = {
    Country: {
      name: action.payload.country.label,
      geonames_id: action.payload.country.value,
    },
    City: {
      name: action.payload.city.label,
      geonames_id: action.payload.city.value,
      lon: action.payload.city.lon,
      lat: action.payload.city.lat,
    },
  }

  yield put(__internal.create.started())

  try {
    const id = yield call([InstitutionService, 'create'], { school, geo })

    yield putResolve(__internal.create.successed({ school: { ...school, id: id.success }, geo }))
  } catch (err) {
    yield put(__internal.create.failed(err))
  }

  yield put(__internal.create.finished())
}

function* editTask(action) {
  const school = {
    id: action.payload.id,
    name: action.payload.name,
    email: action.payload.email,
    address: action.payload.address,
    users: +action.payload.users,
    contact_name: action.payload.contact_name,
    contacts: action.payload.contacts,
    url: action.payload.url,
    phone: action.payload.phone,
  }
  const geo = {
    Country: {
      name: action.payload.Country.label,
      geonames_id: action.payload.Country.value,
    },
    City: {
      name: action.payload.City.label,
      geonames_id: action.payload.City.value,
      lon: action.payload.City.lon,
      lat: action.payload.City.lat,
    },
  }

  yield put(__internal.edit.started())

  try {
    yield call([InstitutionService, 'create'], { school, geo })

    yield putResolve(__internal.edit.successed({ ...school, ...geo }))
  } catch (err) {
    yield put(__internal.edit.failed(err))
  }

  yield put(__internal.edit.finished())
}

export default function* () {
  yield takeLatest(fetch, fetchTask)
  yield takeLatest(fetchBidsCount, fetchBidsCountTask)
  yield takeLatest(fetchById, fetchByIdTask)
  yield takeLatest(fetchByName, fetchByNameTask)
  yield takeLatest(bids, bidsTask)
  yield takeLatest(appruveBid, appruveBidTask)
  yield takeLatest(create, createTask)
  yield takeLatest(edit, editTask)
  yield takeLatest(del, deleteTask)
}
