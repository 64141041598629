import validator from 'validate.js'

validator.validators.checked = value => (value ? null : 'must be checked')

validator.validators.array = (value, options) => {
  if (value === undefined) {
    return null
  }

  const validations = value.map(v => validator.single(v, options))

  if (validations.filter(i => i !== undefined).length === 0) {
    return null
  }

  return validations
}

validator.validators.dateValidator = date => (date && date.isValid() ? null : 'not valid')

export const validate = (values, format) => validator(values, format, {
  fullMessages: false,
}) || {}

export const validateAsync = (values, format) => validator.async(values, format, {
  fullMessages: false,
}).then(() => ({}))
