import { createAction } from 'redux-actions'

import { ReduxUtils } from 'utils'

const signInActions = ReduxUtils.generateActionCreators('auth', 'signIn', 'request')
const signUpActions = ReduxUtils.generateActionCreators('auth', 'signUp', 'request')
const signOutActions = ReduxUtils.generateActionCreators('auth', 'signOut', 'request')
const forgotPassActions = ReduxUtils.generateActionCreators('auth', 'forgotPass', 'request')
const changePassActions = ReduxUtils.generateActionCreators('auth', 'changePass', 'request')
const validateTokenActions = ReduxUtils.generateActionCreators('auth', 'validateToken', 'request')

const signIn = ReduxUtils.waitForAction(
  createAction('AUTH/SIGN_IN'),
  signInActions.successed,
  signInActions.failed,
)

const signUp = ReduxUtils.waitForAction(
  createAction('AUTH/SIGN_Up'),
  signUpActions.successed,
  signUpActions.failed,
)

const signOut = ReduxUtils.waitForAction(
  createAction('AUTH/SIGN_OUT'),
  signOutActions.successed,
  signOutActions.failed,
)

const forgotPass = ReduxUtils.waitForAction(
  createAction('AUTH/FORGOT_PASS'),
  forgotPassActions.successed,
  forgotPassActions.failed,
)

const changePass = ReduxUtils.waitForAction(
  createAction('AUTH/CHANGE_PASS'),
  changePassActions.successed,
  changePassActions.failed,
)

const validateToken = ReduxUtils.waitForAction(
  createAction('AUTH/VALIDATE_TOKEN'),
  validateTokenActions.successed,
  validateTokenActions.failed,
)

const __internal = {
  signIn: signInActions,
  signUp: signUpActions,
  signOut: signOutActions,
  forgotPass: forgotPassActions,
  changePass: changePassActions,
  validateToken: validateTokenActions,
}

export {
  signIn,
  signUp,
  signOut,
  forgotPass,
  changePass,
  validateToken,

  __internal,
}
