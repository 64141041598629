import Geonames from 'geonames.js'

const geonames = new Geonames({
  username: 'm.kravets',
  lan: 'en',
  encoding: 'JSON',
})

export const getCountries = async () => {
  const countries = await geonames.countryInfo({})
  const countriesModified = countries.geonames.map(item => {
    const newData = {
      value: item.geonameId,
      label: item.countryName,
      countryCode: item.countryCode,
    }
    return newData
  })
  return countriesModified
}

export const getCities = async (countryCode, name) => {
  const cities = await geonames.search({
    country: countryCode,
    style: 'SHORT',
    featureClass: 'P',
    name: name.value,
  })

  const citiesModified = cities.geonames.map(item => {
    const newData = {
      value: item.geonameId,
      label: item.toponymName,
      lon: item.lng,
      lat: item.lat,
    }
    return newData
  })

  return citiesModified
}
