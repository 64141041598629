import { handleActions } from 'redux-actions'

import produce from 'immer'

import { AppConstants } from 'consts'

import {
  setLocale,

  __internal,
} from './actions'

const initialState = {
  isInitialized: false,
  locale: AppConstants.DEFAULT_LOCALE,
}

export default handleActions({
  [__internal.init.successed]: state => produce(state, next => {
    next.isInitialized = true
  }),
  [setLocale]: (state, action) => produce(state, next => {
    next.locale = action.payload
  }),
}, initialState)
