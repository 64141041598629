import React from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as IconDialog } from 'assets/img/sidebar/icon_dialog.svg'
import { ReactComponent as IconArchive } from 'assets/img/sidebar/icon_archive.svg'

import { ReactComponent as IconAnalytics } from 'assets/img/sidebar/icon_analytics.svg'
import { ReactComponent as IconUsers } from 'assets/img/sidebar/icon_users.svg'
import { ReactComponent as IconInstitution } from 'assets/img/sidebar/icon_institution.svg'
import { ReactComponent as IconExperts } from 'assets/img/sidebar/icon_experts.svg'
import { ReactComponent as IconKeys } from 'assets/img/sidebar/icon_keys.svg'
import { ReactComponent as IconDistributors } from 'assets/img/sidebar/icon_distributors.svg'
import { ReactComponent as IconBid } from 'assets/img/sidebar/icon_bid.svg'
import { ReactComponent as IconNews } from 'assets/img/sidebar/icon_news.svg'

import { ReactComponent as IconDelete } from 'assets/img/table/icon_trash.svg'
import { ReactComponent as IconOther } from 'assets/img/table/icon_ellipsis.svg'
import { ReactComponent as IconEdit } from 'assets/img/table/icon_pencil.svg'
import { ReactComponent as IconAdd } from 'assets/img/table/icon_plus.svg'
import { ReactComponent as IconBlock } from 'assets/img/table/icon_block.svg'
import { ReactComponent as IconQuestion } from 'assets/img/table/icon_question.svg'
import { ReactComponent as IconFilter } from 'assets/img/table/icon_filter.svg'

import { ReactComponent as IconFb } from 'assets/img/icon_fb_logo.svg'
import { ReactComponent as IconVk } from 'assets/img/icon_vk_logo.svg'

import { ReactComponent as IconSmile } from 'assets/img/dialog/smile.svg'
import { ReactComponent as IconMic } from 'assets/img/dialog/icon_microphone.svg'
import { ReactComponent as IconNoAvatar } from 'assets/img/dialog/no_avatar.svg'
import { ReactComponent as IconCloseDialog } from 'assets/img/dialog/close_dialog.svg'

const SvgMap = {
  icon_dialog: IconDialog,
  icon_archive: IconArchive,
  icon_analytics: IconAnalytics,
  icon_users: IconUsers,
  icon_institution: IconInstitution,
  icon_experts: IconExperts,
  icon_keys: IconKeys,
  icon_distributors: IconDistributors,
  icon_bid: IconBid,
  icon_delete: IconDelete,
  icon_other: IconOther,
  icon_edit: IconEdit,
  icon_add: IconAdd,
  icon_block: IconBlock,
  icon_question: IconQuestion,
  icon_fb: IconFb,
  icon_vk: IconVk,
  icon_smile: IconSmile,
  icon_mic: IconMic,
  icon_filter: IconFilter,
  icon_no_avatar: IconNoAvatar,
  icon_news: IconNews,
  close_dialog: IconCloseDialog,
}

const Svg = ({
  name, fill, className, ...rest
}) => {
  const Icon = SvgMap[name]

  // eslint-disable-next-line react/jsx-props-no-spreading
  return (<Icon fill={ fill } className={ className } { ...rest } />)
}

Svg.propTypes = {
  name: PropTypes.oneOf(Object.keys(SvgMap)).isRequired,
  fill: PropTypes.string,
  className: PropTypes.string,
}

Svg.defaultProps = {
  fill: 'currentColor',
  className: 'icon',
}

export default Svg
