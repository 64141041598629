import snakeCase from 'lodash/snakeCase'

export const snakeCaseSections = (str, separator = '/') => {
  const sections = str.split(separator)

  return sections.map(value => snakeCase(value)).join(separator)
}

export {
  snakeCase,
}
