import 'core-js/stable'
import 'regenerator-runtime/runtime'

import React from 'react'

import { AppConstants } from 'consts'
import { ReactUtils } from 'utils'

import App from 'app'

ReactUtils.render(<App />, document.querySelector(AppConstants.APP_ELEMENT))
