/* eslint-disable react/jsx-props-no-spreading */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Select from 'react-select'

import { Field, reduxForm, SubmissionError } from 'redux-form'

import { FunctionUtils } from 'utils'

import uniqueId from 'lodash/uniqueId'
import Spinner from 'components/Spinner'
import Button from 'components/Button'

import cx from 'classnames'
import styles from './styles.module.scss'

class FilterForm extends PureComponent {
  // eslint-disable-next-line
  select = ({ input, label, disabled, options, style, meta: { touched, error }, }) => (
    <Select
      { ...input }
      onChange={ value => {
        this.props.onChangeSelect(value)
        input.onChange(value)
      } }
      onInputChange={ (value, { action }) => {
        if (action === 'input-change') {
          this.setState({ autoCompleteInput: { value, name: input.name } }, () => {
            if (value.length >= 2) {
              this.props.loadOptions(this.state.autoCompleteInput)
            }
          })
        }
      } }
      onBlur={ () => input.onBlur(input.value) }
      options={ options }
      placeholder={ label }
      className={ cx(styles.selectContainer, style, { [styles.selectContainer_error]: touched && error }) }
      isDisabled={ disabled || false }
      isClearable
      isSearchable
    />
  )

  // eslint-disable-next-line
  input = ({ input, label, placeholder, type, style, disabled, meta: { touched, error }, }) => (
    <input
      { ...input }
      placeholder={ placeholder || label }
      type={ type }
      className={ cx(styles.input, style,
        { [styles.input_error]: touched && error },
        { [styles.input_disabled]: disabled }) }
      disabled={ disabled || false }
      onKeyPress={ evt => this.handleEkey(evt, type) }
    />
  )

  handleEkey = (evt, type) => {
    if (type === 'number' && evt.type === 'keypress' && (evt.key === 'e' || evt.key === 'E')) {
      evt.preventDefault()
    }
  }

  renderFieldComponent = component => (this[component])

  requiredField = input => (!input)

  validateDate = input => !(input >= 1 && input <= 31)

  validateYear = input => !(input >= 1900 && input <= new Date().getFullYear())

  validateFullDate = input => {
    if (input === '' || input === undefined) {
      return false
    }

    if (!/^\d{4}-\d{2}-\d{2}/g.test(input)) {
      return 'Некорректный формат даты'
    }

    try {
      const date = new Date(input)
      if (date instanceof Date && !isNaN(date)) {
        return false
      }

      return 'Некорректный формат даты'
    } catch (e) {
      return 'Некорректный формат даты'
    }
  }

  validateEmail = input => {
    if (input === '' || input === undefined) {
      return false
    }

    return (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(input))
      ? 'Некорректный электронный адрес'
      : false
  }


  matchPassword = (input, allInputs) => (input === allInputs.password ? false : 'Пароли не совпадают')

  validateField = validate => validate.map(item => this[item])

  renderField = field => (
    <div className={ cx(styles.fieldWrapper, field.className) } key={ field.name }>
      {field.label ? (
        <p className={ styles.fieldLabel }>
          {`${field.label}:`}
        </p>
      ) : false}
      <Field
        type={ field.type }
        name={ field.name }
        label={ field.label }
        placeholder={ field.placeholder }
        component={ this.renderFieldComponent(field.component) }
        validate={ field.validate ? this.validateField(field.validate) : null }
        options={ field.options }
        disabled={ field.disabled || false }
      />
    </div>
  )

  renderFields = fields => fields.map(item => this.renderField(item))

  rendersubmitSucceeded = title => (
    <p className={ styles.textSucceeded }>{`${title} прошло успешло`}</p>
  )

  render() {
    const {
      // eslint-disable-next-line
      error, pristine, submitting, invalid, handleSubmit, submitSucceeded,
    } = this.props

    const {
      title, cancelAction, cancelText, submittingText, fields,
    } = this.props

    return (
      <div className={ styles.container }>
        <p className={ styles.formTitle }>{title}</p>
        {this.props.isLoading ? <Spinner /> : (
          <form onSubmit={ handleSubmit } className={ styles.formContainer }>
            {submitSucceeded ? this.rendersubmitSucceeded(title) : this.renderFields(fields)}
            {(error === undefined)
              ? (!pristine && invalid && <div className={ styles.error }>Поля заполнены не верно</div>)
              : (error && <div className={ styles.error }>{error}</div>)}
            <div className={ styles.buttonGroup }>
              {submitSucceeded ? false : (
                <Button
                  className={ styles.cancelButton }
                  color="white"
                  filled
                  onClick={ cancelAction }
                >
                  {cancelText}
                </Button>
              )}
              <Button
                className={ styles.submitButton }
                color="blue"
                filled
                disabled={ submitting || pristine || invalid }
                type={ submitSucceeded ? 'button' : 'submit' }
                onClick={ submitSucceeded ? cancelAction : undefined }
              >
                {submitSucceeded ? 'Закрыть' : submittingText}
              </Button>
            </div>
          </form>
        )}
      </div>
    )
  }
}

FilterForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fields: PropTypes.array,
  cancelAction: PropTypes.func.isRequired,
  cancelText: PropTypes.string.isRequired,
  submittingText: PropTypes.string.isRequired,
  submitAction: PropTypes.func.isRequired,
  afterSubmitAction: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  afterSubmitActionArgs: PropTypes.object,
  isLoadingSelector: PropTypes.func.isRequired,
  onChangeSelect: PropTypes.func,
  loadOptions: PropTypes.func,
}

FilterForm.defaultProps = {
  fields: [],
  afterSubmitActionArgs: {},
  onChangeSelect: () => { },
  afterSubmitAction: () => { },
  loadOptions: () => { },
}

const mapStateToProps = (state, ownProps) => ({
  isLoading: ownProps.isLoadingSelector(state),
  initialValues: ownProps.initialValues,
})

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  submitAction: ownProps.submitAction,
}, dispatch)


const onSubmit = (values, dispatch, props) => {
  if (!values) {
    return () => { }
  }

  const cpValues = { ...values }

  const reducedValues = Object.keys(cpValues).reduce((acc, key) => {
    if (cpValues[key] === '') {
      return { ...acc }
    }

    if (typeof cpValues[key] === 'object'
      && (typeof cpValues[key].value === 'number' || typeof cpValues[key].value === 'string')) {
      acc[key] = (cpValues[key].value !== '') ? cpValues[key].value : undefined
      return { ...acc }
    }

    if (key === 'phone') {
      acc[key] = cpValues[key].toString()
      return { ...acc }
    }

    acc[key] = cpValues[key]
    return { ...acc }
  }, {})

  const filter = { filter: { ...reducedValues } }

  return props.submitAction(filter)
    .then(() => {
      if (typeof props.afterSubmitAction === 'function') {
        props.afterSubmitAction(filter)
      }
    })
    .catch(e => {
      throw new SubmissionError({
        _error: 'Что-то пошло не так',
      })
    })
}


export default FunctionUtils.compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: uniqueId('FilterForm-'),
    onSubmit,
    keepDirtyOnReinitialize: true,
    enableReinitialize: true,
    updateUnregisteredFields: true,
  }),
)(FilterForm)
