import { createAction } from 'redux-actions'

import { ReduxUtils } from 'utils'

const fetchActions = ReduxUtils.generateActionCreators('distributors', 'fetch', 'request')
const fetchByNameActions = ReduxUtils.generateActionCreators('distributors', 'fetch_by_name', 'request')

const fetchBidsCountActions = ReduxUtils.generateActionCreators('distributors', 'fetch_bidsCount', 'request')
const fetchBidsActions = ReduxUtils.generateActionCreators('distributors', 'fetch_bids', 'request')
const appruveBidActions = ReduxUtils.generateActionCreators('distributors', 'appruve_bid', 'request')
const requestBidActions = ReduxUtils.generateActionCreators('distributors', 'request_bid', 'request')

const createActions = ReduxUtils.generateActionCreators('distributor', 'create', 'request')
const deleteActions = ReduxUtils.generateActionCreators('distributor', 'delete', 'request')
const editActions = ReduxUtils.generateActionCreators('distributor', 'edit', 'request')

const fetch = ReduxUtils.waitForAction(
  createAction('DISTRIBUTORS/FETCH'),
  fetchActions.successed,
  fetchActions.failed,
)

const fetchBidsCount = ReduxUtils.waitForAction(
  createAction('DISTRIBUTORS/FETCH_BIDSCOUNT'),
  fetchBidsCountActions.successed,
  fetchBidsCountActions.failed,
)

const fetchBids = ReduxUtils.waitForAction(
  createAction('DISTRIBUTORS/FETCH_BIDS'),
  fetchBidsActions.successed,
  fetchBidsActions.failed,
)

const appruveBid = ReduxUtils.waitForAction(
  createAction('DISTRIBUTORS/APPRUVE_BID'),
  appruveBidActions.successed,
  appruveBidActions.failed,
)

const requestBid = ReduxUtils.waitForAction(
  createAction('DISTRIBUTORS/REQUEST_BID'),
  requestBidActions.successed,
  requestBidActions.failed,
)

const fetchByName = ReduxUtils.waitForAction(
  createAction('DISTRIBUTORS/FETCH_BY_NAME'),
  fetchByNameActions.successed,
  fetchByNameActions.failed,
)

const create = ReduxUtils.waitForAction(
  createAction('DISTRIBUTOR/CREATE'),
  createActions.successed,
  createActions.failed,
)

const del = ReduxUtils.waitForAction(
  createAction('DISTRIBUTOR/DELETE'),
  deleteActions.successed,
  deleteActions.failed,
)

const edit = ReduxUtils.waitForAction(
  createAction('DISTRIBUTOR/EDIT'),
  editActions.successed,
  editActions.failed,
)

const __internal = {
  fetch: fetchActions,
  fetchBidsCount: fetchBidsCountActions,
  fetchBids: fetchBidsActions,
  appruveBid: appruveBidActions,
  requestBid: requestBidActions,
  fetchByName: fetchByNameActions,
  create: createActions,
  del: deleteActions,
  edit: editActions,
}

export {
  fetch,
  fetchBidsCount,
  fetchBids,
  appruveBid,
  requestBid,
  fetchByName,
  create,
  del,
  edit,

  __internal,
}
