import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import cx from 'classnames'

import { Field, reduxForm, SubmissionError } from 'redux-form'

import { Actions } from 'ducks'
import { FunctionUtils } from 'utils'

import Router from 'components/Router'
import Button from 'components/Button'

import styles from './styles.module.scss'

const validate = values => {
  const errors = {}

  if (!values.email) {
    errors.email = 'Поле не заполнено'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Некорректный электронный адрес'
  }

  return errors
}

class Forgot extends PureComponent {
  renderInput = ({
    input, label, type, style, meta: { touched, error },
  }) => (
    <div>
      <input
        // eslint-disable-next-line react/jsx-props-no-spreading
        { ...input }
        placeholder={ label }
        type={ type }
        className={ cx(styles.input, style, { [styles.inputError]: touched && error }) }
      />
      { touched && error && <div className={ styles.error }>{ error }</div> }
    </div>
  )

  handleOnClick = () => {
    // eslint-disable-next-line react/prop-types
    this.props.history.goBack()
  }

  renderField = () => (
    <Field
      name="email"
      type="email"
      label="Электронная почта для получения ссылки"
      component={ this.renderInput }
    />
  )

  renderText = () => (
    <p className={ styles.textSucceeded }>
      Ссылка на изменение пароля<br />
      отправлена на указанную<br />
      электронную почту.
    </p>
  )

  render() {
    const {
      // eslint-disable-next-line
      error, pristine, submitting, invalid, handleSubmit, submitSucceeded,
    } = this.props

    return (
      <div className={ styles.container }>
        <div className={ styles.buttonGroup }>
          <Router.Link to="/auth/forgot">
            <Button
              size="medium"
            >
              Смена пароля
            </Button>
          </Router.Link>
        </div>
        <div className={ styles.formContainer }>
          <form onSubmit={ handleSubmit }>
            { submitSucceeded ? this.renderText() : this.renderField() }
            { error && <div className={ styles.error }>{ error }</div> }
            <Button
              className={ styles['auth-button-submit'] }
              type={ submitSucceeded ? 'button' : 'submit' }
              size="large"
              filled
              disabled={ submitting || pristine || invalid }
              onClick={ submitSucceeded ? this.handleOnClick : undefined }
            >
              { submitSucceeded ? 'OK' : 'Восстановить пароль' }
            </Button>
          </form>
          <p className={ styles.link }>
            <Router.Link to="/auth/sign-in" className={ styles.a }>Войдите,</Router.Link> если вспомнили пароль
          </p>
        </div>
      </div>
    )
  }
}

Forgot.propTypes = {
  push: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
}

const mapDispatchToProps = dispatch => bindActionCreators({
  forgotPass: Actions.Auth.forgotPass,
  push: Actions.Router.push,
}, dispatch)

export default FunctionUtils.compose(
  connect(null, mapDispatchToProps),
  reduxForm({
    form: 'ForgotForm',
    validate,
    onSubmit: (values, dispatch, props) => props.forgotPass(values)
      .catch(e => {
        throw new SubmissionError({
          _error: 'Что-то пошло не так',
        })
      }),
  }),
)(Forgot)
