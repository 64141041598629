import { createAction } from 'redux-actions'

import { ReduxUtils } from 'utils'

const fetchActions = ReduxUtils.generateActionCreators('news', 'fetch', 'request')
const fetchOneActions = ReduxUtils.generateActionCreators('news', 'fetch_one', 'request')
const addActions = ReduxUtils.generateActionCreators('news', 'add', 'request')
const changeActions = ReduxUtils.generateActionCreators('news', 'change', 'request')
const deleteActions = ReduxUtils.generateActionCreators('news', 'delete', 'request')

const fetch = ReduxUtils.waitForAction(
  createAction('NEWS/FETCH'),
  fetchActions.successed,
  fetchActions.failed,
)

const fetchOne = ReduxUtils.waitForAction(
  createAction('NEWS/FETCH_ONE'),
  fetchOneActions.successed,
  fetchOneActions.failed,
)

const add = ReduxUtils.waitForAction(
  createAction('NEWS/ADD'),
  addActions.successed,
  addActions.failed,
)

const change = ReduxUtils.waitForAction(
  createAction('NEWS/CHANGE'),
  changeActions.successed,
  changeActions.failed,
)

const del = ReduxUtils.waitForAction(
  createAction('NEWS/DELETE'),
  deleteActions.successed,
  deleteActions.failed,
)


const __internal = {
  fetch: fetchActions,
  fetchOne: fetchOneActions,
  add: addActions,
  change: changeActions,
  del: deleteActions,
}


export {
  fetch,
  fetchOne,
  add,
  change,
  del,

  __internal,
}
