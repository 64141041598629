import React from 'react'

import {
  Route,
  Redirect,
} from 'react-router'

import { useSelector } from 'react-redux'

import { Selectors } from 'ducks'

const createRouteRenderer = (Component, roles, { isAuthorized, userRole }) => componentProps => {
  if (isAuthorized) {
    if (roles.includes(userRole)) {
      return React.createElement(Component, componentProps)
    }

    const redirectPath = {
      pathname: '/404',
      state: {
        redirect: componentProps.location,
      },
    }

    return (
      <Redirect to={ redirectPath } />
    )
  }

  const redirectPath = {
    pathname: '/auth/sign-in',
    state: {
      redirect: componentProps.location,
    },
  }

  return (
    <Redirect to={ redirectPath } />
  )
}

// eslint-disable-next-line react/prop-types
const ProtectedRoute = ({ component, roles, ...routeProps }) => {
  const isAuthorized = useSelector(Selectors.Auth.isAuthorized)
  const userRole = useSelector(Selectors.Auth.role)

  return React.createElement(Route, {
    ...routeProps,
    render: createRouteRenderer(component, roles, { isAuthorized, userRole }),
  })
}

ProtectedRoute.propTypes = {
  component: Route.propTypes.component,
}

ProtectedRoute.defaultProps = {
  component: null,
}

export default React.memo(ProtectedRoute)
