import React from 'react'
import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'
import Helmet from 'react-helmet'

import { Selectors } from 'ducks'

const Wrapper = ({ children }) => {
  const locale = useSelector(Selectors.App.locale)

  return (
    <>
      <Helmet>
        <html lang={ locale } />
      </Helmet>
      { children }
    </>
  )
}

Wrapper.propTypes = {
  children: PropTypes.node,
}

Wrapper.defaultProps = {
  children: null,
}

export default React.memo(Wrapper)
