export default class News {
  constructor(APIService) {
    this.APIService = APIService
  }

  fetchNews(payload) {
    return this.APIService.get('/admin/news/table', payload)
      .then(response => response.data).catch(e => {
        throw e
      })
  }

  fetchOneNews(id) {
    return this.APIService.get('/news/get', { id })
      .then(response => response.data).catch(e => {
        throw e
      })
  }

  addNews(info) {
    return this.APIService.post('/admin/news/upsert', info)
      .then(response => response.data).catch(e => {
        throw e
      })
  }

  uploadPhoto(photo) {
    const bodyFormData = new FormData()
    bodyFormData.set('file', photo)
    return this.APIService.post('/admin/news/photo-upload', bodyFormData)
      .then(response => response.data).catch(e => {
        throw e
      })
  }

  deleteNews(id) {
    return this.APIService.post('/admin/news/delete', id)
      .then(response => response.data)
      .catch(e => {
        throw e
      })
  }
}
