export default class AuthService {
  constructor(APIService) {
    this.APIService = APIService
  }

  signIn(email, password) {
    return this.APIService.post('/auth/local/login', {
      email,
      password,
    }).then(response => response.data.token).catch(e => {
      throw e
    })
  }

  fetchInfo() {
    return this.APIService.get('/auth/fetch')
      .then(response => response.data.auth).catch(e => {
        throw e
      })
  }

  forgotPass(email) {
    return this.APIService.post('/auth/local/send-reset-link', {
      email,
    }).then(response => response.data.success).catch(e => {
      throw e
    })
  }

  changePass(token, password) {
    return this.APIService.post('/auth/local/password-reset', {
      token,
      password,
    }).then(response => response.data).catch(e => {
      throw e
    })
  }

  validateToken(token) {
    return this.APIService.get('/auth/local/password-token-validate', {
      token,
    }).then(response => response.data.email).catch(e => {
      throw e
    })
  }

  getCode(phone) {
    return this.APIService.post('/auth/get-code', { phone })
      .then(response => response.data).catch(e => {
        throw e
      })
  }

  getAccessToken(token, code) {
    return this.APIService.post('/auth/get-access-token', {
      token,
      code,
    }).then(response => response.data.accessToken).catch(e => {
      throw e
    })
  }

  signOut() {
    return this.APIService.post('/auth/sign-out').then(response => response.data).catch(e => { throw e })
  }
}
