import {
  call,
  put,
  putResolve,
  takeLatest,
} from 'redux-saga/effects'

import {
  TeacherService,
} from 'services'

import {
  fetch,
  fetchBidsCount,
  bids,
  appruveBid,
  create,
  del,
  edit,

  __internal,
} from './actions'

function* fetchTask(action) {
  yield put(__internal.fetch.started())

  try {
    const data = yield call([TeacherService, 'fetchExpert'], action.payload)

    yield putResolve(__internal.fetch.successed(data))
  } catch (err) {
    yield put(__internal.fetch.failed(err))
  }

  yield put(__internal.fetch.finished())
}

function* appruveBidTask(action) {
  yield put(__internal.appruveBid.started())

  try {
    yield call([TeacherService, 'appruveBid'], { id: action.payload })

    yield putResolve(__internal.appruveBid.successed())
  } catch (err) {
    yield put(__internal.appruveBid.failed(err))
  }

  yield put(__internal.appruveBid.finished())
}

function* fetchBidsCountTask() {
  yield put(__internal.fetchBidsCount.started())

  try {
    const data = yield call([TeacherService, 'fetchBidsCount'])

    yield putResolve(__internal.fetchBidsCount.successed(data))
  } catch (err) {
    yield put(__internal.fetchBidsCount.failed(err))
  }

  yield put(__internal.fetchBidsCount.finished())
}

function* bidsTask(action) {
  yield put(__internal.bids.started())

  try {
    const data = yield call([TeacherService, 'fetchBids'], action.payload)

    yield putResolve(__internal.bids.successed(data))
  } catch (err) {
    yield put(__internal.bids.failed(err))
  }

  yield put(__internal.bids.finished())
}

function* createTask(action) {
  const data = {
    name: action.payload.name,
    email: action.payload.email,
    phone: action.payload.phone,
    password: action.payload.password,
  }

  yield put(__internal.create.started())

  try {
    const id = yield call([TeacherService, 'createExpert'], data)

    yield putResolve(__internal.create.successed({
      ...data,
      id: id.success,
      name: {
        full: data.name,
      },
    }))
  } catch (err) {
    yield put(__internal.create.failed(err))
  }

  yield put(__internal.create.finished())
}

function* editTask(action) {
  const data = {
    id: action.payload.id,
    name: action.payload.name,
    email: action.payload.email,
    phone: action.payload.phone,
    password: action.payload.password,
  }

  yield put(__internal.edit.started())

  try {
    yield call([TeacherService, 'createExpert'], data)

    yield putResolve(__internal.edit.successed({
      ...data,
      name: {
        full: data.name,
      },
    }))
  } catch (err) {
    yield put(__internal.edit.failed(err))
  }

  yield put(__internal.edit.finished())
}

function* deleteTask(action) {
  const id = action.payload

  yield put(__internal.del.started())

  try {
    yield call([TeacherService, 'deleteExpert'], { id: [id] })

    yield putResolve(__internal.del.successed(id))
  } catch (err) {
    yield put(__internal.del.failed(err))
  }

  yield put(__internal.del.finished())
}

export default function* () {
  yield takeLatest(fetch, fetchTask)
  yield takeLatest(fetchBidsCount, fetchBidsCountTask)
  yield takeLatest(bids, bidsTask)
  yield takeLatest(appruveBid, appruveBidTask)
  yield takeLatest(create, createTask)
  yield takeLatest(del, deleteTask)
  yield takeLatest(edit, editTask)
}
