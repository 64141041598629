import { createAction } from 'redux-actions'

import { ReduxUtils } from 'utils'

const fetchActions = ReduxUtils.generateActionCreators('institutions', 'fetch', 'request')
const fetchBidsCountActions = ReduxUtils.generateActionCreators('institutions', 'fetch_bidsCount', 'request')

const fetchByIdActions = ReduxUtils.generateActionCreators('institution', 'fetch_by_id', 'request')
const fetchByNameActions = ReduxUtils.generateActionCreators('institution', 'fetch_by_name', 'request')
const bidsActions = ReduxUtils.generateActionCreators('institutions', 'bids', 'request')
const appruveBidActions = ReduxUtils.generateActionCreators('institutions', 'appruve_bid', 'request')
const createActions = ReduxUtils.generateActionCreators('institutions', 'create', 'request')
const editActions = ReduxUtils.generateActionCreators('institutions', 'edit', 'request')
const deleteActions = ReduxUtils.generateActionCreators('institutions', 'delete', 'request')

const fetch = ReduxUtils.waitForAction(
  createAction('INSTITUTIONS/FETCH'),
  fetchActions.successed,
  fetchActions.failed,
)

const fetchBidsCount = ReduxUtils.waitForAction(
  createAction('INSTITUTIONS/FETCH_BIDSCOUNT'),
  fetchBidsCountActions.successed,
  fetchBidsCountActions.failed,
)

const fetchById = ReduxUtils.waitForAction(
  createAction('INSTITUTION/FETCH_BY_ID'),
  fetchByIdActions.successed,
  fetchByIdActions.failed,
)

const fetchByName = ReduxUtils.waitForAction(
  createAction('INSTITUTION/FETCH_BY_NAME'),
  fetchByNameActions.successed,
  fetchByNameActions.failed,
)

const bids = ReduxUtils.waitForAction(
  createAction('INSTITUTIONS/BIDS'),
  bidsActions.successed,
  bidsActions.failed,
)

const appruveBid = ReduxUtils.waitForAction(
  createAction('INSTITUTIONS/APPRUVE_BID'),
  appruveBidActions.successed,
  appruveBidActions.failed,
)

const create = ReduxUtils.waitForAction(
  createAction('INSTITUTIONS/CREATE'),
  createActions.successed,
  createActions.failed,
)

const edit = ReduxUtils.waitForAction(
  createAction('INSTITUTIONS/EDIT'),
  editActions.successed,
  editActions.failed,
)

const del = ReduxUtils.waitForAction(
  createAction('INSTITUTIONS/DELETE'),
  deleteActions.successed,
  deleteActions.failed,
)

const __internal = {
  fetch: fetchActions,
  fetchBidsCount: fetchBidsCountActions,
  fetchById: fetchByIdActions,
  fetchByName: fetchByNameActions,
  bids: bidsActions,
  appruveBid: appruveBidActions,
  create: createActions,
  edit: editActions,
  del: deleteActions,

}

export {
  fetch,
  fetchBidsCount,
  fetchById,
  fetchByName,
  bids,
  appruveBid,
  create,
  edit,
  del,

  __internal,
}
