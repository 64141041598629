export default class DialogService {
  constructor(APIService) {
    this.APIService = APIService
  }

  fetchDialogs(payload) {
    return this.APIService.get('/chat/list', payload)
      .then(response => response.data).catch(e => {
        throw e
      })
  }

  fetchChat(payload) {
    return this.APIService.get('/chat/messages', payload)
      .then(response => response.data).catch(e => {
        throw e
      })
  }

  fetchChatNewMessage(payload) {
    return this.APIService.get('/chat/message-get', payload)
      .then(response => response.data).catch(e => {
        throw e
      })
  }

  sendMessage(payload) {
    return this.APIService.post('/chat/message', payload)
      .then(response => response.data).catch(e => {
        throw e
      })
  }

  fetchNewTask(id) {
    return this.APIService.get('/chat/task', { id })
      .then(response => ({ id, user_task: response.data.user_task })).catch(e => {
        throw e
      })
  }
}
