import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Scrollbars } from 'react-custom-scrollbars'

class Scrollable extends PureComponent {
  scrollable = React.createRef()

  getClientHeight() {
    return this.scrollable.current.getClientHeight()
  }

  getScrollHeight() {
    return this.scrollable.current.getScrollHeight()
  }

  getScrollTop() {
    return this.scrollable.current.getScrollTop()
  }

  scrollTop(position) {
    this.scrollable.current.scrollTop(position)
  }

  scrollToBottom() {
    if (this.scrollable && this.scrollable.current && typeof this.scrollable.current.scrollToBottom === 'function') {
      this.scrollable.current.scrollToBottom()
    }
  }

  render() {
    const { children, ...rest } = this.props
    return (
      <Scrollbars
        autoHide
        {...rest} //eslint-disable-line
        ref={ this.scrollable }
      >
        {children}
      </Scrollbars>
    )
  }
}

Scrollable.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Scrollable
