import { combineActions, handleActions } from 'redux-actions'

import produce from 'immer'

import {
  __internal,
} from './actions'

const initialState = {
  loading: false,
  one: [],
  autocompleteList: [],

  list: [],
  count: 0,

  bids: [],
  countBids: 0,
}

const startedActions = combineActions(
  __internal.fetch.started,
  __internal.fetchBidsCount.started,
  __internal.appruveBid.started,
  __internal.fetchById.started,
  __internal.fetchByName.started,
  __internal.bids.started,
  __internal.create.started,
  __internal.edit.started,
  __internal.del.started,
)

const finishedActions = combineActions(
  __internal.fetch.finished,
  __internal.fetchBidsCount.finished,
  __internal.appruveBid.finished,
  __internal.fetchById.finished,
  __internal.fetchByName.finished,
  __internal.bids.finished,
  __internal.create.finished,
  __internal.edit.finished,
  __internal.del.finished,
)

const reducer = handleActions({
  [startedActions]: state => produce(state, next => {
    next.loading = true
  }),
  [__internal.fetch.successed]: (state, action) => produce(state, next => {
    next.list = action.payload.list
    next.count = action.payload.count
  }),
  [__internal.fetchBidsCount.successed]: (state, action) => produce(state, next => {
    next.countBids = action.payload.count
  }),
  [__internal.fetchById.successed]: (state, action) => produce(state, next => {
    next.one = action.payload
  }),
  [__internal.fetchByName.successed]: (state, action) => produce(state, next => {
    next.autocompleteList = action.payload
  }),
  [__internal.bids.successed]: (state, action) => produce(state, next => {
    next.bids = action.payload.list
    next.countBids = action.payload.count
  }),
  [__internal.del.successed]: (state, action) => produce(state, next => {
    next.list = state.list.filter(item => item.id !== action.payload)
  }),
  [__internal.edit.successed]: (state, action) => produce(state, next => {
    let nextInstitution = state.list.find(item => item.id === action.payload.id)
    nextInstitution = { ...nextInstitution, ...action.payload }
    next.list = [...state.list.filter(item => item.id !== action.payload.id),
      nextInstitution].sort((a, b) => (a.id) - (b.id))
  }),
  [__internal.create.successed]: (state, action) => produce(state, next => {
    const newList = { ...action.payload.school, ...action.payload.geo }
    next.list = [...state.list, newList]
  }),
  [finishedActions]: state => produce(state, next => {
    next.loading = false
  }),
}, initialState)

export default reducer
