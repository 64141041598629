import { createAction } from 'redux-actions'

import { ReduxUtils } from 'utils'

const fetchBidsActions = ReduxUtils.generateActionCreators('feedback', 'fetch_bids', 'request')
const fetchBidsCountActions = ReduxUtils.generateActionCreators('feedback', 'fetch_bidsCount', 'request')
const appruveBidActions = ReduxUtils.generateActionCreators('feedback', 'appruve_bid', 'request')

const fetchBids = ReduxUtils.waitForAction(
  createAction('FEEDBACK/FETCH_BIDS'),
  fetchBidsActions.successed,
  fetchBidsActions.failed,
)

const fetchBidsCount = ReduxUtils.waitForAction(
  createAction('FEEDBACK/FETCH_BIDSCOUNT'),
  fetchBidsCountActions.successed,
  fetchBidsCountActions.failed,
)

const appruveBid = ReduxUtils.waitForAction(
  createAction('FEEDBACK/APPRUVE_BID'),
  appruveBidActions.successed,
  appruveBidActions.failed,
)

const __internal = {
  fetchBids: fetchBidsActions,
  fetchBidsCount: fetchBidsCountActions,
  appruveBid: appruveBidActions,
}

export {
  fetchBids,
  fetchBidsCount,
  appruveBid,

  __internal,
}
