import {
  // combineActions,
  handleActions,
} from 'redux-actions'

import produce from 'immer'

import {
  __internal,
} from './actions'

const initialState = {
  currentDialog: {},
  loadingDialog: false,
  loadingChat: false,
  list: [],
  teacherChat: {},
  listTask: {},
  chatList: [],
  isEnd: false,
  isEndChat: false,
}

// const startedActions = combineActions(
//   __internal.fetchDialogList.started,
// )

// const finishedActions = combineActions(
//   __internal.fetchDialogList.finished,
// )

const reducer = handleActions({
  [__internal.fetchDialogList.started]: state => produce(state, next => {
    next.loadingDialog = true
  }),
  [__internal.fetchChatList.started]: state => produce(state, next => {
    next.loadingChat = true
  }),
  [__internal.dialogInit]: state => produce(state, next => {
    next.list = []
    next.loadingDialog = true
  }),
  [__internal.dialogChatInit]: state => produce(state, next => {
    next.chatList = []
    next.loadingChat = true
  }),
  [__internal.fetchDialogList.successed]: (state, action) => produce(state, next => {
    next.list = state.list.concat(action.payload.data.list)
    next.isEnd = (action.payload.data.list.length < action.payload.params.limit)
    next.listTask = action.payload.data.list.reduce((acc, item) => {
      if (item.user_task) {
        return { ...acc, [item.id]: item.user_task }
      }
      return acc
    }, state.listTask)
    next.teacherChat = action.payload.data.list.reduce((acc, item) => {
      if (item.EmployeeId) {
        return { ...acc, [item.EmployeeId]: item.id }
      }
      return acc
    }, state.teacherChat)
  }),
  [__internal.fetchNewTask.successed]: (state, action) => produce(state, next => {
    next.listTask = { ...state.listTask, [action.payload.id]: action.payload.user_task }
  }),
  [__internal.fetchChatList.successed]: (state, action) => produce(state, next => {
    next.chatList = state.chatList.concat(action.payload.data.messages) // .sort((a, b) => (b.id - a.id))
    next.isEndChat = (action.payload.data.messages.length < action.payload.params.limit)
  }),
  [__internal.fetchChatNewMessage.successed]: (state, action) => {
    const prevChatlist = [...state.chatList]
    prevChatlist.splice(0, 0, action.payload.data.message)

    return produce(state, next => {
      next.chatList = prevChatlist // .concat(action.payload.message).sort((a, b) => (b.id - a.id))
    })
  },
  [__internal.changeTeacherStatus]: (state, action) => {
    const { teacherJoin, teacherLeave } = action.payload

    return produce(state, next => {
      if (teacherLeave) {
        delete next.teacherChat[teacherLeave.id]
      }

      if (teacherJoin) {
        next.teacherChat[teacherJoin.id] = teacherJoin.ChatId
      }
    })
  },
  [__internal.fetchDialogList.finished]: state => produce(state, next => {
    next.loadingDialog = false
  }),
  [__internal.fetchChatList.finished]: state => produce(state, next => {
    next.loadingChat = false
  }),
  [__internal.currentDialog]: (state, action) => produce(state, next => {
    next.currentDialog = action.payload
  }),
}, initialState)

export default reducer
