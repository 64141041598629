import { combineActions, handleActions } from 'redux-actions'

import produce from 'immer'

import {
  __internal,
} from './actions'

const initialState = {
  loading: false,

  bids: [],
  countBids: 0,
}

const startedActions = combineActions(
  __internal.fetchBids.started,
  __internal.fetchBidsCount.started,
  __internal.appruveBid.started,
)

const finishedActions = combineActions(
  __internal.fetchBids.finished,
  __internal.fetchBidsCount.finished,
  __internal.appruveBid.finished,

)

const reducer = handleActions({
  [startedActions]: state => produce(state, next => {
    next.loading = true
  }),
  [__internal.fetchBids.successed]: (state, action) => produce(state, next => {
    next.bids = action.payload.list
    next.countBids = action.payload.count
  }),
  [__internal.fetchBidsCount.successed]: (state, action) => produce(state, next => {
    next.countBids = action.payload.count
  }),
  [finishedActions]: state => produce(state, next => {
    next.loading = false
  }),
}, initialState)

export default reducer
