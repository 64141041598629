import React from 'react'

import Button from 'components/Button'
import Router from 'components/Router'

import styles from './styles.module.scss'

const NotFound = () => (
  <div className={ styles.page }>
    <h1>Что-то пошло не так…</h1>
    <h3 className={ styles.h3 }>Ссылка, на которую вы нажали, возможно, устарела и больше не работает,</h3>
    <h3 className={ styles.h3 }>или Вы случайно перешли на страницу, которая не доступна Вам для просмотра.</h3>
    <h4 className={ styles.h4 }>Попытайтесь ещё раз ввести адрес в строке</h4>
    <h4 className={ styles.h4 }>Или вернитесь на «Главную»</h4>
    <Router.Link to="/">
      <Button
        size="large"
        color="blue"
        filled
      >
        Главная страница
      </Button>
    </Router.Link>
  </div>
)

export default NotFound
