import {
  call,
  put,
  putResolve,
  takeLatest,
} from 'redux-saga/effects'

import {
  StatisticService,
} from 'services'

import {
  fetchInstalls,

  __internal,
} from './actions'

function* fetchInstallsTask() {
  yield put(__internal.fetchInstalls.started())

  try {
    const data = yield call([StatisticService, 'fetchInstalls'])

    yield putResolve(__internal.fetchInstalls.successed(data))
  } catch (err) {
    yield put(__internal.fetchInstalls.failed(err))
  }

  yield put(__internal.fetchInstalls.finished())
}

export default function* () {
  yield takeLatest(fetchInstalls, fetchInstallsTask)
}
