import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { fromQueryString, toQueryString } from 'utils/query'

import { Actions, Selectors } from 'ducks'
import { FunctionUtils } from 'utils'

import DeleteForm from 'components/Form/DeleteForm'
import Spinner from 'components/Spinner'
import Button from 'components/Button'
import Table from 'components/Table'
import Modal from 'components/Modal'
import Svg from 'components/Svg'
import Form from 'components/Form'
import FilterForm from 'components/Form/FilterForm'

import cx from 'classnames'

import styles from './styles.module.scss'
import 'react-virtualized/styles.css'

class Experts extends PureComponent {
  constructor(props) {
    super(props)

    const query = (this.props.location && this.props.location.search)
      ? fromQueryString(this.props.location.search)
      : {
        limit: 10,
        offset: 0,
        order: {
          field: 'id',
          direct: 'DESC',
        },
      }

    this.state = {
      COLUMN: [
        {
          dataKey: null,
          maxWidth: 25,
          className: styles.id,
          headerRenderer: () => (
            <div className={ cx(styles.firstColumn, styles.firstColumnHeader) }>
              <Svg name="icon_edit" />
            </div>
          ),
          cellRenderer: ({ rowData }) => (
            <div className={ cx(styles.firstColumn, styles.firstColumnCell) }>
              <Svg
                name="icon_edit"
                className={ styles.editIcon }
                onClick={ () => this.makeEditFormValue(rowData) }
              />
            </div>
          ),
        },
        {
          name: 'ID',
          dataKey: 'id',
          maxWidth: 35,
        },
        {
          name: 'ФИО',
          dataKey: 'name',
          flexGrow: 1,
          cellDataGetter: ({ rowData }) => rowData.name.full,
        },
        {
          name: 'Электронная почта',
          dataKey: 'email',
          flexGrow: 1,
        },
        {
          name: 'Контактный телефон',
          dataKey: 'phone',
          maxWidth: 100,
        },
        {
          dataKey: null,
          maxWidth: 25,
          className: styles.id,
          headerRenderer: () => (
            <div className={ cx(styles.firstColumn, styles.firstColumnHeader) }>
              <Svg name="icon_delete" />
            </div>
          ),
          cellRenderer: ({ rowData }) => (
            <div className={ cx(styles.firstColumn, styles.firstColumnCell) }>
              <Svg
                name="icon_delete"
                className={ styles.deleteIcon }
                onClick={ () => this.setState({ showModal: true, modalType: 'delete', rowData }) }
              />
            </div>
          ),
        },
      ],
      ADD_FIELDS: [
        {
          name: 'name',
          label: 'ФИО',
          validate: ['requiredField'],
          component: 'input',
        },
        {
          name: 'email',
          type: 'email',
          label: 'Электронная почта',
          validate: ['requiredField', 'validateEmail'],
          component: 'input',
        },
        {
          name: 'phone',
          type: 'phone',
          label: 'Контактный телефон',
          validate: ['requiredField'],
          component: 'input',
        },
        {
          name: 'password',
          type: 'password',
          label: 'Пароль',
          validate: ['requiredField'],
          component: 'input',
        },
        {
          name: 'confirm_password',
          type: 'password',
          label: 'Повторите пароль',
          validate: ['requiredField', 'matchPassword'],
          component: 'input',
        },
      ],
      EDIT_FIELDS: [
        {
          name: 'id',
          label: 'ID',
          component: 'input',
          disabled: true,
        },
        {
          name: 'name',
          label: 'ФИО',
          validate: ['requiredField'],
          component: 'input',
        },
        {
          name: 'email',
          type: 'email',
          label: 'Электронная почта',
          validate: ['requiredField', 'validateEmail'],
          component: 'input',
        },
        {
          name: 'phone',
          type: 'phone',
          label: 'Контактный телефон',
          validate: ['requiredField'],
          component: 'input',
        },
      ],
      FILTER_FIELDS: [
        {
          name: 'id',
          label: 'ID',
          component: 'input',
          type: 'number',
        },
        {
          name: 'name',
          label: 'Имя',
          component: 'input',
        },
        {
          name: 'email',
          label: 'Email',
          component: 'input',
          validate: ['validateEmail'],
        },
        {
          name: 'phone',
          label: 'Телефон',
          component: 'input',
        },
      ],
      showModal: false,
      modalType: '',
      rowData: null,
      fetchParams: {
        limit: query.limit || 10,
        offset: query.offset || 0,
        order: {
          field: (query.order && query.order.field) ? query.order.field : 'id',
          direct: (query.order && query.order.direct) ? query.order.direct : 'DESC',
        },
        filter: query.filter || undefined,

      },
      tableParams: {
        limit: query.limit || 10,
        offset: query.offset || 0,
        field: (query.order && query.order.field) ? query.order.field : 'id',
        direct: (query.order && query.order.direct) ? query.order.direct : 'DESC',
      },
    }
  }

  componentDidMount() {
    this.props.fetchTeachers(this.state.fetchParams)

    this.props.history.replace({
      pathname: this.props.location.pathname,
      search: toQueryString(this.state.fetchParams),
    })
  }


  onTableUpdate = tableState => {
    if (tableState) {
      const fetchParams = {
        ...this.state.fetchParams,
      }

      if (typeof tableState.limit === 'number') {
        fetchParams.limit = tableState.limit
      }
      if (typeof tableState.offset === 'number') {
        fetchParams.offset = tableState.offset
      }
      if (tableState.field) {
        fetchParams.order.field = tableState.field
      }
      if (tableState.direct) {
        fetchParams.order.direct = tableState.direct
      }

      this.props.fetchTeachers(fetchParams)

      this.props.history.replace({
        pathname: this.props.location.pathname,
        search: toQueryString(fetchParams),
      })

      this.setState(prevState => ({
        fetchParams: {
          ...fetchParams,
        },
        tableParams: {
          ...prevState.tableParams,
          ...tableState,
        },
      }))
    }
  }


  renderTable = () => (
    <div className={ styles.AutoSizeWrapper }>
      <Table
        className={ styles.table }
        headerClassName={ styles.header }
        columns={ this.state.COLUMN }
        list={ this.props.list }
        count={ this.props.count }
        params={ this.state.tableParams }
        onTableUpdate={ this.onTableUpdate }
      />
    </div>
  )

  renderDeleteForm = data => (
    <DeleteForm
      title="Удаление эксперта"
      isLoadingSelector={ Selectors.Teachers.isLoading }
      submittingText="Да"
      cancelText="Отменить"
      cancelAction={ () => this.setState({ showModal: false }) }
      submitAction={ Actions.Teachers.del }
      data={ { ...data, name: data.name.full } }
      afterSubmitAction={ this.props.fetchTeachers }
      afterSubmitActionArgs={ this.state.fetchParams }
    />
  )

  renderAddForm = fields => (
    <Form
      title="Добавление эксперта"
      isLoadingSelector={ Selectors.Teachers.isLoading }
      submittingText="Добавить"
      cancelText="Отменить"
      cancelAction={ () => this.setState({ showModal: false }) }
      fields={ fields }
      submitAction={ Actions.Teachers.create }
      afterSubmitAction={ this.props.fetchTeachers }
      afterSubmitActionArgs={ this.state.fetchParams }
    />
  )

  renderEditForm = fields => (
    <Form
      title="Изменение данных эксперта"
      isLoadingSelector={ Selectors.Teachers.isLoading }
      submittingText="Изменить"
      cancelText="Отменить"
      cancelAction={ () => this.setState({ showModal: false }) }
      fields={ fields }
      submitAction={ Actions.Teachers.edit }
      initialValues={ this.state.initialValues }
      afterSubmitAction={ this.props.fetchTeachers }
      afterSubmitActionArgs={ this.state.fetchParams }
    />
  )

  makeEditFormValue = rowData => {
    this.setState({
      initialValues: {
        id: rowData.id,
        name: rowData.name.full,
        email: rowData.email,
        phone: rowData.phone,
      },
      showModal: true,
      modalType: 'edit',
    })
  }

  renderForm = type => {
    switch (type) {
      case 'add':
        return this.renderAddForm(this.state.ADD_FIELDS)
      case 'delete':
        return this.renderDeleteForm(this.state.rowData)
      case 'edit':
        return this.renderEditForm(this.state.EDIT_FIELDS)
      case 'filter':
        return this.renderFilterForm(this.state.FILTER_FIELDS)
      default:
        return null
    }
  }

  renderModal = () => (
    <Modal isOpen={ this.state.showModal } onRequestClose={ () => this.setState({ showModal: false }) }>
      {this.state.showModal ? this.renderForm(this.state.modalType) : false}
    </Modal>
  )


  showFilterModal = evt => {
    if (evt.type === 'keypress' && evt.key !== 'Enter') {
      return
    }

    this.setState(state => ({
      initialValues: { ...state.fetchParams.filter },
      showModal: true,
      modalType: 'filter',
    }))
  }

  setFilter = values => {
    this.setState(state => {
      const fetchParams = {
        ...state.fetchParams,
        ...values,
      }

      this.props.history.replace({
        pathname: this.props.location.pathname,
        search: toQueryString(fetchParams),
      })

      return {
        showModal: false,
        fetchParams,
      }
    })
  }

  removeFilter = evt => {
    if (evt.type === 'keypress' && evt.key !== 'Enter') {
      return
    }

    if (!this.state.fetchParams.filter || !Object.keys(this.state.fetchParams.filter).length) {
      return
    }

    this.setState(state => {
      const fetchParams = {
        ...state.fetchParams,
        filter: undefined,
      }

      this.props.fetchTeachers(fetchParams)

      this.props.history.replace({
        pathname: this.props.location.pathname,
        search: toQueryString(fetchParams),
      })

      return {
        fetchParams,
      }
    })
  }

  renderFilterForm = fields => (
    <FilterForm
      title="Расширенный поиск"
      isLoadingSelector={ Selectors.Teachers.isLoading }
      submittingText="Поиск"
      cancelText="Отменить"
      cancelAction={ () => this.setState({ showModal: false }) }
      initialValues={ this.state.initialValues }
      fields={ fields }
      submitAction={ values => Actions.Teachers.fetch({ ...this.state.fetchParams, ...values }) }
      afterSubmitAction={ this.setFilter }
    />
  )

  render() {
    return (
      <div className={ cx(styles.page, { [styles.spinner]: this.props.isLoading }) }>
        {this.props.isLoading ? <Spinner /> : (
          <div className={ styles.container }>
            <div className={ styles.header }>
              <div className={ styles.label }>
                Эксперты
              </div>
              <Button
                className={ styles.button }
                color="blue"
                filled
                onClick={ () => this.setState({ showModal: true, modalType: 'add' }) }
              >
                Добавить эксперта
              </Button>
              <div
                className={ styles.filter }
                role="button"
                onClick={ this.showFilterModal }
                tabIndex={ 0 }
                onKeyPress={ this.showFilterModal }
              >
                <Svg name="icon_filter" />
                <div className={ styles.filter__text }>
                  Расширенный поиск
                </div>
              </div>
              {this.state.fetchParams.filter && (
                <div
                  role="button"
                  onClick={ this.removeFilter }
                  tabIndex={ 0 }
                  onKeyPress={ this.removeFilter }
                >
                  <div className={ styles.filter__remove }>
                    Отчистить фильтр
                  </div>
                </div>
              )}
            </div>
            {this.renderTable()}
          </div>
        )}
        {this.renderModal()}
      </div>
    )
  }
}

Experts.propTypes = {
  fetchTeachers: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.object,
  ).isRequired,
  count: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired, //eslint-disable-line
  history: PropTypes.object.isRequired, //eslint-disable-line
}

const mapStateToProps = state => ({
  isLoading: Selectors.Teachers.isLoading(state),
  list: Selectors.Teachers.list(state),
  count: Selectors.Teachers.count(state),
})

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchTeachers: Actions.Teachers.fetch,
}, dispatch)

export default FunctionUtils.compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Experts)
