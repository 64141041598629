/* eslint-disable react/jsx-props-no-spreading */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import cx from 'classnames'

import { Field, reduxForm, SubmissionError } from 'redux-form'

import { APIConfig } from 'configs'

import { Actions, Selectors } from 'ducks'
import { FunctionUtils } from 'utils'

import Router from 'components/Router'
import Button from 'components/Button'
import Spinner from 'components/Spinner'

import styles from './styles.module.scss'

class SignUp extends PureComponent {
  handleOnClick = () => {
    // eslint-disable-next-line react/prop-types
    this.props.history.goBack()
  }

  requiredInput = input => (!input)

  validateEmail = input => (
    (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(input))
      ? 'Некорректный электронный адрес'
      : false
  )

  renderTextarea = ({
    input, label, style, meta: { touched, error },
  }) => (
    <textarea
      // eslint-disable-next-line react/jsx-props-no-spreading
      { ...input }
      placeholder={ label }
      className={ cx(styles.input, styles.textarea, style, { [styles.inputError]: touched && error }) }
    />
  )

  renderInput = ({
    input, label, type, style, meta: { touched, error },
  }) => (
    <div>
      <input
        // eslint-disable-next-line react/jsx-props-no-spreading
        { ...input }
        placeholder={ label }
        type={ type }
        className={ cx(styles.input, style, { [styles.inputError]: touched && error }) }
      />
      { (label === 'Электронная почта')
        ? touched && error && <div className={ styles.errorEmail }>{ error }</div>
        : false}
    </div>
  )

  renderField = ({
    name, label, className, validate, type, component, value,
  }) => (
    <Field
      name={ name }
      value={ value }
      type={ type }
      label={ label }
      component={ component || this.renderInput }
      style={ styles[className] }
      validate={ validate }
    />
  )

  renderText = () => (
    <p className={ styles.textSucceeded }>
      Заявка отправлена, <br />
      в ближайщее время с Вами свяжется наш специалист.
    </p>
  )

  renderRadioButton = () => (
    <div>
      <p className={ styles.textRadio }>Готов выделять ежедневно на общение с учащимися (часов)</p>
      <div className={ styles.radioGroup }>
        <div className={ styles.radioButton }>
          {this.renderField({
            name: 'work_time',
            type: 'radio',
            value: '2',
            className: 'radioInput',
            validate: [this.requiredInput],
          })}
          2
        </div>
        <div className={ styles.radioButton }>
          {this.renderField({
            name: 'work_time',
            type: 'radio',
            value: '3',
            className: 'radioInput',
            validate: [this.requiredInput],
          })}
          3
        </div>
        <div className={ styles.radioButton }>
          {this.renderField({
            name: 'work_time',
            type: 'radio',
            value: '4',
            className: 'radioInput',
            validate: [this.requiredInput],
          })}
          4
        </div>
        <div className={ styles.radioButton }>
          {this.renderField({
            name: 'work_time',
            type: 'radio',
            value: '5',
            className: 'radioInput',
            validate: [this.requiredInput],
          })}
          5
        </div>
        <div className={ styles.radioButton }>
          {this.renderField({
            name: 'work_time',
            type: 'radio',
            value: '6',
            className: 'radioInput',
            validate: [this.requiredInput],
          })}
          6
        </div>
      </div>
    </div>
  )

  render() {
    const {
      // eslint-disable-next-line
      error, pristine, submitting, invalid, handleSubmit, submitSucceeded,
    } = this.props

    return (
      <div className={ styles.container }>
        { this.props.isLoading ? <Spinner /> : (
          <div className={ styles.container }>
            <div className={ styles.buttonGroup }>
              <Router.Link to="/auth/sign-in">
                <Button
                  className={ styles['auth-button-in'] }
                  size="medium"
                >
                  Войти
                </Button>
              </Router.Link>
              <Router.Link to="/auth/sign-up">
                <Button
                  size="medium"
                  filled
                >
                  Создать аккаунт
                </Button>
              </Router.Link>
            </div>
            <div className={ styles.formContainer }>
              <form onSubmit={ handleSubmit }>
                { submitSucceeded
                  ? this.renderText()
                  : this.renderField({
                    name: 'name',
                    label: 'ФИО',
                    validate: [this.requiredInput],
                  })}
                {
                  submitSucceeded
                    ? false
                    : this.renderField({
                      name: 'phone',
                      label: 'Контактный телефон',
                      className: 'secondaryInput',
                      validate: [this.requiredInput],
                    })
                }
                {
                  submitSucceeded
                    ? false
                    : this.renderField({
                      name: 'email',
                      label: 'Электронная почта',
                      className: 'secondaryInput',
                      validate: [this.requiredInput, this.validateEmail],
                    })
                }
                {
                  submitSucceeded
                    ? false
                    : this.renderField({
                      name: 'expirience_lng',
                      label: 'Опыт разработки (укажите языки, на которых вы программируете)',
                      className: 'secondaryInput',
                      validate: [this.requiredInput],
                      component: this.renderTextarea,
                    })
                }
                {
                  submitSucceeded
                    ? false
                    : this.renderField({
                      name: 'expirience',
                      label: 'Опыт преподавания (название учебного заведения, название курса, программы)',
                      className: 'secondaryInput',
                      validate: [this.requiredInput],
                      component: this.renderTextarea,
                    })
                }
                {
                  submitSucceeded ? false : this.renderRadioButton()
                }
                {(error === undefined)
                  ? (!pristine && invalid && <div className={ styles.error }>Заполнены не все поля</div>)
                  : (error && <div className={ styles.error }>{ error }</div>)}
                <Button
                  className={ styles['button-submit'] }
                  type={ submitSucceeded ? 'button' : 'submit' }
                  size="large"
                  filled
                  disabled={ submitting || pristine || invalid }
                  onClick={ submitSucceeded ? this.handleOnClick : undefined }
                >
                  { submitSucceeded ? 'ОК' : 'Создать аккаунт' }
                </Button>
              </form>
              { submitSucceeded ? false : (
                <span className={ styles.license }>
                  Нажимая кнопку, вы соглашаетесь с&nbsp;
                  <a
                    href={ `${APIConfig.PUBLIC_URL}/files/Cyber_Book_oferta.pdf` }
                    target="_blank"
                    rel="noopener noreferrer"
                    className={ styles.linkLicense }
                  >Условиями использования
                    <br />
                  и  пользовательского соглашения
                  </a>
                </span>
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
}

SignUp.propTypes = {
  push: PropTypes.func.isRequired,
  teacher: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  isLoading: Selectors.RequestForm.isLoading(state),
})

const mapDispatchToProps = dispatch => bindActionCreators({
  push: Actions.Router.push,
  teacher: Actions.RequestForm.teacher,
}, dispatch)

export default FunctionUtils.compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'LogUpForm',
    onSubmit: (values, dispatch, props) => props.teacher(values)
      .catch(e => {
        throw new SubmissionError({
          _error: 'Что-то пошло не так',
        })
      }),
  }),
)(SignUp)
