import React, { PureComponent } from 'react'

import styles from './styles.module.scss'

class SocialMobileFail extends PureComponent {
  renderText = () => <p className={ styles.textSucceeded }>Что-то пошло не так</p>
  // // eslint-disable-next-line
  // const success = this.props.location.search.match(/^\?success\=(.*)/)
  // const text = (success[1] === 'true'
  //   ? 'Cпасибо, авторизация прошла успешно'
  //   : 'Что-то пошло не так')

  render() {
    return (
      <div className={ styles.container }>
        <div className={ styles.formContainer }>
          { this.renderText() }
        </div>
      </div>
    )
  }
}

export default SocialMobileFail
