import { eventChannel } from 'redux-saga'
import {
  fork, take, call, put, cancelled, takeLatest, select, race,
} from 'redux-saga/effects'
import socketIO from 'socket.io-client'

import {
  fetchChatNewMessage, fetchNewTask, changeTeacherStatus,
} from 'ducks/dialog/actions'

import {
  changeStatus, joinRoom, leaveRoom,
} from './actions'
import { isAuthorized as isAuthorizedSelector, accessToken } from '../auth/selectors'
import { currentDialog } from '../dialog/selectors'

let socket = null

function createSocketEventChannel(event) {
  return eventChannel(emitter => {
    const fn = payload => {
      if (payload) {
        emitter(payload)
      } else {
        emitter({})
      }
    }

    socket.on(event, fn)

    return () => {
      socket.off(event, fn)
    }
  })
}

function* socketUserStatusTask() {
  const socketStatusJoinChannel = yield call(createSocketEventChannel, 'user:join')
  const socketStatusLeaveChannel = yield call(createSocketEventChannel, 'user:leave')

  try {
    while (true) {
      const { userJoin, userLeave } = yield race({
        userJoin: take(socketStatusJoinChannel),
        userLeave: take(socketStatusLeaveChannel),
      })

      yield put(changeStatus({ userJoin, userLeave }))
    }
  } finally {
    if (yield cancelled()) {
      socket.disconnect()
    }
  }
}

function* socketTeacherStatusTask() {
  const socketStatusJoinChannel = yield call(createSocketEventChannel, 'teacher:join')
  const socketStatusLeaveChannel = yield call(createSocketEventChannel, 'teacher:leave')

  try {
    while (true) {
      const { teacherJoin, teacherLeave } = yield race({
        teacherJoin: take(socketStatusJoinChannel),
        teacherLeave: take(socketStatusLeaveChannel),
      })

      yield put(changeTeacherStatus({ teacherJoin, teacherLeave }))
    }
  } finally {
    if (yield cancelled()) {
      socket.disconnect()
    }
  }
}

function* socketMessageTask() {
  const socketMessageChannel = yield call(createSocketEventChannel, 'message')

  try {
    while (true) {
      const { ChatId, id } = yield take(socketMessageChannel)

      yield put(fetchChatNewMessage({ ChatId, id }))
    }
  } finally {
    if (yield cancelled()) {
      socket.disconnect()
    }
  }
}

function* socketTaskUpdatedTask() {
  const socketTaskUpdatedChannel = yield call(createSocketEventChannel, 'task:updated')

  try {
    while (true) {
      yield take(socketTaskUpdatedChannel)
      const { id } = yield select(currentDialog)

      if (id) {
        yield put(fetchNewTask(id))
      }
    }
  } finally {
    if (yield cancelled()) {
      socket.disconnect()
    }
  }
}

function* initSocket() {
  const isAuthorized = yield select(isAuthorizedSelector)
  const token = yield select(accessToken)

  if (!isAuthorized) {
    if (socket) {
      socket.disconnect()
    }

    socket = null

    return
  }

  if (socket) {
    return
  }

  socket = socketIO('/chat', {
    query: {
      token,
    },
  })

  yield fork(socketTeacherStatusTask)
  yield fork(socketUserStatusTask)
  yield fork(socketMessageTask)
  yield fork(socketTaskUpdatedTask)
}

function joinRoomTask(action) {
  const { id } = action.payload
  socket.emit('join', `room:${id}`)
}

function leaveRoomTask() {
  socket.emit('leave')
}

export default function* () {
  yield takeLatest('APP/INIT/SUCCESSED', initSocket)
  yield takeLatest('AUTH/SIGN_IN/REQUEST_SUCCESSED', initSocket)
  yield takeLatest('AUTH/SIGN_OUT/REQUEST_SUCCESSED', initSocket)
  yield takeLatest(joinRoom, joinRoomTask)
  yield takeLatest(leaveRoom, leaveRoomTask)
}
