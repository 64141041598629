import {
  handleActions,
} from 'redux-actions'

import produce from 'immer'

import {
  changeStatus,
} from './actions'

const initialState = {
  changeStatus: {
    userJoin: {},
    userLeave: {},
  },
}

const reducer = handleActions({
  [changeStatus]: (state, action) => produce(state, next => {
    next.changeStatus = { ...action.payload }
  }),
}, initialState)

export default reducer
