import { applyMiddleware, createStore, compose } from 'redux'

import { routerMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'
import createReduxWaitForMiddleware from 'redux-wait-for-action'

import { persistStore } from 'redux-persist'

import rootReducer, { saga, Actions } from 'ducks'

import { history } from '../history'

export const configureStore = initialState => {
  const reduxDevTool = typeof window === 'object'
   && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
   && process.env.NODE_ENV === 'development'
  const composeWithDevTools = !reduxDevTool ? compose : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    trace: true,
    traceLimit: 25,
  })

  const sagaMiddleware = createSagaMiddleware({})

  const appliedMiddlewares = [
    sagaMiddleware,
    createReduxWaitForMiddleware(),
    routerMiddleware(history),
  ]

  const middlewares = composeWithDevTools(applyMiddleware(...appliedMiddlewares))

  const store = createStore(rootReducer, initialState, middlewares)

  sagaMiddleware.run(saga)

  const persistor = persistStore(store, null, () => {
    store.dispatch(Actions.App.init())
  })

  return {
    store,
    persistor,
  }
}
