import {
  Translate,
  Localize,

  t,
  l,

  setLocale,
  setTranslations,
} from 'react-i18nify'

import { AppConstants } from 'consts'

import * as locales from 'locales'

setLocale(AppConstants.DEFAULT_LOCALE)
setTranslations(locales)

const I18n = {
  Translate,
  Localize,

  t,
  l,

  setLocale,
  setTranslations,
}

export default I18n
