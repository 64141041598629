import { eventChannel } from 'redux-saga'

import {
  fork,
  call,
  put,
  putResolve,
  take,
  takeLatest,
  cancelled,
} from 'redux-saga/effects'

import {
  APIService,
  AuthService,
} from 'services'

import { actions as routerActions } from '../router'

import {
  signIn,
  signUp,
  signOut,
  forgotPass,
  changePass,
  validateToken,

  __internal,
} from './actions'

function createAPIServiceAuthErrorEventChannel() {
  return eventChannel(emitter => {
    const unsubscribe = APIService.addErrorHandler(response => {
      emitter(response.status === 401)

      return Promise.reject()
    })

    return () => {
      if (typeof unsubscribe === 'function') {
        unsubscribe()
      }
    }
  })
}

function* apiServiceAuthErrorTask() {
  const apiServiceAuthErrorEventChannel = yield call(createAPIServiceAuthErrorEventChannel)
  try {
    while (true) {
      const is401 = yield take(apiServiceAuthErrorEventChannel)

      if (is401) {
        yield putResolve(__internal.signOut.successed())

        window.location.href = '/'
      }
    }
  } finally {
    if (yield cancelled()) {
      apiServiceAuthErrorEventChannel.close()
    }
  }
}

function* signInTask(action) {
  const {
    email,
    password,
  } = action.payload

  yield put(__internal.signIn.started())

  try {
    const token = yield call([AuthService, 'signIn'], email, password)
    yield call([APIService, 'setAccessToken'], token)
    const info = yield call([AuthService, 'fetchInfo'])

    yield putResolve(__internal.signIn.successed({ token, role: info.role, id: info.id }))

    yield putResolve(routerActions.push('/'))
  } catch (err) {
    yield put(__internal.signIn.failed(err))
  }

  yield put(__internal.signIn.finished())
}

function* signUpTask() {
  yield put(__internal.signUp.started())

  try {
    // yield call([AuthService, 'signUp'])
    yield put(__internal.signUp.successed())
  } catch (err) {
    yield put(__internal.signUp.failed(err))
  }

  yield put(__internal.signUp.finished())
}

function* signOutTask() {
  yield put(__internal.signOut.started())

  try {
    // yield call([AuthService, 'signOut'])
    yield put(routerActions.replace('/'))
    yield put(__internal.signOut.successed())
  } catch (err) {
    yield put(__internal.signOut.failed(err))
  }

  yield put(__internal.signOut.finished())
}

function* forgotPassTask(action) {
  const {
    email,
  } = action.payload

  yield put(__internal.forgotPass.started())

  try {
    const response = yield call([AuthService, 'forgotPass'], email)
    yield putResolve(__internal.forgotPass.successed(response))
  } catch (err) {
    yield put(__internal.forgotPass.failed(err))
  }

  yield put(__internal.forgotPass.finished())
}

function* changePassTask(action) {
  const {
    password,
    token,
  } = action.payload

  yield put(__internal.changePass.started())

  try {
    const response = yield call([AuthService, 'changePass'], token, password)

    yield putResolve(__internal.changePass.successed(response))
  } catch (err) {
    yield put(__internal.changePass.failed(err))
  }

  yield put(__internal.changePass.finished())
}

function* validateTokenTask(action) {
  const {
    token,
  } = action.payload

  yield put(__internal.validateToken.started())

  try {
    const response = yield call([AuthService, 'validateToken'], token)
    yield putResolve(__internal.validateToken.successed({ token, email: response }))
  } catch (err) {
    yield put(__internal.validateToken.failed(err))
  }

  yield put(__internal.validateToken.finished())
}

export default function* () {
  yield fork(apiServiceAuthErrorTask)

  yield takeLatest(signIn, signInTask)
  yield takeLatest(signUp, signUpTask)
  yield takeLatest(signOut, signOutTask)
  yield takeLatest(forgotPass, forgotPassTask)
  yield takeLatest(changePass, changePassTask)
  yield takeLatest(validateToken, validateTokenTask)
}
