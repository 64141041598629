import {
  call,
  put,
  putResolve,
  takeLatest,
} from 'redux-saga/effects'

import {
  CodeService,
} from 'services'

import {
  fetchGroups,
  fetchGroupName,
  fetchGroupsByName,
  createGroup,
  editGroup,
  deleteGroup,
  fetchKeys,
  fetchFreeKeys,
  banKeys,
  unbanKeys,
  addUser,
  addKeyToGroup,
  createKeys,

  __internal,
} from './actions'

function* fetchGroupsTask(action) {
  yield put(__internal.fetchGroups.started())

  try {
    const data = yield call([CodeService, 'fetchGroups'], action.payload)

    yield putResolve(__internal.fetchGroups.successed(data))
  } catch (err) {
    yield put(__internal.fetchGroups.failed(err))
  }

  yield put(__internal.fetchGroups.finished())
}

function* fetchKeysTask(action) {
  yield put(__internal.fetchKeys.started())

  try {
    const data = yield call([CodeService, 'fetchKeys'], action.payload)

    yield putResolve(__internal.fetchKeys.successed({ data }))
  } catch (err) {
    yield put(__internal.fetchKeys.failed(err))
  }

  yield put(__internal.fetchKeys.finished())
}

function* fetchGroupNameTask(action) {
  yield put(__internal.fetchGroupName.started())

  try {
    const data = yield call([CodeService, 'fetchGroupName'], action.payload)

    yield putResolve(__internal.fetchGroupName.successed({ data }))
  } catch (err) {
    yield put(__internal.fetchGroupName.failed(err))
  }

  yield put(__internal.fetchGroupName.finished())
}

function* fetchGroupsByNameTask(action) {
  yield put(__internal.fetchGroupsByName.started())

  try {
    const data = yield call([CodeService, 'fetchGroupsByName'], { name: action.payload })

    yield putResolve(__internal.fetchGroupsByName.successed(data.list))
  } catch (err) {
    yield put(__internal.fetchGroupsByName.failed(err))
  }

  yield put(__internal.fetchGroupsByName.finished())
}

function* fetchFreeKeysTask(action) {
  yield put(__internal.fetchFreeKeys.started())

  try {
    const data = yield call([CodeService, 'fetchFreeKeys'], action.payload)

    yield putResolve(__internal.fetchFreeKeys.successed(data))
  } catch (err) {
    yield put(__internal.fetchFreeKeys.failed(err))
  }

  yield put(__internal.fetchFreeKeys.finished())
}

function* createGroupTask(action) {
  const info = {
    name: action.payload.name,
    ...(action.payload.EmployeeId ? { EmployeeId: action.payload.EmployeeId.value } : {}),
    SchoolId: action.payload.SchoolId.value,
  }

  yield put(__internal.createGroup.started())

  try {
    const id = yield call([CodeService, 'createGroup'], info)

    yield putResolve(__internal.createGroup.successed({ ...info, id: id.success }))
  } catch (err) {
    yield put(__internal.createGroup.failed(err))
  }

  yield put(__internal.createGroup.finished())
}

function* createKeysTask(action) {
  const info = {
    ...action.payload,
    SchoolId: action.payload.SchoolId.value,
    EmployeeId: action.payload.EmployeeId.value,
  }

  yield put(__internal.createKeys.started())

  try {
    yield call([CodeService, 'createKeys'], info)

    yield putResolve(__internal.createKeys.successed())
  } catch (err) {
    yield put(__internal.createKeys.failed(err))
  }

  yield put(__internal.createKeys.finished())
}

function* editGroupTask(action) {
  const info = {
    id: action.payload.id,
    name: action.payload.name,
    ...(action.payload.EmployeeId ? { EmployeeId: action.payload.EmployeeId.value } : {}),
    SchoolId: action.payload.SchoolId.value,
  }

  yield put(__internal.editGroup.started())

  try {
    yield call([CodeService, 'editGroup'], info)

    yield putResolve(__internal.editGroup.successed(info))
  } catch (err) {
    yield put(__internal.editGroup.failed(err))
  }

  yield put(__internal.editGroup.finished())
}

function* deleteGroupTask(action) {
  const id = action.payload

  yield put(__internal.deleteGroup.started())

  try {
    yield call([CodeService, 'deleteGroup'], { id: [id] })

    yield putResolve(__internal.deleteGroup.successed(id))
  } catch (err) {
    yield put(__internal.deleteGroup.failed(err))
  }

  yield put(__internal.deleteGroup.finished())
}

function* banKeyTask(action) {
  yield put(__internal.banKey.started())

  try {
    yield call([CodeService, 'banKey'], action.payload.key, action.payload.comment)

    yield putResolve(__internal.banKey.successed())
  } catch (err) {
    yield put(__internal.banKey.failed(err))
  }

  yield put(__internal.banKey.finished())
}

function* unbanKeyTask(action) {
  yield put(__internal.unbanKey.started())

  try {
    yield call([CodeService, 'unbanKey'], action.payload.key, action.payload.comment)

    yield putResolve(__internal.unbanKey.successed())
  } catch (err) {
    yield put(__internal.unbanKey.failed(err))
  }

  yield put(__internal.unbanKey.finished())
}

function* addUserTask(action) {
  const data = {
    key: action.payload.key,
    UserId: action.payload.User.value,
  }

  yield put(__internal.addUser.started())

  try {
    yield call([CodeService, 'addKey'], data)

    yield putResolve(__internal.addUser.successed())
  } catch (err) {
    yield put(__internal.addUser.failed(err))
  }

  yield put(__internal.addUser.finished())
}

function* addKeyToGroupTask(action) {
  const data = {
    codes: [action.payload.id],
    GCodeId: action.payload.GCodeId.value,
  }

  yield put(__internal.addKeyToGroup.started())

  try {
    yield call([CodeService, 'addKeyToGroup'], data)

    yield putResolve(__internal.addKeyToGroup.successed())
  } catch (err) {
    yield put(__internal.addKeyToGroup.failed(err))
  }

  yield put(__internal.addKeyToGroup.finished())
}

export default function* () {
  yield takeLatest(fetchGroups, fetchGroupsTask)
  yield takeLatest(fetchGroupName, fetchGroupNameTask)
  yield takeLatest(fetchGroupsByName, fetchGroupsByNameTask)
  yield takeLatest(createGroup, createGroupTask)
  yield takeLatest(editGroup, editGroupTask)
  yield takeLatest(deleteGroup, deleteGroupTask)
  yield takeLatest(fetchKeys, fetchKeysTask)
  yield takeLatest(fetchFreeKeys, fetchFreeKeysTask)
  yield takeLatest(banKeys, banKeyTask)
  yield takeLatest(unbanKeys, unbanKeyTask)
  yield takeLatest(addUser, addUserTask)
  yield takeLatest(addKeyToGroup, addKeyToGroupTask)
  yield takeLatest(createKeys, createKeysTask)
}
