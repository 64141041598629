import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import cx from 'classnames'

import styles from './styles.module.scss'

export default class Button extends PureComponent {
  render() {
    const {
      className, disabled, children, type, color, size, onClick, textWithEnvColor, filled,
    } = this.props

    const classes = cx(
      styles.button,
      {
        [styles.button_disabled]: disabled,
        [styles['button_text-with-env-color']]: textWithEnvColor,
        [styles.button_filled]: filled,
        [styles[`button_${color}`]]: color,
        [styles[`button_${size}`]]: size,
      },
      className,
    )
    /* eslint-disable react/button-has-type */
    return (
      <button className={ classes } type={ type } onClick={ onClick } disabled={ disabled }>
        { children }
      </button>
    )
    /* eslint-enable react/button-has-type */
  }
}

Button.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  color: PropTypes.oneOf(['green', 'blue', 'white']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  textWithEnvColor: PropTypes.bool,
  filled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
}

Button.defaultProps = {
  className: '',
  disabled: false,
  type: 'button',
  color: 'green',
  size: 'medium',
  onClick: () => {},
  children: null,
  textWithEnvColor: false,
  filled: false,
}
