export default class DistributorService {
  constructor(APIService) {
    this.APIService = APIService
  }

  fetchDistributors(payload) {
    return this.APIService.get('/admin/distributors', payload)
      .then(response => response.data).catch(e => {
        throw e
      })
  }

  fetchBids({
    order, limit, offset, filter,
  }) {
    return this.APIService.get('/distributor/requests', {
      limit, offset, order, filter,
    })
      .then(response => response.data).catch(e => {
        throw e
      })
  }

  appruveBid(id) {
    return this.APIService.post('/admin/code/appruve-request', id)
      .then(response => response.data).catch(e => {
        throw e
      })
  }

  requestBid(info) {
    return this.APIService.post('/request/distributor', info)
      .then(response => response.data).catch(e => {
        throw e
      })
  }

  fetchBidsCount() {
    return this.APIService.get('/distributor/requests')
      .then(response => response.data).catch(e => {
        throw e
      })
  }

  fetchByName(name) {
    return this.APIService.get('/admin/distributors', { filter: { name } })
      .then(response => response.data).catch(e => {
        throw e
      })
  }

  createDistributor(info) {
    return this.APIService.post('/admin/distributor/upsert', info)
      .then(response => response.data)
      .catch(e => {
        throw e
      })
  }

  deleteDistributor(id) {
    return this.APIService.post('/admin/distributor/delete', id)
      .then(response => response.data)
      .catch(e => {
        throw e
      })
  }
}
