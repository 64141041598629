import { createAction } from 'redux-actions'

import { ReduxUtils } from 'utils'

const fetchDialogListActions = ReduxUtils.generateActionCreators('dialog', 'list_fetch', 'request')
const fetchDialogChatActions = ReduxUtils.generateActionCreators('dialog', 'chat_fetch', 'request')
const
  fetchDialogChatGetNewMessageActions = ReduxUtils.generateActionCreators('dialog', 'chat_get_new_message', 'request')
const sendMessageActions = ReduxUtils.generateActionCreators('dialog', 'send_message', 'request')
const fetchTaskActions = ReduxUtils.generateActionCreators('dialog', 'task_fetch', 'request')

const fetchDialogList = ReduxUtils.waitForAction(
  createAction('DIALOG/LIST/FETCH'),
  fetchDialogListActions.successed,
  fetchDialogListActions.failed,
)

const fetchChatList = ReduxUtils.waitForAction(
  createAction('DIALOG/CHAT/FETCH'),
  fetchDialogChatActions.successed,
  fetchDialogChatActions.failed,
)

const fetchChatNewMessage = ReduxUtils.waitForAction(
  createAction('DIALOG/CHAT/FETCH_NEW_MESSAGE'),
  fetchDialogChatGetNewMessageActions.successed,
  fetchDialogChatGetNewMessageActions.failed,
)

const sendMessage = ReduxUtils.waitForAction(
  createAction('DIALOG/CHAT/SEND_MESSAGE'),
  sendMessageActions.successed,
  sendMessageActions.failed,
)

const fetchNewTask = ReduxUtils.waitForAction(
  createAction('DIALOG/TASK/FETCH'),
  fetchTaskActions.successed,
  fetchTaskActions.failed,
)

const dialogInit = createAction('DIALOG/INIT')

const dialogChatInit = createAction('DIALOG/CHAT/INIT')

const currentDialog = createAction('DIALOG/CHAT/CURRENT')

const changeTeacherStatus = createAction('DIALOG/CHAT/TEACHER_CHANGE_STATUS')

const __internal = {
  fetchChatNewMessage: fetchDialogChatGetNewMessageActions,
  fetchDialogList: fetchDialogListActions,
  fetchChatList: fetchDialogChatActions,
  sendMessage: sendMessageActions,
  dialogInit,
  dialogChatInit,
  currentDialog,
  changeTeacherStatus,
  fetchNewTask: fetchTaskActions,
}

export {
  sendMessage,
  fetchChatNewMessage,
  fetchDialogList,
  dialogInit,
  fetchChatList,
  dialogChatInit,
  currentDialog,
  fetchNewTask,
  changeTeacherStatus,

  __internal,
}
