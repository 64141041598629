import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import cx from 'classnames'

import { Field, reduxForm, SubmissionError } from 'redux-form'

import { Actions, Selectors } from 'ducks'
import { FunctionUtils } from 'utils'

import Router from 'components/Router'
import Button from 'components/Button'
import Spinner from 'components/Spinner'

import styles from './styles.module.scss'

class ChangePassword extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
    }
  }

  componentDidMount = async () => {
    const token = this.props.location.search.match(/^\?token\=(.*)/); //eslint-disable-line

    (token === null) ? this.props.push('/') : await this.validateToken(token[1])
  }

  validateToken = async token => {
    try {
      await this.props.validateToken({ token })
    } catch (e) {
      await this.setState({ isLoading: false })
    }
    await this.setState({ isLoading: false })
  }

  requiredInput = input => (!input)

  matchInput = (input, allInputs) => (input === allInputs.password ? false : 'Пароли не совпадают')

  renderInput = ({
    input, label, type, style, meta: { touched, error },
  }) => (
    <div>
      <input
        // eslint-disable-next-line react/jsx-props-no-spreading
        { ...input }
        placeholder={ label }
        type={ type }
        className={ cx(styles.input, style, { [styles.inputError]: touched && error }) }
      />
      {
        touched
        && (typeof error === 'string')
        && <div className={ styles.error }>{ error }</div>
      }
    </div>
  )

  handleOnClick = () => {
    this.props.push('/auth/sign-in')
  }

  renderField = ({
    name, label, className, validate,
  }) => (
    <Field
      name={ name }
      type="password"
      label={ label }
      component={ this.renderInput }
      style={ styles[className] }
      validate={ validate }
    />
  )

  renderText = validToken => {
    const text = validToken ? 'Ваш пароль успешно изменен' : 'Что-то пошло не так'
    return (
      <p className={ styles.textSucceeded }>{ text }</p>
    )
  }

  renderButtonText = validToken => (
    validToken ? 'Войти' : 'ОК'
  )

  renderLink = () => (
    <p className={ styles.link }>
      <Router.Link to="/auth/sign-in" className={ styles.a }>Войдите,</Router.Link> если вспомнили пароль
    </p>
  )

  render() {
    const {
      // eslint-disable-next-line
      error, pristine, submitting, invalid, handleSubmit, submitSucceeded, validToken
    } = this.props

    return (
      <div className={ styles.container }>
        { this.state.isLoading
          ? <Spinner />
          : (
            <div className={ styles.container }>
              <div className={ styles.buttonGroup }>
                <Router.Link to="/auth/forgot">
                  <Button
                    size="medium"
                  >
                    Смена пароля
                  </Button>
                </Router.Link>
              </div>
              <div className={ styles.formContainer }>
                <form onSubmit={ handleSubmit }>
                  { (submitSucceeded || !validToken)
                    ? this.renderText(validToken)
                    : this.renderField({
                      name: 'password',
                      label: 'Новый пароль',
                      validate: [this.requiredInput],
                    })}
                  {
                  (submitSucceeded || !validToken)
                    ? false
                    : this.renderField({
                      name: 'confirmPassword',
                      label: 'Повторите новый пароль',
                      className: 'confirmPassword',
                      validate: [this.requiredInput, this.matchInput],
                    })
                }
                  { error && <div className={ styles.error }>{ error }</div> }
                  <Button
                    className={ styles['auth-button-submit'] }
                    type={ (submitSucceeded || !validToken) ? 'button' : 'submit' }
                    size="large"
                    filled
                    disabled={ submitting || pristine || invalid }
                    onClick={ (submitSucceeded || !validToken) ? this.handleOnClick : undefined }
                  >
                    { (submitSucceeded || !validToken) ? this.renderButtonText(validToken) : 'Сохранить новый пароль' }
                  </Button>
                </form>
                { (submitSucceeded || !validToken) ? false : this.renderLink() }
              </div>
            </div>
          )}
      </div>
    )
  }
}

ChangePassword.propTypes = {
  push: PropTypes.func.isRequired,
  validateToken: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  validToken: Selectors.Auth.validTemporaryToken(state),
  temporaryToken: Selectors.Auth.temporaryToken(state),
})

const mapDispatchToProps = dispatch => bindActionCreators({
  push: Actions.Router.push,
  validateToken: Actions.Auth.validateToken,
  changePass: Actions.Auth.changePass,
}, dispatch)

export default FunctionUtils.compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'ChangePasswordForm',
    onSubmit: (values, dispatch, props) => props.changePass({
      token: props.temporaryToken,
      password: values.confirmPassword,
    })
      .catch(e => {
        throw new SubmissionError({
          _error: 'Ошибка отправки запроса',
        })
      }),
  }),
)(ChangePassword)
