/* eslint-disable react/jsx-props-no-spreading */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import cx from 'classnames'

import { Field, reduxForm, SubmissionError } from 'redux-form'

import { Actions } from 'ducks'
import { FunctionUtils } from 'utils'

import Router from 'components/Router'
import Button from 'components/Button'
// import { ReactComponent as LogoVK } from 'assets/img/icon_vk_logo.svg'
// import { ReactComponent as LogoFB } from 'assets/img/icon_fb_logo.svg'

import styles from './styles.module.scss'

const validate = values => {
  const errors = {}

  if (!values.email) {
    errors.email = 'Заполнены не все поля'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Некорректный электронный адрес'
  }

  if (!values.password) {
    errors.password = 'Заполнены не все поля'
  }

  return errors
}

class SignIn extends PureComponent {
  renderInput = ({
    input, label, type, style, meta: { touched, error },
  }) => (
    <div>
      <input
        { ...input }
        placeholder={ label }
        type={ type }
        className={ cx(styles.input, style, { [styles.inputError]: touched && error }) }
      />
      { touched && error && <div className={ styles.error }>{ error }</div> }
    </div>
  )

  render() {
    const {
    // eslint-disable-next-line react/prop-types
      error, pristine, submitting, invalid, handleSubmit,
    } = this.props

    return (
      <div className={ styles.container }>
        <div className={ styles.buttonGroup }>
          <Router.Link to="/auth/sign-in">
            <Button
              className={ styles['auth-button-in'] }
              size="medium"
              filled
            >
              Войти
            </Button>
          </Router.Link>
          <Router.Link to="/auth/sign-up">
            <Button
              size="medium"
            >
              Создать аккаунт
            </Button>
          </Router.Link>
        </div>
        <div className={ styles.formContainer }>
          <form onSubmit={ handleSubmit }>
            <Field
              name="email"
              type="email"
              label="Электронная почта"
              component={ this.renderInput }
            />
            <Field
              name="password"
              type="password"
              label="Пароль"
              style={ styles.inputPassword }
              component={ this.renderInput }
            />
            { error && <div className={ styles.error }>{ error }</div> }
            <Button
              className={ styles['auth-button-submit'] }
              type="submit"
              size="large"
              filled
              disabled={ submitting || pristine || invalid }
            >
              Войти
            </Button>
          </form>
          <Router.Link to="/auth/forgot" className={ styles.link }>
            Я забыл пароль
          </Router.Link>
          {/* <p className={ styles.text }>
            Войти через соцсети
          </p>
          <div className={ styles.socialButtonGroup }>
            <button
              className={ styles.socialButtonVk }
              type="button"
              onClick={ () => this.handleVkLogin() }
            >
              <LogoVK className={ styles.socialIconVk } />
            </button>
            <button
              className={ styles.socialButtonFb }
              type="button"
              onClick={ () => this.handleFbLogin() }
            >
              <LogoFB className={ styles.socialIconFb } />
            </button>
          </div> */}
        </div>
      </div>
    )
  }
}

SignIn.propTypes = {
  push: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
}

const mapDispatchToProps = dispatch => bindActionCreators({
  push: Actions.Router.push,
  signIn: Actions.Auth.signIn,
}, dispatch)

export default FunctionUtils.compose(
  connect(null, mapDispatchToProps),
  reduxForm({
    form: 'LogInForm',
    validate,
    onSubmit: (values, dispatch, props) => props.signIn(values)
      .catch(e => {
        throw new SubmissionError({
          _error: 'Неверные данные',
        })
      }),
  }),
)(SignIn)
