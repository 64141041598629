import { createAction } from 'redux-actions'

import { ReduxUtils } from 'utils'

const initActions = ReduxUtils.generateActionCreators('app', 'init')

const init = ReduxUtils.waitForAction(
  createAction('APP/INIT'),
  initActions.successed,
  initActions.failed,
)

const setLocale = createAction('APP/SET_LOCALE')

const __internal = {
  init: initActions,
}

export {
  init,
  setLocale,

  __internal,
}
