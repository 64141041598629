import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Menu, { SubMenu, MenuItem as Item } from 'rc-menu'

import { Actions, Selectors } from 'ducks'
import { FunctionUtils } from 'utils'

import MenuItem from 'components/MenuItem'

import styles from './styles.module.scss'
import 'rc-menu/assets/index.css'

class Sidebar extends PureComponent {
  componentDidMount() {
    if (this.props.role !== ('distributor' || 'teacher')) {
      this.props.fetchTeachersBids()
      this.props.fetchInstitutionsBids()
      this.props.fetchFeedbackBids()
    }
    this.props.fetchDistributorsBids()
  }

  renderExpertMenu = () => (
    <div>
      <MenuItem route="/dialog" label="Диалоги" iconName="icon_dialog" />
      <MenuItem route="/archive" label="Архив" iconName="icon_archive" className={ styles.dis } />
    </div>
  )

  renderDistributorMenu = () => (
    <div>
      <MenuItem route="/codes" label="Промокоды" iconName="icon_keys" />
      <MenuItem route="/bids-distributor" label="Мои заявки" iconName="icon_bid" />
    </div>
  )

  renderAdminMenu = () => (
    <div>
      <MenuItem route="/dialog" label="Диалоги" iconName="icon_dialog" />
      <MenuItem route="/archive" label="Архив" iconName="icon_archive" className={ styles.dis } />
      <Menu className={ styles.analyticsMenu }>
        <SubMenu
          title={ (
            <MenuItem
              label="Аналитика"
              iconName="icon_analytics"
              className={ styles.bids }
            />
          ) }
        >
          <Item key="teacher">
            <MenuItem
              route="/statistics-installs"
              label="Установки"
              iconName="icon_analytics"
              className={ styles.bid }
            />
          </Item>
        </SubMenu>
      </Menu>
      <p className={ styles.catalog }>СПРАВОЧНИКИ</p>
      <MenuItem route="/users" label="Учащиеся" iconName="icon_users" />
      <MenuItem route="/institutions" label="Образовательные учреждения" iconName="icon_institution" />
      <MenuItem route="/experts" label="Эксперты" iconName="icon_experts" />
      <MenuItem route="/codes" label="Промокоды" iconName="icon_keys" />
      <MenuItem route="/distributors" label="Дистрибьюторы" iconName="icon_distributors" />
      <MenuItem route="/news" label="Новости" iconName="icon_news" />
      <Menu className={ styles.bidsMenu }>
        <SubMenu
          title={ (
            <MenuItem
              label="Заявки"
              iconName="icon_bid"
              badge
              badgeCount={ this.props.teachersBids
              + this.props.institutionsBids
              + this.props.feedbackBids
              + this.props.distributorsBids }
              className={ styles.bids }
            />
          ) }
        >
          <Item key="teacher">
            <MenuItem
              route="/bids-teacher"
              label="Заявки учителей"
              iconName="icon_bid"
              badge
              badgeCount={ this.props.teachersBids }
              className={ styles.bid }
            />
          </Item>
          <Item key="institution">
            <MenuItem
              route="/bids-institution"
              label="Заявки ОУ"
              iconName="icon_bid"
              badge
              badgeCount={ this.props.institutionsBids }
            />
          </Item>
          <Item key="feedback">
            <MenuItem
              route="/bids-feedback"
              label="Заявки ТП"
              iconName="icon_bid"
              badge
              badgeCount={ this.props.feedbackBids }
            />
          </Item>
          <Item key="distributor">
            <MenuItem
              route="/bids-distributor"
              label="Заявки дистрибьюторов"
              iconName="icon_bid"
              badge
              badgeCount={ this.props.distributorsBids }
            />
          </Item>
        </SubMenu>
      </Menu>
    </div>
  )

  // eslint-disable-next-line consistent-return
  renderSidebar = role => {
    switch (role) {
      case 'admin':
        return this.renderAdminMenu()

      case 'teacher':
        return this.renderExpertMenu()

      case 'distributor':
        return this.renderDistributorMenu()

      default:
        break
    }
  }

  render() {
    return (
      <div className={ styles.sidebar }>
        { this.renderSidebar(this.props.role) }
      </div>
    )
  }
}

Sidebar.propTypes = {
  role: PropTypes.string.isRequired,

  fetchFeedbackBids: PropTypes.func.isRequired,
  fetchTeachersBids: PropTypes.func.isRequired,
  fetchInstitutionsBids: PropTypes.func.isRequired,
  fetchDistributorsBids: PropTypes.func.isRequired,

  feedbackBids: PropTypes.number,
  teachersBids: PropTypes.number,
  institutionsBids: PropTypes.number,
  distributorsBids: PropTypes.number,
}
Sidebar.defaultProps = {
  teachersBids: 0,
  institutionsBids: 0,
  feedbackBids: 0,
  distributorsBids: 0,
}

const mapStateToProps = state => ({
  role: Selectors.Auth.role(state),
  feedbackBids: Selectors.Feedback.countBids(state),
  teachersBids: Selectors.Teachers.countBids(state),
  institutionsBids: Selectors.Institutions.countBids(state),
  distributorsBids: Selectors.Distributors.countBids(state),
})

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchFeedbackBids: Actions.Feedback.fetchBidsCount,
  fetchTeachersBids: Actions.Teachers.fetchBidsCount,
  fetchInstitutionsBids: Actions.Institutions.fetchBidsCount,
  fetchDistributorsBids: Actions.Distributors.fetchBidsCount,
}, dispatch)

export default FunctionUtils.compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Sidebar)
