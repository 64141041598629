export default class TeacherService {
  constructor(APIService) {
    this.APIService = APIService
  }

  bid(info) {
    return this.APIService.post('/request/teacher', info)
      .then(response => response.data)
      .catch(e => {
        throw e
      })
  }

  fetchBids(payload) {
    return this.APIService.get('/admin/request/teachers', payload)
      .then(response => response.data).catch(e => {
        throw e
      })
  }

  appruveBid(id) {
    return this.APIService.post('/admin/request/appruve-teacher', id)
      .then(response => response.data).catch(e => {
        throw e
      })
  }

  fetchBidsCount() {
    return this.APIService.get('/admin/request/teachers')
      .then(response => response.data).catch(e => {
        throw e
      })
  }

  fetchExpert(payload) {
    return this.APIService.get('/admin/teachers', payload)
      .then(response => response.data).catch(e => {
        throw e
      })
  }

  createExpert(info) {
    return this.APIService.post('/admin/teacher/upsert', info)
      .then(response => response.data)
      .catch(e => {
        throw e
      })
  }

  deleteExpert(id) {
    return this.APIService.post('/admin/teacher/delete', id)
      .then(response => response.data)
      .catch(e => {
        throw e
      })
  }
}
