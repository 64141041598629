import { combineActions, handleActions } from 'redux-actions'
import { persistReducer } from 'redux-persist'

import storage from 'localforage'

import produce from 'immer'

import {
  __internal,
} from './actions'

const initialState = {
  loading: false,
  isAuthorized: false,
  id: 0,
  accessToken: null,
  role: null,
  temporaryToken: null,
  validTemporaryToken: false,
  sendMail: false,
}

const startedActions = combineActions(
  __internal.signIn.started,
  __internal.signUp.started,
  __internal.signOut.started,
  __internal.forgotPass.started,
  __internal.changePass.started,
)

const finishedActions = combineActions(
  __internal.signIn.finished,
  __internal.signUp.finished,
  __internal.signOut.finished,
  __internal.forgotPass.finished,
  __internal.changePass.finished,
  __internal.validateToken.finished,
)

const reducer = handleActions({
  [startedActions]: state => produce(state, next => {
    next.loading = true
  }),
  [__internal.signIn.successed]: (state, action) => produce(state, next => {
    next.isAuthorized = true
    next.accessToken = action.payload.token
    next.role = action.payload.role
    next.id = action.payload.id
  }),
  [__internal.signOut.successed]: state => produce(state, next => {
    next.isAuthorized = false
    next.accessToken = null
  }),
  [__internal.forgotPass.successed]: (state, action) => produce(state, next => {
    next.sendMail = action.payload
  }),
  [__internal.changePass.successed]: (state, action) => produce(state, next => {
    next.sendMail = false
  }),
  [__internal.validateToken.started]: (state, action) => produce(state, next => {
    next.validTemporaryToken = false
    next.temporaryToken = null
  }),
  [__internal.validateToken.successed]: (state, action) => produce(state, next => {
    next.validTemporaryToken = true
    next.temporaryToken = action.payload.token
    next.sendMail = action.payload.email
  }),
  [__internal.validateToken.failed]: state => produce(state, next => {
    next.validTemporaryToken = false
    next.temporaryToken = null
    next.sendMail = false
  }),
  [finishedActions]: state => produce(state, next => {
    next.loading = false
  }),
}, initialState)

export default persistReducer({
  key: 'auth',
  storage,
}, reducer)
