import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { fromQueryString, toQueryString } from 'utils/query'

import { Actions, Selectors } from 'ducks'
import { FunctionUtils } from 'utils'

import Spinner from 'components/Spinner'
import Table from 'components/Table'

import cx from 'classnames'

import styles from './styles.module.scss'
import 'react-virtualized/styles.css'

class Installs extends PureComponent {
  constructor(props) {
    super(props)

    const query = (this.props.location && this.props.location.search)
      ? fromQueryString(this.props.location.search)
      : {
        limit: 10,
        offset: 0,
        order: {
          field: 'id',
          direct: 'DESC',
        },
      }

    this.state = {
      COLUMN: [
        {
          name: 'Платформа',
          dataKey: 'from',
          flexGrow: 1,
          className: styles.id,
        },
        {
          name: 'Количество установок',
          dataKey: 'count',
          flexGrow: 1,
          className: styles.id,
        },
      ],
      fetchParams: {
        limit: query.limit || 10,
        offset: query.offset || 0,
        order: {
          field: (query.order && query.order.field) ? query.order.field : 'id',
          direct: (query.order && query.order.direct) ? query.order.direct : 'DESC',
        },
        filter: query.filter || undefined,
      },
      tableParams: {
        limit: query.limit || 10,
        offset: query.offset || 0,
        field: (query.order && query.order.field) ? query.order.field : 'id',
        direct: (query.order && query.order.direct) ? query.order.direct : 'DESC',
      },
    }
  }

  componentDidMount = async () => {
    await this.props.fetchInstalls(this.state.fetchParams)

    this.props.history.replace({
      pathname: this.props.location.pathname,
      search: toQueryString(this.state.fetchParams),
    })
  }

  onTableUpdate = tableState => {
    if (tableState) {
      const fetchParams = {
        ...this.state.fetchParams,
      }

      if (typeof tableState.limit === 'number') {
        fetchParams.limit = tableState.limit
      }
      if (typeof tableState.offset === 'number') {
        fetchParams.offset = tableState.offset
      }
      if (tableState.field) {
        fetchParams.order.field = tableState.field
      }
      if (tableState.direct) {
        fetchParams.order.direct = tableState.direct
      }

      this.props.fetchInstalls(fetchParams)

      this.props.history.replace({
        pathname: this.props.location.pathname,
        search: toQueryString(fetchParams),
      })

      this.setState(prevState => ({
        fetchParams: {
          ...fetchParams,
        },
        tableParams: {
          ...prevState.tableParams,
          ...tableState,
        },
      }))
    }
  }

  renderTable = () => (
    <div className={ styles.AutoSizeWrapper }>
      <Table
        className={ styles.table }
        headerClassName={ styles.header }
        columns={ this.state.COLUMN }
        list={ this.props.list }
        params={ this.state.tableParams }
        onTableUpdate={ this.onTableUpdate }
        showPagination={ false }
      />
    </div>
  )

  render() {
    return (
      <div className={ cx(styles.page, { [styles.spinner]: this.props.isLoading }) }>
        {this.props.isLoading ? <Spinner /> : (
          <div className={ styles.container }>
            <div className={ styles.header }>
              <div className={ styles.label }>
                Установки
              </div>
            </div>
            {this.renderTable()}
          </div>
        )}
      </div>
    )
  }
}

Installs.propTypes = {
  fetchInstalls: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.object,
  ).isRequired,
  location: PropTypes.object.isRequired, //eslint-disable-line
  history: PropTypes.object.isRequired, //eslint-disable-line
}

const mapStateToProps = state => ({
  isLoading: Selectors.Statistics.isLoading(state),
  list: Selectors.Statistics.list(state),
})

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchInstalls: Actions.Statistics.fetchInstalls,
}, dispatch)

export default FunctionUtils.compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Installs)
