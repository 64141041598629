import { createAction } from 'redux-actions'

import { ReduxUtils } from 'utils'

const bidActions = ReduxUtils.generateActionCreators('requestform', 'institution', 'request')
const feedbackActions = ReduxUtils.generateActionCreators('requestform', 'feedback', 'request')
const teacherActions = ReduxUtils.generateActionCreators('requestform', 'teacher', 'request')

const bid = ReduxUtils.waitForAction(
  createAction('REQUESTFORM/INSTITUTION'),
  bidActions.successed,
  bidActions.failed,
)

const feedback = ReduxUtils.waitForAction(
  createAction('REQUESTFORM/FEEDBACK'),
  feedbackActions.successed,
  feedbackActions.failed,
)

const teacher = ReduxUtils.waitForAction(
  createAction('REQUESTFORM/TEACHER'),
  teacherActions.successed,
  teacherActions.failed,
)

const __internal = {
  bid: bidActions,
  feedback: feedbackActions,
  teacher: teacherActions,
}

export {
  bid,
  feedback,
  teacher,

  __internal,
}
