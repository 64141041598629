import { eventChannel } from 'redux-saga'

import {
  call,
  cancelled,
  fork,
  take,
} from 'redux-saga/effects'

import { history } from 'app/history'

function createRouteHistoryChannel() {
  return eventChannel(emitter => {
    const unsubscribe = history.listen((location, action) => {
      emitter({
        location,
        action,
      })
    })

    return () => {
      if (typeof unsubscribe === 'function') {
        unsubscribe()
      }
    }
  })
}

function* routeChangeTask() {
  const routeHistoryChannel = yield call(createRouteHistoryChannel)

  try {
    while (true) {
      yield take(routeHistoryChannel)

      if (window && typeof window.scrollTo === 'function') {
        window.scrollTo(0, 0)
      }
    }
  } finally {
    if (yield cancelled()) {
      routeHistoryChannel.close()
    }
  }
}

export default function* () {
  yield fork(routeChangeTask)
}
