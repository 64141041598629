import { combineActions, handleActions } from 'redux-actions'

import produce from 'immer'

import {
  __internal,
} from './actions'

const initialState = {
  loading: false,

  list: [],
  count: 0,

  bids: [],
  countBids: 0,

  autocompleteList: [],
}

const startedActions = combineActions(
  __internal.fetch.started,
  __internal.fetchBids.started,
  __internal.fetchBidsCount.started,
  __internal.fetchByName.started,
  __internal.create.started,
  __internal.del.started,
  __internal.edit.started,
  __internal.appruveBid.started,
  __internal.requestBid.started,
)

const finishedActions = combineActions(
  __internal.fetch.finished,
  __internal.fetchBids.finished,
  __internal.fetchBidsCount.finished,
  __internal.fetchByName.finished,
  __internal.create.finished,
  __internal.del.finished,
  __internal.edit.finished,
  __internal.appruveBid.finished,
  __internal.requestBid.finished,
)

const reducer = handleActions({
  [startedActions]: state => produce(state, next => {
    next.loading = true
  }),
  [__internal.fetch.successed]: (state, action) => produce(state, next => {
    next.list = action.payload.list
    next.count = action.payload.count
  }),
  [__internal.fetchBids.successed]: (state, action) => produce(state, next => {
    next.bids = action.payload.list
    next.countBids = action.payload.count
  }),
  [__internal.fetchBidsCount.successed]: (state, action) => produce(state, next => {
    next.countBids = action.payload.count
  }),
  [__internal.fetchByName.successed]: (state, action) => produce(state, next => {
    next.autocompleteList = action.payload
  }),
  [finishedActions]: state => produce(state, next => {
    next.loading = false
  }),
}, initialState)

export default reducer
