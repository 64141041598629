import React from 'react'

import Workspace from 'containers/Workspace'

import Header from 'containers/Header'
import Auth from 'pages/Auth'
import AddInstitution from 'pages/Institutions/Bid'
import NotFound from 'pages/404'
import Feedback from 'pages/Feedback'

import Router from 'components/Router'

import styles from './styles.module.scss'

const Routing = ({ history }) => (
  <Router.ReduxAdapter history={ history }>
    <div className={ styles.index }>
      <Header />
      <div className={ styles.body }>
        <Router.Switch>
          <Router.Route path="/auth" component={ Auth } />
          <Router.Route exact path="/institution" component={ AddInstitution } />
          <Router.Route exact path="/feedback" component={ Feedback } />
          <Router.Route.Protected path="/" component={ Workspace } roles={ ['admin', 'distributor', 'teacher'] } />
          <Router.Route exact path="/404" component={ NotFound } />
          <Router.Redirect to="/404" />
        </Router.Switch>
      </div>
    </div>
  </Router.ReduxAdapter>
)

Routing.propTypes = {
  history: Router.Types.History.isRequired,
}

export default Routing
