import { createAction } from 'redux-actions'

import { ReduxUtils } from 'utils'

const fetchInstallsActions = ReduxUtils.generateActionCreators('statistics', 'installs', 'request')

const fetchInstalls = ReduxUtils.waitForAction(
  createAction('STATISTICS/INSTALLS'),
  fetchInstallsActions.successed,
  fetchInstallsActions.failed,
)

const __internal = {
  fetchInstalls: fetchInstallsActions,
}

export {
  fetchInstalls,

  __internal,
}
