export default class InstitutionService {
  constructor(APIService) {
    this.APIService = APIService
  }

  bid(info) {
    return this.APIService.post('/request/school', info)
      .then(response => response.data)
      .catch(e => {
        throw e
      })
  }

  appruveBid(id) {
    return this.APIService.post('/admin/request/appruve-school', id)
      .then(response => response.data).catch(e => {
        throw e
      })
  }

  fetchInstitutions({
    order, limit, offset, filter,
  }) {
    return this.APIService.get('/admin/schools', {
      limit, offset, order, filter,
    })
      .then(response => response.data).catch(e => {
        throw e
      })
  }

  fetchById(id) {
    return this.APIService.get('/admin/schools', { filter: { id } })
      .then(response => response.data).catch(e => {
        throw e
      })
  }

  fetchByName(name) {
    return this.APIService.get('/admin/schools', { filter: { name } })
      .then(response => response.data).catch(e => {
        throw e
      })
  }

  fetchBids(payload) {
    return this.APIService.get('/admin/request/schools', payload)
      .then(response => response.data).catch(e => {
        throw e
      })
  }

  fetchBidsCount() {
    return this.APIService.get('/admin/request/schools')
      .then(response => response.data).catch(e => {
        throw e
      })
  }

  create(info) {
    return this.APIService.post('/admin/school/upsert', info)
      .then(response => response.data)
      .catch(e => {
        throw e
      })
  }

  delete(id) {
    return this.APIService.post('/admin/school/delete', id)
      .then(response => response.data)
      .catch(e => {
        throw e
      })
  }
}
