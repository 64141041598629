import { createAction } from 'redux-actions'

import { ReduxUtils } from 'utils'

const fetchActions = ReduxUtils.generateActionCreators('teacher', 'fetch', 'request')
const fetchBidsCountActions = ReduxUtils.generateActionCreators('teacher', 'fetch_bidsCount', 'request')
const bidsActions = ReduxUtils.generateActionCreators('teacher', 'bids', 'request')
const appruveBidActions = ReduxUtils.generateActionCreators('teacher', 'appruve_bid', 'request')
const createActions = ReduxUtils.generateActionCreators('teacher', 'create', 'request')
const deleteActions = ReduxUtils.generateActionCreators('teacher', 'delete', 'request')
const editActions = ReduxUtils.generateActionCreators('teacher', 'edit', 'request')

const fetch = ReduxUtils.waitForAction(
  createAction('TEACHER/FETCH'),
  fetchActions.successed,
  fetchActions.failed,
)

const fetchBidsCount = ReduxUtils.waitForAction(
  createAction('TEACHER/FETCH_BIDSCOUNT'),
  fetchBidsCountActions.successed,
  fetchBidsCountActions.failed,
)

const bids = ReduxUtils.waitForAction(
  createAction('TEACHER/BIDS'),
  bidsActions.successed,
  bidsActions.failed,
)

const appruveBid = ReduxUtils.waitForAction(
  createAction('TEACHER/APPRUVE_BID'),
  appruveBidActions.successed,
  appruveBidActions.failed,
)

const create = ReduxUtils.waitForAction(
  createAction('TEACHER/CREATE'),
  createActions.successed,
  createActions.failed,
)

const del = ReduxUtils.waitForAction(
  createAction('TEACHER/DELETE'),
  deleteActions.successed,
  deleteActions.failed,
)

const edit = ReduxUtils.waitForAction(
  createAction('TEACHER/EDIT'),
  editActions.successed,
  editActions.failed,
)

const __internal = {
  fetch: fetchActions,
  fetchBidsCount: fetchBidsCountActions,
  bids: bidsActions,
  appruveBid: appruveBidActions,
  create: createActions,
  del: deleteActions,
  edit: editActions,
}

export {
  fetch,
  fetchBidsCount,
  bids,
  appruveBid,
  create,
  del,
  edit,

  __internal,
}
