import { combineActions, handleActions } from 'redux-actions'

import produce from 'immer'

import {
  __internal,
} from './actions'

const initialState = {
  loading: false,
  loadingKeys: false,

  list: [],
  count: 0,

  groupKeys: [],
  groupKeysCount: 0,
  freeKeys: [],
  freeKeysCount: 0,
  groupName: '',
  groupID: null,

  autocompleteList: [],
}

const startedGroupActions = combineActions(
  __internal.fetchGroups.started,
  __internal.createGroup.started,
  __internal.editGroup.started,
  __internal.deleteGroup.started,
)

const finishedGroupActions = combineActions(
  __internal.fetchGroups.finished,
  __internal.createGroup.finished,
  __internal.editGroup.finished,
  __internal.deleteGroup.finished,
)

const startedKeysActions = combineActions(
  __internal.fetchKeys.started,
  __internal.fetchGroupName.started,
  __internal.fetchFreeKeys.started,
  __internal.banKey.started,
  __internal.unbanKey.started,
  __internal.addUser.started,
  __internal.addKeyToGroup.started,
  __internal.createKeys.started,
)

const finishedKeysActions = combineActions(
  __internal.fetchKeys.finished,
  __internal.fetchGroupName.finished,
  __internal.fetchFreeKeys.finished,
  __internal.banKey.finished,
  __internal.unbanKey.finished,
  __internal.addUser.finished,
  __internal.addKeyToGroup.finished,
  __internal.createKeys.finished,
)

const reducer = handleActions({
  [startedGroupActions]: state => produce(state, next => {
    next.loading = true
  }),
  [startedKeysActions]: state => produce(state, next => {
    next.loadingKeys = true
  }),
  [__internal.fetchGroups.successed]: (state, action) => produce(state, next => {
    next.list = action.payload.list
    next.count = action.payload.count
  }),
  [__internal.fetchGroupsByName.successed]: (state, action) => produce(state, next => {
    next.autocompleteList = action.payload
  }),
  [__internal.fetchKeys.successed]: (state, action) => produce(state, next => {
    next.groupKeys = action.payload.data.list
    next.groupKeysCount = action.payload.data.count
  }),
  [__internal.fetchGroupName.successed]: (state, action) => produce(state, next => {
    next.groupName = action.payload.data.info.name
    next.groupID = action.payload.data.info.id
  }),
  [__internal.fetchFreeKeys.successed]: (state, action) => produce(state, next => {
    next.freeKeys = action.payload.list
    next.freeKeysCount = action.payload.count
  }),
  [finishedGroupActions]: state => produce(state, next => {
    next.loading = false
  }),
  [finishedKeysActions]: state => produce(state, next => {
    next.loadingKeys = false
  }),
}, initialState)

export default reducer
