import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { fromQueryString, toQueryString } from 'utils/query'

import { Actions, Selectors } from 'ducks'
import { FunctionUtils } from 'utils'

import Spinner from 'components/Spinner'
import Table from 'components/Table'
import Modal from 'components/Modal'
import Form from 'components/Form'
import FilterForm from 'components/Form/FilterForm'
import Svg from 'components/Svg'

import cx from 'classnames'

import styles from './styles.module.scss'
import 'react-virtualized/styles.css'

const monthOption = [
  { value: 0, label: 'Январь' },
  { value: 1, label: 'Февраль' },
  { value: 2, label: 'Март' },
  { value: 3, label: 'Апрель' },
  { value: 4, label: 'Май' },
  { value: 5, label: 'Июнь' },
  { value: 6, label: 'Июль' },
  { value: 7, label: 'Август' },
  { value: 8, label: 'Сентябрь' },
  { value: 9, label: 'Октябрь' },
  { value: 10, label: 'Ноябрь' },
  { value: 11, label: 'Декабрь' },
]

const socialOptions = [{ value: 'fb', label: 'Facebook' }, { value: 'vk', label: 'Vk' }]

class Students extends PureComponent {
  constructor(props) {
    super(props)

    const query = (this.props.location && this.props.location.search)
      ? fromQueryString(this.props.location.search)
      : {
        limit: 10,
        offset: 0,
        order: {
          field: 'id',
          direct: 'DESC',
        },
      }

    this.state = {
      COLUMN: [
        {
          dataKey: null,
          maxWidth: 25,
          className: styles.id,
          headerRenderer: () => (
            <div className={ cx(styles.firstColumn, styles.firstColumnHeader) }>
              <Svg name="icon_edit" />
            </div>
          ),
          cellRenderer: ({ rowData }) => (
            <div className={ cx(styles.firstColumn, styles.firstColumnCell) }>
              <Svg
                name="icon_edit"
                className={ styles.editIcon }
                onClick={ () => this.makeEditFormValue(rowData) }
              />
            </div>
          ),
        },
        {
          name: 'ID',
          dataKey: 'id',
          maxWidth: 35,
          className: styles.id,
        },
        {
          name: 'ФИО',
          dataKey: 'name',
          flexGrow: 1,
          maxWidth: 400,
          cellDataGetter: ({ rowData }) => rowData.name.full,
        },
        {
          name: 'Дата рождения, возраст',
          dataKey: 'bdate',
          cellDataGetter: ({ rowData }) => rowData.age,
          cellRenderer: ({ rowData }) => `${new Date(rowData.bdate).toLocaleDateString('ru-RU')}(${rowData.age})`,
          maxWidth: 100,
        },
        {
          name: 'Страна',
          dataKey: null,
          maxWidth: 120,
          cellDataGetter: ({ rowData }) => rowData.Code,
          cellRenderer: ({ cellData }) => ((cellData && cellData.GCode && cellData.GCode.School)
            ? cellData.GCode.School.Country.name
            : null),
        },
        {
          name: 'Город',
          dataKey: null,
          maxWidth: 120,
          cellDataGetter: ({ rowData }) => rowData.Code,
          cellRenderer: ({ cellData }) => ((cellData && cellData.GCode && cellData.GCode.School)
            ? cellData.GCode.School.City.name
            : null),
        },
        {
          name: 'Образовательное учреждение',
          dataKey: null,
          flexGrow: 1,
          cellDataGetter: ({ rowData }) => rowData.Code,
          cellRenderer: ({ cellData }) => ((cellData && cellData.GCode && cellData.GCode.School)
            ? cellData.GCode.School.name
            : null),
        },
        {
          name: 'Код активации',
          dataKey: null,
          minWidth: 250,
          flexGrow: 1,
          cellDataGetter: ({ rowData }) => ((rowData.Code) ? rowData.Code.key : 'Нет ключа'),
          className: styles.keyColumn,
        },
        {
          name: 'Статус кода',
          dataKey: null,
          maxWidth: 85,
          className: styles.centerText,
          cellDataGetter: ({ rowData }) => rowData.Code,
          cellRenderer: ({ cellData }) => {
            if (cellData) {
              switch (cellData.status) {
                case 'inactive':
                  return 'Не активен'
                case 'active':
                  return 'Активен'
                case 'expired':
                  return 'Истек срок'
                case 'banned':
                  return 'Забанен'

                default:
                  return null
              }
            } else return ''
          },
        },
        {
          name: 'Соцсеть',
          dataKey: null,
          maxWidth: 60,
          className: styles.socialColumn,
          cellDataGetter: ({ rowData }) => rowData.social,
          cellRenderer: ({ rowData }) => (
            <div className={ styles.socialCell }>
              {(rowData.social === 'vk')
                ? (
                  <a href={ rowData.link } className={ styles.vklLink }>
                    <Svg name="icon_vk" className={ styles.vkIcon } />
                  </a>
                )
                : (
                  <a href={ rowData.link } className={ styles.fbLink }>
                    <Svg name="icon_fb" className={ styles.fbIcon } />
                  </a>
                )}
            </div>
          ),
        },
        {
          name: 'Бан/разбан',
          dataKey: null,
          maxWidth: 50,
          className: styles.id,
          cellRenderer: ({ rowData }) => (rowData.Code
            ? (
              <div className={ cx(styles.firstColumn, styles.firstColumnCell) }>
                {this.renderBanColumn(rowData)}
              </div>
            )
            : null),
        },
      ],
      BLOCK_FIELDS: [
        {
          name: 'id',
          label: 'ID Студента',
          component: 'input',
          disabled: true,
        },
        {
          name: 'key',
          label: 'Ключ',
          component: 'input',
          disabled: true,
        },
        {
          name: 'comment',
          label: 'Сообщение',
          component: 'textarea',
          validate: ['requiredField'],
        },
      ],
      EDIT_FIELDS: [
        {
          name: 'id',
          label: 'ID',
          component: 'input',
          disabled: true,
        },
        {
          name: 'name',
          label: 'ФИО',
          component: 'input',
          validate: ['requiredField'],
        },
        {
          name: 'year',
          label: 'Год рождения',
          component: 'input',
          type: 'number',
          validate: ['requiredField', 'validateYear'],
        },
        {
          name: 'month',
          label: 'Месяц рождения',
          component: 'select',
          options: monthOption,
          type: 'number',
          validate: ['requiredField'],
        },
        {
          name: 'day',
          label: 'День рождения',
          component: 'input',
          type: 'number',
          validate: ['requiredField', 'validateDate'],
        },
      ],
      FILTER_FIELDS: [
        {
          name: 'id',
          label: 'ID Студента',
          component: 'input',
          type: 'number',
        },
        {
          name: 'name',
          label: 'Имя',
          component: 'input',
        },
        {
          name: 'bdate',
          label: 'День рождения',
          component: 'input',
          placeholder: 'ГГГГ-ММ-ДД',
          validate: ['validateFullDate'],
        },
        {
          name: 'school_id',
          label: 'ID Школы',
          component: 'input',
          type: 'number',
        },
        {
          name: 'social',
          label: 'Соц. Сеть',
          component: 'select',
          options: socialOptions,
        },
      ],
      showModal: false,
      modalType: '',
      initialValues: {},
      fetchParams: {
        limit: query.limit || 10,
        offset: query.offset || 0,
        order: {
          field: (query.order && query.order.field) ? query.order.field : 'id',
          direct: (query.order && query.order.direct) ? query.order.direct : 'DESC',
        },
        filter: query.filter || undefined,
      },
      tableParams: {
        limit: query.limit || 10,
        offset: query.offset || 0,
        field: (query.order && query.order.field) ? query.order.field : 'id',
        direct: (query.order && query.order.direct) ? query.order.direct : 'DESC',
      },
    }
  }

  componentDidMount = async () => {
    await this.props.fetchStudents(this.state.fetchParams)

    this.props.history.replace({
      pathname: this.props.location.pathname,
      search: toQueryString(this.state.fetchParams),
    })
  }

  onTableUpdate = tableState => {
    if (tableState) {
      const fetchParams = {
        ...this.state.fetchParams,
      }

      if (typeof tableState.limit === 'number') {
        fetchParams.limit = tableState.limit
      }
      if (typeof tableState.offset === 'number') {
        fetchParams.offset = tableState.offset
      }
      if (tableState.field) {
        fetchParams.order.field = tableState.field
      }
      if (tableState.direct) {
        fetchParams.order.direct = tableState.direct
      }

      this.props.fetchStudents(fetchParams)

      this.props.history.replace({
        pathname: this.props.location.pathname,
        search: toQueryString(fetchParams),
      })

      this.setState(prevState => ({
        fetchParams: {
          ...fetchParams,
        },
        tableParams: {
          ...prevState.tableParams,
          ...tableState,
        },
      }))
    }
  }

  renderBanColumn = rowData => {
    switch (rowData.Code.status) {
      case 'active':
        return (
          <Svg
            name="icon_block"
            className={ styles.deleteIcon }
            onClick={ () => this.makeBanFormValue(rowData) }
          />
        )
      case 'banned':
        return (
          <Svg
            name="icon_add"
            className={ styles.addIcon }
            onClick={ () => this.makeUnBanFormValue(rowData) }
          />
        )

      default:
        return null
    }
  }

  renderTable = () => (
    <div className={ styles.AutoSizeWrapper }>
      <Table
        className={ styles.table }
        headerClassName={ styles.header }
        columns={ this.state.COLUMN }
        list={ this.props.list }
        count={ this.props.count }
        params={ this.state.tableParams }
        onTableUpdate={ this.onTableUpdate }
      />
    </div>
  )

  renderKeyBlockForm = fields => (
    <Form
      title="Заблокировать ключ"
      isLoadingSelector={ Selectors.Students.isLoading }
      submittingText="Заблокировать"
      cancelText="Отменить"
      cancelAction={ () => this.setState({ showModal: false }) }
      fields={ fields }
      submitAction={ Actions.Students.banKey }
      initialValues={ this.state.initialValues }
      afterSubmitAction={ this.props.fetchStudents }
      afterSubmitActionArgs={ this.state.fetchParams }
    />
  )

  renderKeyUnBlockForm = fields => (
    <Form
      title="Разблокировать ключ"
      isLoadingSelector={ Selectors.Students.isLoading }
      submittingText="Разблокировать"
      cancelText="Отменить"
      cancelAction={ () => this.setState({ showModal: false }) }
      fields={ fields }
      submitAction={ Actions.Students.unbanKey }
      initialValues={ this.state.initialValues }
      afterSubmitAction={ this.props.fetchStudents }
      afterSubmitActionArgs={ this.state.fetchParams }
    />
  )

  renderEditForm = fields => (
    <Form
      title="Изменение данных учащегося"
      isLoadingSelector={ Selectors.Students.isLoading }
      submittingText="Изменить"
      cancelText="Отменить"
      cancelAction={ () => this.setState({ showModal: false }) }
      fields={ fields }
      submitAction={ Actions.Students.edit }
      initialValues={ this.state.initialValues }
      afterSubmitAction={ this.props.fetchStudents }
      afterSubmitActionArgs={ this.state.fetchParams }
    />
  )

  makeBanFormValue = rowData => {
    this.setState({
      initialValues: {
        id: rowData.id,
        key: rowData.Code.key,
      },
      showModal: true,
      modalType: 'block',
    })
  }

  makeUnBanFormValue = rowData => {
    this.setState({
      initialValues: {
        id: rowData.id,
        key: rowData.Code.key,
        comment: rowData.Code.comment,
      },
      showModal: true,
      modalType: 'unblock',
    })
  }

  makeEditFormValue = rowData => {
    this.setState({
      initialValues: {
        id: rowData.id,
        name: rowData.name.full,
        year: new Date(rowData.bdate).getFullYear(),
        month: monthOption.filter(item => item.value === new Date(rowData.bdate).getMonth()),
        day: new Date(rowData.bdate).getDate(),
      },
      showModal: true,
      modalType: 'edit',
    })
  }

  renderForm = type => {
    switch (type) {
      case 'block':
        return this.renderKeyBlockForm(this.state.BLOCK_FIELDS)
      case 'unblock':
        return this.renderKeyUnBlockForm(this.state.BLOCK_FIELDS)
      case 'edit':
        return this.renderEditForm(this.state.EDIT_FIELDS)
      case 'filter':
        return this.renderFilterForm(this.state.FILTER_FIELDS)

      default:
        return null
    }
  }

  renderModal = () => (
    <Modal isOpen={ this.state.showModal } onRequestClose={ () => this.setState({ showModal: false }) }>
      {this.state.showModal ? this.renderForm(this.state.modalType) : false}
    </Modal>
  )

  showFilterModal = evt => {
    if (evt.type === 'keypress' && evt.key !== 'Enter') {
      return
    }

    this.setState(state => {
      const initialValues = {
        id: (state.fetchParams.filter && state.fetchParams.filter.id)
          ? state.fetchParams.filter.id
          : undefined,
        name: (state.fetchParams.filter && state.fetchParams.filter.name)
          ? state.fetchParams.filter.name
          : undefined,
        social: (state.fetchParams.filter && state.fetchParams.filter.social)
          ? socialOptions.filter(opt => opt.value === state.fetchParams.filter.social)
          : undefined,
        school_id: (state.fetchParams.filter && state.fetchParams.filter.school_id)
          ? state.fetchParams.filter.school_id
          : undefined,
      }

      return {
        initialValues,
        showModal: true,
        modalType: 'filter',
      }
    })
  }

  setFilter = values => {
    this.setState(state => {
      const fetchParams = {
        ...state.fetchParams,
        ...values,
      }

      this.props.history.replace({
        pathname: this.props.location.pathname,
        search: toQueryString(fetchParams),
      })

      return {
        showModal: false,
        fetchParams,
      }
    })
  }

  removeFilter = evt => {
    if (evt.type === 'keypress' && evt.key !== 'Enter') {
      return
    }

    if (!this.state.fetchParams.filter || !Object.keys(this.state.fetchParams.filter).length) {
      return
    }

    this.setState(state => {
      const fetchParams = {
        ...state.fetchParams,
        filter: undefined,
      }

      this.props.fetchStudents(fetchParams)

      this.props.history.replace({
        pathname: this.props.location.pathname,
        search: toQueryString(fetchParams),
      })

      return {
        fetchParams,
      }
    })
  }

  renderFilterForm = fields => (
    <FilterForm
      title="Расширенный поиск"
      isLoadingSelector={ Selectors.Students.isLoading }
      submittingText="Поиск"
      cancelText="Отменить"
      cancelAction={ () => this.setState({ showModal: false }) }
      fields={ fields }
      initialValues={ this.state.initialValues }
      submitAction={ values => Actions.Students.fetch({ ...this.state.fetchParams, ...values }) }
      afterSubmitAction={ this.setFilter }
    />
  )

  render() {
    return (
      <div className={ cx(styles.page, { [styles.spinner]: this.props.isLoading }) }>
        {this.props.isLoading ? <Spinner /> : (
          <div className={ styles.container }>
            <div className={ styles.header }>
              <div className={ styles.label }>
                Учащиеся
              </div>
              <div
                className={ styles.filter }
                role="button"
                onClick={ this.showFilterModal }
                tabIndex={ 0 }
                onKeyPress={ this.showFilterModal }
              >
                <Svg name="icon_filter" />
                <div className={ styles.filter__text }>
                  Расширенный поиск
                </div>
              </div>
              {this.state.fetchParams.filter && (
                <div
                  role="button"
                  onClick={ this.removeFilter }
                  tabIndex={ 0 }
                  onKeyPress={ this.removeFilter }
                >
                  <div className={ styles.filter__remove }>
                    Отчистить фильтр
                  </div>
                </div>
              )}
            </div>
            {this.renderTable()}
          </div>
        )}
        {this.renderModal()}
      </div>
    )
  }
}

Students.propTypes = {
  fetchStudents: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.object,
  ).isRequired,
  count: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired, //eslint-disable-line
  history: PropTypes.object.isRequired, //eslint-disable-line
}

const mapStateToProps = state => ({
  isLoading: Selectors.Students.isLoading(state),
  list: Selectors.Students.list(state),
  count: Selectors.Students.count(state),
})

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchStudents: Actions.Students.fetch,
}, dispatch)

export default FunctionUtils.compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Students)
