export default class CodeService {
  constructor(APIService) {
    this.APIService = APIService
  }

  fetchGroups(payload) {
    return this.APIService.get('/distributor/groups', payload)
      .then(response => response.data).catch(e => {
        throw e
      })
  }

  fetchCSV(GCodeId) {
    return this.APIService.get('/admin/code/csv', { GCodeId })
      .then(response => response).catch(e => {
        throw e
      })
  }

  fetchGroupsByName(name) {
    return this.APIService.get('/distributor/groups', { filter: name, limit: 100 })
      .then(response => response.data).catch(e => {
        throw e
      })
  }

  fetchGroupName(payload) {
    return this.APIService.get('/distributor/gcode/info', payload)
      .then(response => response.data).catch(e => {
        throw e
      })
  }

  fetchKeys({ id, filter, ...args }) {
    return this.APIService.get('/distributor/codes', { filter: { ...filter, GCodeId: id }, ...args })
      .then(response => response.data).catch(e => {
        throw e
      })
  }

  fetchFreeKeys({
    limit, offset, order, filter,
  }) {
    return this.APIService.get('/distributor/codes', {
      filter: { ...filter, GCodeId: null }, limit, offset, order,
    })
      .then(response => response.data).catch(e => {
        throw e
      })
  }

  addKeyToGroup(data) {
    return this.APIService.post('/distributor/gcode/append', data)
      .then(response => response.data).catch(e => {
        throw e
      })
  }

  createGroup(info) {
    return this.APIService.post('/distributor/gcode/upsert', info)
      .then(response => response.data)
      .catch(e => {
        throw e
      })
  }

  createKeys(info) {
    return this.APIService.post('/admin/code/generate', info)
      .then(response => response.data)
      .catch(e => {
        throw e
      })
  }

  editGroup(info) {
    return this.APIService.post('/distributor/gcode/upsert', info)
      .then(response => response.data)
      .catch(e => {
        throw e
      })
  }

  deleteGroup(id) {
    return this.APIService.post('/distributor/gcode/delete', id)
      .then(response => response.data)
      .catch(e => {
        throw e
      })
  }

  banKey(key, comment) {
    return this.APIService.post('/admin/code/ban', { key, comment })
      .then(response => response.data)
      .catch(e => {
        throw e
      })
  }

  unbanKey(key, comment) {
    return this.APIService.post('/admin/code/ban-remove', { key, comment })
      .then(response => response.data)
      .catch(e => {
        throw e
      })
  }

  addKey(data) {
    return this.APIService.post('/app/user/code/activate', data)
      .then(response => response.data)
      .catch(e => {
        throw e
      })
  }
}
