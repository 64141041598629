import { combineReducers } from 'redux'

import {
  all,
  fork,
} from 'redux-saga/effects'

import appReducer, {
  actions as appActions,
  saga as appSaga,
  selectors as appSelectors,
} from './app'

import authReducer, {
  actions as authActions,
  saga as authSaga,
  selectors as authSelectors,
} from './auth'

import routerReducer, {
  actions as routerActions,
  saga as routerSaga,
} from './router'


import requestFormReducer, {
  actions as requestFormActions,
  saga as requestFormSaga,
  selectors as requestFormSelectors,
} from './requestForm'

import feedbackReducer, {
  actions as feedbackActions,
  saga as feedbackSaga,
  selectors as feedbackSelectors,
} from './feedback'

import teachersReducer, {
  actions as teachersActions,
  saga as teachersSaga,
  selectors as teachersSelectors,
} from './teachers'

import studentsReducer, {
  actions as studentsActions,
  saga as studentsSaga,
  selectors as studentsSelectors,
} from './students'

import distributorsReducer, {
  actions as distributorsActions,
  saga as distributorsSaga,
  selectors as distributorsSelectors,
} from './distributors'

import institutionsReducer, {
  actions as institutionsActions,
  saga as institutionsSaga,
  selectors as institutionsSelectors,
} from './institutions'

import codesReducer, {
  actions as codesActions,
  saga as codesSaga,
  selectors as codesSelectors,
} from './code'

import statisticsReducer, {
  actions as statisticsActions,
  saga as statisticsSaga,
  selectors as statisticsSelectors,
} from './statistics'

import dialogReducer, {
  actions as dialogActions,
  saga as dialogSaga,
  selectors as dialogSelectors,
} from './dialog'

import newsReducer, {
  actions as newsActions,
  saga as newsSaga,
  selectors as newsSelectors,
} from './news'

import
socketReducer, {
  actions as socketActions,
  saga as socketSaga,
  selectors as socketSelectors, //eslint-disable-line
} from './socket'

import formReducer from './form'

const reducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  router: routerReducer,
  form: formReducer,
  requestForm: requestFormReducer,
  feedback: feedbackReducer,
  teachers: teachersReducer,
  students: studentsReducer,
  distributors: distributorsReducer,
  institutions: institutionsReducer,
  codes: codesReducer,
  statistics: statisticsReducer,
  dialog: dialogReducer,
  socket: socketReducer,
  news: newsReducer,
})

const actions = {
  App: appActions,
  Auth: authActions,
  Router: routerActions,
  RequestForm: requestFormActions,
  Feedback: feedbackActions,
  Teachers: teachersActions,
  Students: studentsActions,
  Distributors: distributorsActions,
  Institutions: institutionsActions,
  Codes: codesActions,
  Statistics: statisticsActions,
  Dialog: dialogActions,
  Socket: socketActions,
  News: newsActions,
}

const selectors = {
  App: appSelectors,
  Auth: authSelectors,
  RequestForm: requestFormSelectors,
  Feedback: feedbackSelectors,
  Teachers: teachersSelectors,
  Students: studentsSelectors,
  Distributors: distributorsSelectors,
  Institutions: institutionsSelectors,
  Codes: codesSelectors,
  Statistics: statisticsSelectors,
  Dialog: dialogSelectors,
  Socket: socketSelectors,
  News: newsSelectors,
}

function* saga() {
  yield all([
    fork(appSaga),
    fork(authSaga),
    fork(routerSaga),
    fork(requestFormSaga),
    fork(feedbackSaga),
    fork(teachersSaga),
    fork(studentsSaga),
    fork(distributorsSaga),
    fork(institutionsSaga),
    fork(codesSaga),
    fork(statisticsSaga),
    fork(dialogSaga),
    fork(socketSaga),
    fork(newsSaga),
  ])
}

export {
  reducer as default,
  saga,
  selectors as Selectors,
  actions as Actions,
}
