import React, { PureComponent } from 'react'

import Router from 'components/Router'

import Sidebar from 'containers/Sidebar'

import Dialog from 'pages/Dialog'
import Students from 'pages/Students'
import Institutions from 'pages/Institutions'
import Experts from 'pages/Experts'
import Codes from 'pages/Codes'
import Distributors from 'pages/Distributors'
import ExpertBids from 'pages/Bids/Experts'
import InstitutionsBids from 'pages/Bids/Institutions'
import FeedbackBids from 'pages/Bids/Feedbacks'
import DistributorsBids from 'pages/Bids/Distributors'
import News from 'pages/News'
import InstallsStatistics from 'pages/Statistics/Installs'

import NotFound from 'pages/404'
import Main from 'pages/Main'

import styles from './styles.module.scss'

class Workspace extends PureComponent {
  render() {
    return (
      <div className={ styles.workspace }>
        <Sidebar />
        <Router.Switch>
          <Router.Route.Protected path="/dialog" component={ Dialog } roles={ ['admin', 'teacher'] } />
          <Router.Route.Protected path="/users" component={ Students } roles={ ['admin'] } />
          <Router.Route.Protected path="/institutions" component={ Institutions } roles={ ['admin'] } />
          <Router.Route.Protected path="/experts" component={ Experts } roles={ ['admin'] } />
          <Router.Route.Protected path="/codes" component={ Codes } roles={ ['admin', 'distributor'] } />
          <Router.Route.Protected path="/distributors" component={ Distributors } roles={ ['admin'] } />
          <Router.Route.Protected path="/news" component={ News } roles={ ['admin'] } />
          <Router.Route.Protected path="/bids-teacher" component={ ExpertBids } roles={ ['admin'] } />
          <Router.Route.Protected path="/bids-institution" component={ InstitutionsBids } roles={ ['admin'] } />
          <Router.Route.Protected path="/bids-feedback" component={ FeedbackBids } roles={ ['admin'] } />
          <Router.Route.Protected
            path="/bids-distributor"
            component={ DistributorsBids }
            roles={ ['admin', 'distributor'] }
          />
          <Router.Route.Protected path="/statistics-installs" component={ InstallsStatistics } roles={ ['admin'] } />
          <Router.Route exact path="/" component={ Main } />

          <Router.Route exact path="/404" component={ NotFound } />
          <Router.Redirect to="/404" />
        </Router.Switch>
      </div>
    )
  }
}

export default Workspace
