export default class StatisticService {
  constructor(APIService) {
    this.APIService = APIService
  }

  fetchInstalls() {
    return this.APIService.get('/statistic/installs')
      .then(response => response.data).catch(e => {
        throw e
      })
  }
}
