import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

import Router from 'components/Router'
import Svg from 'components/Svg'

import cx from 'classnames'

import styles from './styles.module.scss'

class MenuItem extends PureComponent {
  isActive = () => ((this.props.location.pathname === this.props.route))

  render() {
    const {
      className, children, label, route, badge, badgeCount, iconName,
    } = this.props

    const classes = cx(
      styles.item,
      className,
    )

    if (!this.props.route || this.isActive()) {
      const classesWithoutLink = cx(
        classes,
        styles.item__nolink,
      )
      return (
        <div className={ classesWithoutLink }>
          <div className={ cx(styles.icon, { [styles.icon_active]: this.isActive() }) }>
            <Svg name={ iconName } />
          </div>
          <p className={ cx(styles.text, { [styles.text_active]: this.isActive() }) }>{label}</p>
          {badge ? (
            <p className={ styles.badge }>{badgeCount}</p>
          ) : false}
          {children}
        </div>
      )
    }

    return (
      <Router.NavLink to={ route } isActive={ this.isActive } className={ classes }>
        <div className={ cx(styles.icon, { [styles.icon_active]: this.isActive() }) }>
          <Svg name={ iconName } />
        </div>
        <p className={ cx(styles.text, { [styles.text_active]: this.isActive() }) }>{label}</p>
        {badge ? (
          <p className={ styles.badge }>{badgeCount}</p>
        ) : false}
        {children}
      </Router.NavLink>
    )
  }
}

MenuItem.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  route: PropTypes.string,
  children: PropTypes.node,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  badge: PropTypes.bool,
  badgeCount: PropTypes.number,
  iconName: PropTypes.string.isRequired,
}

MenuItem.defaultProps = {
  className: '',
  label: 'Название',
  route: null,
  children: null,
  badge: false,
  badgeCount: 0,
}

export default withRouter(MenuItem)
