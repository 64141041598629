import axios from 'axios'

import { createHeaders } from './utils'

export default class APIService {
  constructor(baseUrl) {
    this._baseUrl = baseUrl
    this._accessToken = null

    this._errorHandlers = []
  }

  setAccessToken(token) {
    this._accessToken = token
  }

  addErrorHandler(handler) {
    this._errorHandlers.push(handler)

    return () => {
      this._errorHandlers = this._errorHandlers.filter(_handler => _handler !== handler)
    }
  }

  _invokeErrorHandlers(error) {
    return Promise.all(this._errorHandlers.map(handler => handler(error))).then(() => Promise.resolve(error))
  }

  async get(endpoint, params) {
    try {
      return await axios.get(
        `${this._baseUrl}${endpoint}`,
        { params, headers: createHeaders(this._accessToken) },
      )
    } catch (e) {
      await this._invokeErrorHandlers(e.response)

      throw e.response
    }
  }

  async post(endpoint, payload) {
    try {
      return await axios.post(
        `${this._baseUrl}${endpoint}`,
        payload,
        { headers: createHeaders(this._accessToken) },
      )
    } catch (e) {
      await this._invokeErrorHandlers(e.response)

      throw e.response
    }
  }

  async patch(endpoint, payload) {
    try {
      return await axios.patch(
        `${this._baseUrl}${endpoint}`,
        payload,
        { headers: createHeaders(this._accessToken) },
      )
    } catch (e) {
      await this._invokeErrorHandlers(e.response)

      throw e.response
    }
  }

  async delete(endpoint, params) {
    try {
      return await axios.delete(
        `${this._baseUrl}${endpoint}`,
        { params, headers: createHeaders(this._accessToken) },
      )
    } catch (e) {
      await this._invokeErrorHandlers(e.response)

      throw e.response
    }
  }
}
