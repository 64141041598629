import { createActions } from 'redux-actions'

const { websocket } = createActions({
  WEBSOCKET: {
    CHANGE_STATUS: undefined,
    JOIN_ROOM: undefined,
    LEAVE_ROOM: undefined,
  },
})

const { changeStatus } = websocket
const { joinRoom } = websocket
const { leaveRoom } = websocket

export {
  changeStatus, joinRoom, leaveRoom,
}
