import React from 'react'

import { Provider } from 'react-redux'

import AwaitInitialize from 'components/AwaitInitialize'

import { history } from './history'
import { configureStore } from './store'

import Wrapper from './wrapper'
import Routing from './routing'

import './styles.scss'

const { store } = configureStore({})

const App = () => (
  <Provider store={ store }>
    <Wrapper>
      <AwaitInitialize>
        <Routing history={ history } />
      </AwaitInitialize>
    </Wrapper>
  </Provider>
)

export default React.memo(App)
