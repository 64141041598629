import { createAction } from 'redux-actions'
import { WAIT_FOR_ACTION, ERROR_ACTION, CALLBACK_ERROR_ARGUMENT } from 'redux-wait-for-action'

import * as StringUtils from './string'

const requestActionTypes = {
  started: 'STARTED',
  successed: 'SUCCESSED',
  failed: 'FAILED',
  finished: 'FINISHED',
}

export const waitForAction = (actionCreator, actionToWait, errorAction) => {
  const reduxAction = (...args) => ({
    ...actionCreator(...args),
    [WAIT_FOR_ACTION]: actionToWait.toString(),
    [ERROR_ACTION]: errorAction.toString(),
    [CALLBACK_ERROR_ARGUMENT]: action => action.payload,
  })

  reduxAction.toString = actionCreator.toString

  return reduxAction
}

export const generateActionTypes = (namespace, action, prefix = '') => {
  const _namespace = StringUtils.snakeCaseSections(namespace).toUpperCase()
  const _action = StringUtils.snakeCaseSections(action).toUpperCase()
  const _prefix = prefix && prefix.trim().length > 0 ? `${StringUtils.snakeCaseSections(prefix)}_`.toUpperCase() : ''

  return Object.keys(requestActionTypes).reduce((acc, requestActionType) => ({
    ...acc,
    [requestActionType]: `${_namespace}/${_action}/${_prefix}${requestActionTypes[requestActionType]}`,
  }), {})
}

export const generateActionCreatorsFromTypes = types => Object.keys(types).reduce((acc, type) => ({
  ...acc,
  [type]: createAction(types[type]),
}), {})

export const generateActionCreators = (namespace, action, prefix) => {
  const actionTypes = generateActionTypes(namespace, action, prefix)

  return generateActionCreatorsFromTypes(actionTypes)
}
